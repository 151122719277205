// listing info design code starts here


.c_profile_tabs_section{
    margin-top: 30px;
    .nav-pills{
        border-bottom: 1px solid  #e4e4e4;
        padding: 0 20px;
        @media(max-width:767px){
            flex-direction: column !important;
            padding: 0;
            border-bottom: 0px;
        }
        .nav-item{
            .nav-link{
                background-color: $white_color;
                border-radius: 0;
                padding: 15px 20px;
                transition: all 0.4s ease-in-out;
                border:0;
                position: relative;
                @media(max-width:767px){
                    border-radius: 0px;
                    border: 1px solid #e4e4e4;
                    border-top: 0px;
                }
                &:focus-visible{
                    outline: 0;
                }
                &::before{
                    content: '';
                    background:#ecbe7a;
                    left: 0;
                    width: 0;
                    height: 3px;
                    bottom: 0;
                    position: absolute;
                    transition: all 0.4s ease-in-out;
                    @media(max-width:767px){
                      display: none;
                    }
                }
                &:hover{
                    &::before{
                        width: 100%;
                    }
                }
                h5{
                    font-size: 16px;
                    color: $grey_color;
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }
            .nav-link.active{
                @media(max-width:767px){
                   background:#ecbe7a;
                }
                &::before{
                    width: 100%;
                }
            }
            &:first-child{
                .nav-link{
                    @media(max-width:767px){
                        border-top:  1px solid #e4e4e4;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }
                }
            }
            &:last-child{
                .nav-link{
                    @media(max-width:767px){
                        border-bottom:  1px solid #e4e4e4;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }
    }
    .tab-content{
        .tab_detail_wrapper{
            .tab_heading_block{
                padding: 30px 0;
                border-bottom: 1px solid  #e4e4e4;
                .tab_name{
                    font-size: 20px;
                    line-height: 30px;
                    color: $grey_color;
                    font-weight: 400;
                    margin-bottom:20px;
                }
                .primary-btn{
                    margin-left: 30px;
                    padding: 13px 15px !important;
                }
                .left_section{
                    width: calc(100% - 450px);
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 10px;
                    @media(max-width:1366px){
                        width: calc(100% - 250px);  
                    }
                    @media(max-width:767px){
                        width: 100%;
                        margin-bottom: 20px;
                        padding-right: 0;
                    }
                }
                .right_section{
                    width: 450px;
                    display: inline-block;
                    vertical-align: middle;
                    text-align: right;
                
                     @media(max-width:1366px){
                        width: 250px;  
                        .border-btn{
                            width:auto;
                            margin-bottom: 20px;
                        }
                        .primary-btn{
                            width: auto;
                            margin-left: 0;
                        }
                    }
                    @media(max-width:767px){
                        width: 100%;
                        display: flex;
                        .border-btn,.primary-btn{
                            width:auto;
                            flex: auto;
                            margin-bottom: 0;
                            margin-left: 0;
                        }
                        .primary-btn{
                            margin-left: 20px;
                        }
                    }
                    @media(max-width:640px){
                        width: 100%;
                        display: block;
                        .border-btn,.primary-btn{
                            width:100%;
                        }
                        .primary-btn{
                            margin-top: 15px;
                            margin-left: 0;
                        }
                    }
                }
            }
            .company_detail_form_wrap{
                padding: 30px 0;
                @media(max-width:991px){
                    padding-bottom: 10px;
                }
                    .light-text{
                        font-size: 14px;
                        display: inline-block;
                        color: #787777;
                    }
            }
        }
    }
    .tab-pane {
        .tab_detail_wrapper{
					.form-label {
						margin-bottom: 10px;
					}
					.input_text {
						margin-bottom: 0;
					}
            .p_img_wrap{
                background: #fff;
                display: block;
                max-width: 100%;
                overflow: hidden;
                width:150px;
                border: 1px solid #ccc;
                margin-bottom: 20px;
                   span{
                    display: block;
                    height: 0;
                    overflow: hidden;
                    padding-bottom: 100%!important;
                    position: relative;
                    z-index: 0;
										 span {
											position: static !important;
											padding-bottom: 0 !important;
											width: auto !important;
											height: auto !important;
										}
                        img{
                            bottom: 0;
                            display: block;
                            height: auto;
                            left: 0;
                            margin: auto;
                            max-width: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 100%;
                            border-radius: 0;
                            border: 0;
														width: 100% !important;
														height: auto !important;
														max-width: 100% !important;
														max-height: 100% !important;
														min-width: auto !important;
														min-height: auto !important;
														margin: auto !important;
                        }
                   }
               }
        }
    }
}


// listing info design code ends here

// Button show on mobile and desktop

.desktop-btn{
    @media(max-width:991px){
        display: none !important;
    }
}
.mobile-btn{
    @media(min-width:991px){
        display: none !important;
    }
}

// service areas design code starts here

.c_profile_tabs_section {
    .tab-content {
        .tab_detail_wrapper {
            .tab_heading_block.service_heading{
                   border: 0;
                   .tag-listing{
                        li{
                            font-size: 14px;
                            color: $grey_color;
                            margin-bottom: 6px;
                            vertical-align: top;
                            font-weight: 400;
                            border-radius: 15px;
                            background-color: #ffffff;
                            border: 1px solid #a4a4a4;
                            padding: 4px 20px;
                            margin-right: 15px;
                            transition: all 0.3s ease-in-out;
                        }
                   }
            }
            .service_area_detail_wrapper{
                .layout-btn_wrap{
                    text-align: right;
                    margin-bottom: 10px;
                    .btn_layout{
                        display: inline-block;
                        padding: 0 10px;
                        cursor: pointer;
                        i{
                            font-size: 26px;
                        }
                    }
                }
                .detail_inner_wrap{
                    border: 1px solid #a4a4a4;
                    border-radius: 5px;
                    padding: 20px;
                    background: $white_color;
                    margin-bottom: 20px;
                    position: relative;
                    .edit_wrapper{
                        .left__col{
                            display: inline-block;
                            vertical-align: top;
                            padding-right: 10px;
                            width: calc(100% - 185px);
                            @media(max-width:991px){
                                width: calc(100% - 20px);
                            }
                        }
                        .right__col{
                            display: inline-block;
                            width:185px ;
                            vertical-align: top;
                            #del__btn{
                                position: absolute;
                                top: -10px;
                                right: -10px;
                                background: $blue_color;
                                height: 30px;
                                width: 30px;
                                text-align: center;
                                color: #fff;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                &:hover{
                                    background: $green_color;
                                }
                            }
                            @media(max-width:991px){
                                width: 20px;
                            }
                            .border-btn{
                                @media(max-width:991px){
                                    border: 0;
                                    padding: 0;
                                    &::before,&:after{
                                        display: none;
                                    }
                                    &:hover,&:focus{
                                        background: $white_color;
                                        color: $green_color;
                                    }
                                }
                                span{
                                    @media(max-width:991px){
                                        display: none;
                                    } 
                                }
                                i{
                                    display: none;
                                    @media(max-width:991px){
                                        display: block;
                                    }
                                }
                            }
                        }
                    }           
                    .area_detail_block{
                        margin-top: 20px;
                        .inner_blocks{
                            position: relative;
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;
                            max-width: 300px;
                            margin-right: 60px;                           
                            .p_head{
                                font-weight: 500;
                                margin-bottom: 18px;
                            }
                        }
                        .map-block{
                            min-height: 300px;
                        }
                    }
                }
                .list{
                    .detail_inner_wrap {
                        .area_detail_block {
                            .inner_blocks{
                                @media(max-width:1600px){
                                    margin-right: 20px;
                                }
                                @media(max-width:1500px){
                                    max-width: 100%;
                                    width: 33%;
                                    margin-right: 0;
                                    padding: 0 20px;
                                }
                                @media(max-width:767px){
                                    width: 100%;
                                    text-align: center;
                                }
                            }
                            .map-block{
                                padding: 0;
                                @media(max-width:767px){
                                margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
                .Grid{
                    display: grid;
                    grid-gap: 20px;
                    padding: 0 12px;
                    grid-template-columns: repeat(4, 1fr);
                    @media(max-width:1550px){
                        grid-template-columns: repeat(3, 1fr);
                    }
                    @media(max-width:1266px){
                        grid-template-columns: repeat(2, 1fr);
                    }
                    @media(max-width:640px){
                        grid-template-columns: repeat(1, 1fr);
                    }
                    .detail_inner_wrap{
                        margin-bottom: 0;
                        .edit_wrapper {
                            .left__col{
                                width: calc(100% - 20px);
                                padding-right: 10px;
                            }
                            .right__col{
                                width: 20px;
                                .border-btn{
                                    border: 0;
                                    padding: 0;
                                    &::before,&:after{
                                        display: none;
                                    }
                                    &:hover,&:focus{
                                        background: $white_color;
                                        color: $green_color;
                                    }
                                    span{
                                        display: none;
                                    }
                                    i{
                                        display: block;
                                    }
                                }
                            }
                        }                   
                    }
                    .area_detail_block{
                        .inner_blocks{
                            display: block;
                            max-width: 100%;
                            margin-bottom: 20px;
                            text-align: center;e
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }               
                    }
                }
            }
        }
    }
}


// service areas design code ends here



// category and task design code starts here

.service_cat_listing_wrapper{
    border: 1px solid #a4a4a4;
    border-radius: 5px;
    padding: 20px 30px;
    padding-bottom: 0;
    background: $white_color;
    margin-bottom: 20px;
    .tab_service_cat {
        color: $grey_color !important;
        background: $white_color !important;
        width: 100%;
        border: 0;
        padding-bottom: 20px;
        text-align: left;
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .tab_ser_content {
        background: $white_color;
        padding: 20px 15px;
        border-top: 1px solid #a4a4a4;
        ul{
            li{
                margin-bottom: 8px;
                .cat_options_checkbox{
                    input {
                        padding: 0;
                        height: initial;
                        width: initial;
                        margin-bottom: 0;
                        display: none;
                        cursor: pointer;
                      }
                      label {
                        position: relative;
                        cursor: pointer;
                        margin-bottom: 0;
                        font-size: 16px;
                        color: $grey_color;
                        padding-left: 32px;
                        @media(max-width:1366px){
                            font-size: 18px;
                          }
                        &::before{
                            content:'';
                            -webkit-appearance: none;
                            background-color: transparent;
                            border:1px solid #A4A4A4;
                            box-shadow: none;
                            width: 18px;
                            height: 18px;
                            border-radius: 5px;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            cursor: pointer;
                            position: absolute;
                            left: 0;
                            top: 5px;
                            @media(max-width:1366px){
                                width: 20px;
                                height: 20px;
                            }
                        }
                      }
                      input:checked + label:after  {
                        content: '\f00c';
                        display: block;
                        position: absolute;
                        top: 7px;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: bold;
                        left: 4px;
                        color: $white_color;
                        font-size: 10px;
                      }
                      input:checked + label::before {
                        background-color: $blue_color;
                        border-color: $blue_color;
                      }
                }
            }
        }
    }
}

// category and task design code ends here


// gallery design code starts here

.tab_detail_wrapper div.gallery_content_wrapper{
    .img_wrap{
        position: relative;
        margin-bottom: 20px;
        border-radius: 5px;
        border: 1px solid #a4a4a4;
        background: #f7f7f7;
        i{
            position: absolute;
            right: 6px;
            top: 4px;
            color: $green_color;
            padding: 10px;
            cursor: pointer;
            z-index: 1;
            &:hover{
                color: $blue_color;
            }
        }    
        .p_img_wrap{
            background: #fff;
            display: block;
            max-width: 100%;
            overflow: hidden;
            width:100%;
            margin: auto;
            border: 0;
               span{
                display: block;
                height: 0;
                overflow: hidden;
                padding-bottom: 100%!important;
                position: relative;
                z-index: 0;
                    img,video{
                        bottom: 0;
                        display: block;
                        height: auto;
                        left: 0;
                        margin: auto;
                        max-width: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width:auto;
                        border-radius: 0;
                        border: 0;
                    }
               }
           }
    }
    .close-icons{
        i{
            position: absolute;
            right: -10px;
            top: -10px;
            color: $white-color;
            padding: 10px;
            cursor: pointer;
            z-index: 1;
            background: $blue_color;
            font-weight: 300;
            font-size: 20px;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        &:hover{
            color: $white-color;
            background: $green_color;
        }
    }
 }
 #spinner.spinner_req_review{
     &:after{
        content: "";
        background: #fbfbfb;
        width: 100%;
        height: 30px;
        top: -30px;
        position: absolute;
     }
     &::before{
        content: "";
        background: #fbfbfb;
        width: 30px;
        height: 100%;
        right: -30px;
        top: -30px;
        position: absolute;
     }
     .lds-spinner{
         bottom: auto;
     }
 }
}
.gallery-upload-btn{
    .custom-file-input{
        &::before{
            background: $green_color;
            border-style: solid;
            border: 0;
            color: $white_color;
            text-shadow: none;
            font-size: 16px;
            font-family: $primary-font;
            line-height: 1.5;
            border-radius: 5px;
            padding: 12px 15px;
            font-weight: 500;
        }
    }
}
.portfolio-modal-wrapper,.portfolio-modal-wrapper-view{
    .modal-dialog{
        max-width: 900px;
        .modal-body{
            .model_img_wrap{
                margin-bottom: 0;
                .close-icon{
                    position: absolute;
                    top: 0;
                    z-index: 1;
                    right: 0;
                    box-shadow: none;
                    border: 0;
                    background: $green_color;
                    padding: 2px 6px;
                    border-bottom-left-radius: 5px;
                    i{
                    color: $white_color;
                    }
                }
            }   
            .note_text{
                font-size: 14px;
                margin-bottom: 0;
            }      
        }
    }
    .multi_img_portfolio{
        padding-left: 0;
        margin-bottom: 0;
        li{
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            vertical-align: top;
        }
    }
}
// gallery design code ends here


#company_profile_tabs-tabpane-tab4{
    @media(min-width:580px){
    .service_heading{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        .left_section{
            width: 40% !important;    
            h4{
                margin-bottom: 0px;
            } 
        }
        .right_section {
            display: flex !important;
            justify-content: end !important;
            width: 60% !important;
            align-items: center;
            .border-btn{
                margin-bottom: 0px;
            }
            form{
                .form-group{
                    margin-bottom: 0px;
                    margin-left: 10px;
                    .custom-file-input{
                        margin-bottom: 0px;
                        margin-top: 0px;
                        .file_name{
                            position: absolute;
                            left: 0;
                            bottom: -28px;
                            font-size: 14px;
                        }
                        .errorMsg{
                            position: absolute;
                            top: 37px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
 }
}