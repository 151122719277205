@import 'variables';


// banner design code starts here


// .banner_breadcrumb_section{
// 	background: url('../../images/public/about-bg.png');
// 	background-size: cover;
// 	padding: 150px 20px;
// 	min-height: 500px;
// 	@media(max-width:1366px){
// 		background-position: 15%;
// 	   }
// 	@media(max-width:991px){
// 		padding: 100px 20px;
// 		min-height: auto;
// 	}
// 	.main-heading-lg{
// 		color: $white_color;
// 		text-shadow: 0px 0px 10px $blue_color;
// 	}
// }

// banner design code ends here


// blog listing section code starts here

.b_category {
	position: absolute;
	z-index: 2;
	top:5px;
	left: 0;
	min-width: 150px;
	font-size: 14px;
	margin-bottom: 0;
	padding: 9px 10px;
	text-align: center;
	color: $white_color;
	font-weight: 400;
	background-color: #ECBE7A;
	border-bottom-right-radius: 5px;
}
.b_category.Design{
	color: #3C3C3C;
}
.b_category.Web_Development{
	background-color: $blue_color;
}
.b_category.IT_Services{
	background-color: $green_color;
}
.b_category.Web_Design{
	background-color: $grey_color;
}
.b_category.SEO{
	color: $grey_color;
	background-color: #a8efb1;
}
.b_category.Ecommerce{
	color: $grey_color;
	background-color: #eaf7ff;
}
.all_blogs_section{
	position: relative;
	margin-bottom: 40px;
		#spinner{
			height: 100%;
			width: 100%;
			background-color: #eaf7ff;
			position: absolute;
			z-index: 99;
			display: block;
			top: 0;
			left: 0;
			.lds-spinner{ 
				div{&::after{
					background: #00cc83;
					}
				}

			}
			}
	
}
.single_blog_section{
	position: relative;
	margin-bottom: 40px;
	background: #eaf7ff;
	&::before{
		content: '';
		background:$blue_color;
		left: 0;
		width: 100%;
		top: 0;
		position: absolute;
		height: 5px;
		transition: all 0.4s ease-in-out;
	}
	&::after{
		content: '';
		background:$green_color;
		left: 0;
		width:0;
		top: 0;
		position: absolute;
		height: 5px;
		transition: all 0.4s ease-in-out;
	}
	&:hover{
		&::after{
		  width: 100%;
		}
	}
	.blog-img_section{
		height: 458px;
		@media(max-width:991px){
			height: 298px;
		}
		span {
			position: static !important;
			width: 100% !important;
			height: 100% !important;
		}
		img{
			position: static !important;
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;
		}
	}
	.row{
		align-items: center;
	}
	.blog_content_section{
		padding: 20px 60px;
		@media(max-width:991px){
			 padding: 30px;
		}  
		.blog_name{
			font-size: 30px;
			color: #3c3c3c;
			font-weight: 700;
			font-family: $secondary-font;
			margin-bottom: 0;
		}
		.description-text-sm{
			color: #3c3c3c;
			margin:20px 0;
		}
	}
}

.double-blog_section{
    .main-block{
        margin-bottom: 40px;
    }
    .blog-img_section{
        height: 298px;
				span {
					position: static !important;
					width: 100% !important;
					height: 100% !important;
				}
        img{
					position: static !important;
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
        }
    }
    .block1{
        margin-right: 8px;
        @media(max-width:767px){
            margin-right: 0;
       }  
    }
    .block2{
         margin-left: 8px;
         @media(max-width:767px){
           margin-left: 0;
       }  
    }
    .blog_content_section{
        padding: 30px 60px;
        @media(max-width:991px){
            padding-left: 30px;
            padding-right: 30px;
          }  
        .left_col{
            width:175px;
            vertical-align: top;
            display: inline-block;
            padding-right: 20px;
            
            @media(max-width:1050px){
                width: 100%;
                padding-right: 0;
            }
        }
        .right_col{
            width: calc(100% - 175px);
            vertical-align: top;
            display: inline-block;
            @media(max-width:1050px){
                width: 100%;
                padding-right: 0;
            }
        }
      .description-text-sm{
          margin-top: 0;
      }
    }
}
.main-block{
	position: relative;
	background: #eaf7ff;
	// margin-bottom: 40px;
	&::before{
		content: '';
		background:$blue_color;
		left: 0;
		width: 100%;
		top: 0;
		position: absolute;
		height: 5px;
		transition: all 0.4s ease-in-out;
	}
	&::after{
		content: '';
		background:$green_color;
		left: 0;
		width:0;
		top: 0;
		position: absolute;
		height: 5px;
		transition: all 0.4s ease-in-out;
	}
	&:hover{
		&::after{
		  width: 100%;
		}
	}
	.blog_content_section{
		padding: 30px 40px;
		@media(max-width:991px){
			padding: 30px;
	   }  
	}
	.blog_name{
		font-size:20px;
		color: #3c3c3c;
		font-weight: 400;
		margin-bottom: 0;
		line-height: 30px;
	}
	.description-text-sm{
		color: #3c3c3c;
		margin-bottom: 20px;
		margin-top: 20px;
	}
}
.normal_blog_section{
	.main-block{
		.blog-img_section{
			height: 248px;
			span {
				position: static !important;
				width: 100% !important;
				height: 100% !important;
			}
			img{
				position: static !important;
				width: 100% !important;
				height: 100% !important;
				object-fit: cover;
			}
		}
	}
	.row{
		display: grid;
		grid-gap: 40px;
		padding: 0 12px;
		grid-template-columns: repeat(4,1fr);
		@media(max-width:1199px){
			grid-template-columns: repeat(3,1fr);
		}
		@media(max-width:991px){
			grid-template-columns: repeat(2,1fr);
		}
		@media(max-width:767px){
			grid-template-columns: repeat(1,1fr);
		}
		.col-lg-4{
			padding: 0 0px;
			display: flex; 
			max-width: 100%;
			width: 100%;
		}
	}
}
// blog listing section code ends here



// blog detail page section code starts here


.blog-detail-section{
	.img_section{
		position: relative;
		&::before{
			content: '';
			background:$blue_color;
			left: 0;
			width: 100%;
			top: 0;
			position: absolute;
			height: 5px;
			transition: all 0.4s ease-in-out;
		}
		&::after{
			content: '';
			background:$green_color;
			left: 0;
			width:0;
			top: 0;
			position: absolute;
			height: 5px;
			transition: all 0.4s ease-in-out;
		}
		&:hover{
			&::after{
			  width: 100%;
			}
		}
		img{
			width: 100% !important;
			object-fit: cover;
			height: 400px !important;
			object-position: top;
		}
	}
	.blog_content_section{
		padding: 30px;
		background: #eaf7ff;
		.blog_name{
			font-size: 30px;
			color: $blue_color;
			font-weight: 700;
			font-family: $secondary-font;
			margin-bottom: 0;
		}
		.description-text{
			color: #3c3c3c;
			margin:10px 0;
			a{
				color: $green_color;
			}
		}
		.published_on {
			margin-top: 20px;
			padding-bottom: 10px;
			border-bottom: 2px solid #ccc;
			i {
				color: $green_color;
				padding-right: 10px;
			}
		}
	}
}



// blog detail page section code ends here


// blog page pagination design code starts here


.blog_pagination{
   .pagination{
	justify-content: normal;
	   .page-item {
		   outline: 0;
		   flex: auto;
		  .page-link{
			  display: none;
			  padding: 10px 35px;
		  }
		  .page-link[aria-label="Previous"],.page-link[aria-label="Next"]{
			  display: block;
			  width: 150px;
			  border: 0;
			  color: $grey_color;
			  outline: 0;
			  &:hover,&:focus{
				  background-color: transparent;
				  color: $grey_color;
			  }
			  &::before{
				position: absolute;
				content: '\f177';
				font-size: 30px;
				left: 0;
				color: $grey_color;
				top: 5px;
				font-family: "Font Awesome 5 Pro";
			} 
				span[aria-hidden="true"]{
					display: none;
				}
				.sr-only{
					font-size: 20px;
					position: initial;
				}
		  }
		  .page-link[aria-label="Next"]{
			text-align: right;
			margin-left: auto;
			padding-left: 0;
			width: auto;
			&::before{
				content: '\f178';
				right: 0;
				left: auto;
			  }
		  }
	   }
	   .page-item.disabled{
			.page-link[aria-label="Previous"],.page-link[aria-label="Next"]{
			  
					opacity: .5;
			   
			}
	   }
   }
}


// blog page pagination design code ends here

// Blog top content
.blog-top-content{
	text-align: center;
	max-width: 1200px;
	margin: auto;
	padding: 0px 10px 60px 10px;
	@media(max-width:600px){
		padding: 0px 10px 40px 10px;
	}
}
// Blog top content end