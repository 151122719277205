@import 'variables';

// pro form section code starts here



.pro-form_section{
    background-image: url('../../../public/admin/main-bg.jpg');
    background-size: cover;
    background-position: right;
    padding: 80px 0;
    .form_Section{
        max-width: 464px !important;
        width: 100%;
        background: $white_color;
        padding: 30px 35px;
        border-radius: 5px;
        margin: auto;
        @media(max-width:767px){
          margin-top: 0 !important;
        }
        .description-text-sm{
            margin: 20px 0;
        }
        .form-group{
            margin-bottom: 20px;
        }
        .input_text{
            border: 1px solid #A4A4A4;
            color: $black_color;
            font-size: 14px;
            padding: 14px 15px;
            background-color: $white_color !important;
            width: 100%;
            border-radius: 5px;
            min-height: 51px;
            &:focus,&:hover,&:focus-visible{
                box-shadow: none !important;
                background-color: $white_color !important;
                border-color: $green_color !important;
                color: $black_color !important;
                outline: 0 !important;
            }
            &::placeholder{
                color: #969696;
            }
        }
        .signup_title{
            font-size: 30px;
            color: $grey_color;
            font-weight: 800;
            font-family: $secondary-font;
            margin-top:0;
            margin-bottom: 25px;
            @media(max-width:767px){
                font-size:25px;
            }
        }
        .primary-btn{
            width: 100%;
        }
        .already_text{
            font-size: 14px;
            color: #3c3c3c;
            margin-top: 20px;
            font-weight: 400;
            margin-bottom: 0;
            a{
                color: #3c3c3c;
                display: inline-block;
            }
        }
        .account_info_section{
            margin-top: 38px;
            @media(max-width:767px){
              margin-top: 30px;
            }        
        }
    }
}

// multicard design code starts here

.payment-cards-section{
    .modal-dialog{
        max-width: 900px;
        .modal-body{
            min-height: 300px;
        }
    }
    .card-body{
        padding: 10px;
        background: linear-gradient(
        135deg,#3d3d3d,#474747 12%,#525252 25%,#383838 39%,#0f0f0f 61%,#212121 66%,#000 79%,#171717 91%,#0f0f0f);
        color: $white_color;
        margin-bottom: 10px;
        border-radius: 12px;
        position: relative;
        input{
            display: none;
        }
        label{
            &::after{
                content: '\f00c';
                background: $blue_color;
                width: 26px;
                font-family: "Font Awesome 5 Pro";
                color: $white_color;
                height: 26px;
                position: absolute;
                top: 0;
                right: 0;
                text-align: center;
                border-top-right-radius: 5px;
                border-bottom-left-radius: 5px;
                display: none;
            }
        }
        input[type=radio]:checked + label:after{
            display: block;
        }
        .name_card{
            font-size: 18px;
            color: $white_color;
            margin-top: 10px;
            text-transform: uppercase;
        }
        .img-wrap{
            display: flex;
            width: 100%;
            align-items: center;
            img{
                width: 50px;
                height: 50px;
            }
            img.globe_img{
                text-align: right;
                width: 100px;
                height: 60px;
                margin-left: auto;
            }
        }
        .card_number{
            font-size: 16px;
            font-weight: 600;
            color: $white_color;
            margin-left: auto;
        }
        .valid-wrap{
            display: flex;
            .valid-text{
                color: #838383;
                font-size: 10px;
                width: 40px;
                font-weight: 600;
                display: inline-block;
                vertical-align: middle;
            }
            .expiry{
                width: 100px;
                display: inline-block;
                vertical-align: middle;
                color: $white_color;
            }
            .brand-name{
                margin-left: auto;
                color: $white_color;
                font-size: 18px;
                font-weight: 600;
                display: inline-block;
            }
        }
    }
}

// multicard design ends starts here


// pro form section code ends here



// why toprow section code starts here


.why_yellow_section{
    background-image: url('../../../public/public/pro-bg-yellow.jpg');
    background-size: cover;
    background-position: right;
    @media(max-width:767px){
       background-position: left;
    }
    .row{
        align-items: center;
        .first_col{
            @media(max-width:767px){
               order: 2;
             } 
        }
        .second_col{
            @media(max-width:767px){
               order: 1;
             } 
        }
    }
    .main-heading{
         margin-bottom: 20px;
    }
    .left_col{
        padding-right: 70px;
        @media(max-width:767px){
            padding-right: 0;
            margin-top: 30px;
        }
    }
    .right_col{
        padding-left: 70px;
        @media(max-width:767px){
            padding-left: 0;
            margin-top: 30px;
        } 
    }
	.text-right {
		img {
			margin-left: auto !important;
		}
	}
}

// why toprow section code ends here




// plans section code starts here


.plans_section{
    .upper-scetion-description{
        max-width: 760px;
        width: 100%;
        margin: 30px auto 50px;
    }
    .main-price_listing{
        padding-left: 0;
        margin-bottom: 0;
        text-align: center;
        .main-list-li{
            max-width: 445px;
            width: 100%;
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            margin-bottom: 40px;
            @media(max-width:1400px){
                margin-bottom: 40px;
                &:last-child{
                    margin-bottom: 0;
                }
            }  
            @media(max-width:767px){
                padding: 0;
            }        
            &:nth-child(2n){
                .plans_list .price_section::before{
                    border-color: #00cb83;
                }
            }
            &:nth-child(3n){
                .plans_list .price_section::before{
                    border-color: #004dbf;
                }
            }
            .plans_list{
                background:#eaf7ff;
                padding: 0 0px 50px;
                border-radius: 5px;
                text-align: left;
                width: 100%;
                .price_section{
                    // min-height: 249px;
                    padding: 40px 0;
                    padding-top: 135px;
                    overflow: hidden;
                    border-radius: 5px;
                    position: relative;
                    transform: rotate(180deg);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &::before{
                        content: '';
                        background-color: #3f3f3f;
                        height: 500px;
                        width: 500px;
                        border-radius: 70px;
                        transform: translate(-50%,61%) rotate(45deg);
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        z-index: -1;
                        border:35px solid #ebbd79;
                    }
                    .inner-wrap{
                        transform: rotate(180deg);
                        text-align: center;
                        .main-heading{
                            color: $white_color;
                            font-weight: 800;
                            margin-bottom: 15px;
                            font-size: 35px;
                            @media(max-width:991px){
                                font-size: 30px;
                            }
                        }
                        .description-text{
                          font-weight: 400;
                          color: $white_color;
                        }
                    }
                }
                .list_col{
                    text-align: center;
                    height: 350px;
                    padding: 15px 25px;
                    overflow: auto;
                    margin-bottom: 35px;
                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 5px;
                    }
                
                    /* Track */
                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px grey;
                        border-radius: 5px;
                    }
                
                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: $green_color;
                        border-radius: 5px;
                    }
                
                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: $green_color;
                    }
                    ul{
                        text-align: left;
                        // max-width: 252px;
                        width: 100%;
                        // padding-left: 0;
                        margin: 0px auto;
                        li{
                            font-size: 20px;
                            color: $grey_color;
                            margin-bottom: 15px;
                            img{
                                vertical-align: text-top;
                                display: inline-block;
                                padding-right: 10px;
                            }
                        }
                    }                 
                }
                .primary-btn{
                    text-align: center;
                    max-width: 150px;
                    width: 100%;
                    margin: auto;
                    padding: 0 20px;
                }
            }
        }
    }
}

// plans section code ends here