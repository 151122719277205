html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */


.remove_span > span > span {
  display: none !important;
}

/* .sitemap_listing {
  list-style-type: none;
  padding: 0px;
}
.accordian_li  {
  padding: 10px;
  border-style: outset;
}

.faq-sections .accordion-button:not(.collapsed)  a {
    color: white !important;
}

.sitemap_loader {
  left: 0;
  right: 0; 
  top: 0;
  bottom: 0;
  z-index: 999;
  position: fixed;
} */

/* .skeleton_box {
  box-shadow: 0 8px 16px 2px rgba(0,0,0,.1);
  border-radius: 5px;
  background-color: #fff;
} */
/* .skeleton_lines {
  padding: 10px 20px 30px;
}
.skeleton_lines > span {
  display: block;
}
.skeleton_lines > span:first-child {
  max-width: 60%;
}
.skeleton_lines .react-loading-skeleton {
  border-radius: 0;
  background-color: #d1d1d1;
} */
/* .skeleton_img {
  width: 100%;
  height: 210px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px 5px 0 0;
}
.skeleton_img.reset_img span img {
  width: 100% !important;
  height: 210px !important;
  object-fit: cover;
} */
/* .services_pop_section .services_home_listing li.list-inline-item {
  padding: 0 7px;
  margin: 0;
}
.services_pop_section .skeleton_img {
  height: 160px;
} */
/* .slider_placeholder {
  display: flex;
}
.slider_placeholder .list-inline-item {
  width: 100%;
}
.slider_placeholder .skeleton_img {
	border-radius: 5px;
} */
/* .blog_listing .list-inline-item {
	margin-right: 0;
} */