@import '../public/variables.scss';


.user_dashboard_main{
    padding: 60px 0;
    // display: flex;
    @media(max-width:991px){
        padding: 40px 0;
    }
}
// leftbar design code starts here

.customerleftbar_section{
    width: 400px;
    display: inline-block;
    vertical-align: top;
    background: #eaf7ff;
    @media(max-width:1366px){
        width: 300px;
    }
    @media(max-width:991px){
        width: auto;
    }
    .sidebar_nav{     
        border-radius: 20px;
        padding: 30px;
        padding-top: 50px;
        @media(max-width:991px){
            padding: 0;
            background: transparent;
        }
        .profile_section{
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            @media(min-width:992px) and (max-width:1300px){
                flex-flow: column;
            }
            .p_img_wrap{
                background: #fff;
                border-radius: 50%;
                display: block;
                margin: auto;
                max-width: 100%;
                overflow: hidden;
                position: relative;
                width: 100px;
                border: 1px solid #ccc;
                   span{
                    display: block;
                    height: 0;
                    overflow: hidden;
                    padding-bottom: 100%!important;
                    position: relative;
                    z-index: 0;
                        img{
                            bottom: 0;
                            display: block;
                            height: auto;
                            left: 0;
                            margin: auto;
                            max-width: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 100%;
                            border-radius: 0;
                            border: 0;
														object-fit: cover;
                        }
                   }
               }
            .name_section{
                width: calc(100% - 100px);
                display: inline-block;
                vertical-align: top;
                padding-left: 20px;
                @media(min-width:992px) and (max-width:1300px){
                    width: calc(100% - 0px);
                    padding-left: 0px;
                    text-align: center;
                    margin-top: 10px;
                }
                h4{
                    font-size: 20px;
                    color: $grey_color;
                    font-weight: 500;
                }
                .join_text{
                    font-size: 16px;
                    color: $grey_color;
                    margin-bottom: 0;
                }
            }
        }
        .navbar-toggler{
            border: 0;
            box-shadow: none;
            .navbar-toggler-icon{
                position: relative;
                &::after{
                    content: 'MENU';
                    position: absolute;
                    left: 55px;
                    top: 5px;
                    color: $grey_color;
                    display: inline-block;
                }
            }          
        }
           .navbar-collapse{
               display: block !important;
               @media(max-width:991px){
                //    display: none !important;
                   min-height: 100%;
                   width: 300px;
                   height: 100%;
                   top: 0;
                   left: -300px;
                   position: fixed;
                   background: $white_color;
                   overflow-y: auto;
                   z-index: 999;
                   padding-top: 60px;
                   padding-bottom: 30px;
                   box-shadow: 0 8px 16px 2px rgba(0, 0 ,0 ,0.1);
                   transition: all .4s ease-in-out;
               }
               .service_toggle,.navbar-toggler{
                position: absolute;
                top: 0;
                right: 0;
                background: #2471eb;
                color: #fff;
                width: 32px;
                height: 34px;
                text-align: center;
                border-radius: 0 0 0 10px;
                   .navbar-toggler-icon{
                       display: none;
                   }
                   &::before,&::after{
                    position: absolute;
                    left: 17px;
                    content: ' ';
                    height: 16px;
                    width: 2px;
                    background-color: $white_color;
                    top: 8px;
                   }
                   &::before{
                    transform: rotate(45deg);
                   }
                   &::after{
                    transform: rotate(-45deg);
                   }
               }
           }
           .navbar-collapse.show{
            display: block !important; 
            left: 0;
            .profile_section{
                padding: 0px 20px;
            }
           }
            .navbar-nav{
                display: block;
                width: 100%;
                .nav-link{
                    font-size: 20px; 
                    font-weight: 400;
                    padding: 9px 20px;
                    display: block;
                    color: $grey_color;
                    transition: all 0.3s ease-in-out;
                    margin-bottom: 20px;
                    &:hover{
                     background: $blue_color;
                     color: $white_color; 
                     .dropdown-toggle{
                        color: $white_color;
                    }
                    }
                    @media(max-width:1366px){
                        font-size: 18px;
                        margin-bottom: 6px;
                    }
                    i{
                        margin-right: 15px;
                        min-width: 25px;
                    }           
                    .dropdown-toggle {
                        background: transparent;
                        border: 0;
                        color: $grey_color;
                        padding: 0;
                        font-size: 20px;
                        font-weight: 400; 
                        text-align: left;  
                        position: relative;
                        @media(max-width:1366px){
                            font-size: 18px;
                        }
                        .dropdown{
                            margin-bottom: 0;
                            .nav-link{
                                margin-bottom: 0;
                            }
                            .dropdown-menu{
                                position: relative;
                                border: 0;
                                padding-left: 30px;
                                a{
                                    font-size: 16px;
                                    font-weight: 400;
                                    padding: 9px 20px;
                                }        
                            }
                        }
                        width: 100%;
                        &::after{
                            position: absolute;
                            right: 0;
                            top: 10px;
                        }

                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
                .dropdown{
                    margin-bottom: 20px;
                    @media(max-width:1366px){
                        margin-bottom: 6px;
                    }
                    .nav-link{
                        margin-bottom: 0;
                    }
                    .dropdown-menu{
                        position: relative;
                        border: 0;
                        background-color: transparent;
                        padding-left: 30px;
                        a{
                            font-size: 16px;
                            font-weight: 400;
                            padding: 9px 20px;
                            i{
                                margin-right: 6px;
                                width: 20px;
                            }
                        }       
                        .active{
                            background: $blue_color;
                        } 
                    }
                    .active_menu{
                        display: block;
                    }
                }
            }
            .nav-link.active{
                background: $blue_color;
                color: $white_color;
            }
       }
}


// leftbar design code ends here


.right_col_user{
    width: calc(100% - 400px);
    display: inline-block;
    vertical-align: top;
     padding-left: 40px;
    @media(max-width:1366px){
        width: calc(100% - 300px);
    }
    @media(max-width:991px){
        width: 100%;
        margin-top: 30px;
        padding-left: 0px;
    }
}