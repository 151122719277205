.secondary-btn{
    text-transform: uppercase;
    font-size: 16px;
    background:$blue_color;
    color: $white_color;
    // width: 100%;
    border: 0;
    padding:13px 15px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    &::before{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 0%;
        border-radius: 5px;
        background-color: $green_color;
    }
    &::after{
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 0%;
        background-color: $green_color;
        -webkit-transition: width .5s;
        -o-transition: width .5s;
        border-radius: 5px;
        transition: width .5s;
    }
 span,i{
     position: relative;
     z-index: 1;
     display: inline-block;
 }
    &:hover{  
        background-color: $blue_color;
            color:$white-color;
        &::before{
            -webkit-transition: width .5s;
            -o-transition: width .5s;
            transition: width .5s;
            width: 100%;
        }
        &::after{
            -webkit-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
            visibility: hidden;
            width: 100%;
        }
    }
    @media (hover: none) {
        &:hover{
            background-color: $green_color;
            color:$white-color;
            &::before,&::after{
                display: none;
            }
        }
    }
    &:focus{
        outline: 0;
        box-shadow: none !important;
        background-color: $green_color;
        color:$white-color;
    }
}

.required_field {
	color: red;
    font-size: 25px;
    vertical-align: sub;
    line-height: 0.7;
}
.dashboard_title{
    margin-bottom: 30px;
    img, span {
        display: inline-block !important;
        vertical-align: middle;
        @media(max-width:640px){
					width: 25px;
        }
    }
		&.reset_img {
			span {
				img {
					@media(max-width:640px) {
						width: 25px !important;
					}
				}
			}
		}
    h6{
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        color:  #444444;
        font-weight: 400;
        margin-bottom: 0;
        margin-left: 15px;
        @media(max-width:580px){
            font-size: 18px;
        }
    }
    i{
        color: $green_color;
        font-size: 30px;
        vertical-align: middle;
        font-weight: 300;
    }
    .breadcrumb_text{
        font-size: 16px;
        margin-top: 20px;
        color: #3c3c3c;
        margin-bottom: 0;
        font-weight: 300;
        i{
            color:  #444444;
            margin: 0 15px;
        }
    }
}

.table-responsive {
    margin-bottom: 10px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $blue_color;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $blue_color;
    }
}
.table{
    margin-bottom: 0;
    @media(max-width:1440px){
        white-space: nowrap;
    }
    thead{
        tr{
            background: $blue_color;
            color: $white_color;
            vertical-align: top;
            th{
                padding: 10px 12px;
            }
        }
    }
    tbody{
        tr{
            td{
                padding: 10px 12px;
                white-space: initial;
								word-wrap: break-word;
                a{
                    margin-right: 10px;
                    i{
                        color: $green_color;
                    }
                    .fa-download{
                        font-weight: 600;
                    }
                }
                .uploaded-img{
                    margin-bottom: 0;
                }
                .read-more-less--more,.read-more-less--less{
                    font-weight: 600;
                    color: $green_color;
                    text-decoration: underline;
                    &:hover{
                        color: $blue_color;
                    }
                }
            }
        }
        .no_result{
            padding: 10px;
            text-align: center;
        }
    }
    .sort_wrapper{
        padding-right: 36px;
        position: relative;
        .sort-icon{
            display: inline-block;
            vertical-align: top;
            text-align: right;
            cursor: pointer;
            position: absolute;
            top: 13px;
            right: 10px;
            i{
                font-size: 20px;
            }
        }
        .sort-text{
            display: inline-block;
            vertical-align: top;
            // width: calc(100% - 36px);
        }
    }
}


.input_text{
    border: 1px solid #A4A4A4;
    color: $black_color;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 14px 15px;
    background-color: $white_color !important;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    &:focus,&:hover{
        box-shadow: none !important;
        background-color: $white_color !important;
        border-color: $green_color !important;
        color: $black_color !important;
    }
    &::placeholder{
        color: #969696;
    }
}

.user_edit_profile_page {
	.mb-3 {
		margin-bottom: 20px !important;
		.input_text {
			margin-bottom: 0;
		}
	}
	.form-label {
		margin-bottom: 10px;
	}
}

// react popup design starts here

#react-confirm-alert{
    .react-confirm-alert-overlay{
        background: rgba(0 ,0 ,0 ,0.5);
        .inner_block.custom-ui{
            max-width: 600px;
            width: 100%;        
            background: $white_color;
            border-radius: 5px;
            .header_block{
                background: $blue_color;
                color: $white_color;
                padding: 16px;
                text-align: center;
                h4{
                    margin-bottom: 0;
                    line-height: 1.5;
                }
            }
            .content_section{
                padding: 30px 20px;
                text-align: center;
            }
            .footer_block{
                padding: .75rem;
                border-top: 1px solid #dee2e6;
                display: flex;
                flex-wrap: wrap;
                flex-shrink: 0;
                align-items: center;
                justify-content: flex-end;
                button{
                    margin: .25rem;
                }
            }
        }
    }
}


// react popup design ends here



// react datepicker design code starts here

.react-datepicker__tab-loop{
    .react-datepicker-popper{
        z-index: 2;
        .react-datepicker__triangle{
            &::before,&::after{
                border-bottom-color:$blue_color !important;
                left: -13px !important;
            }
        }
        .react-datepicker{
            border-radius: 0;
            .react-datepicker__header {
                border-radius: 0;
                background: $blue_color;
                .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
                color: $white_color;
                }
            }
            .react-datepicker__day--selected,.react-datepicker__day--keyboard-selected{
                background: $blue_color;
                &:hover{
                    background: $blue_color;
                }
            }
            .react-datepicker__navigation-icon{
                &::before{
                    border-color: $white_color;
                }
            }
        }
    }
}

// react datepicker design code ends here


// tooltip design section starts here

.tooltip{
    .arrow{
        position: absolute;
        display: block;
        width: .8rem;
        height: .4rem;
        &::before{
            position: absolute;
            content: "";
            border-color: transparent;
            border-style: solid;
            display: inline-block;
        }
    }
} 
.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
    bottom: -6px;
    &::before{
        bottom: 0; 
        border-width: .4rem .4rem 0;
        border-top-color: #000;
    }
}
.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
    top: -6px;
    &::before{
        bottom: 0;
        border-width: 0 .4rem .4rem;
        border-bottom-color: #000;
    }
}
.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-bottom .arrow {
    right: -6px;
    width: .4rem;
    height: .8rem;
    &::before{
        left: 0;
        border-width: .4rem 0 .4rem .4rem;
        border-left-color: #000;
    }
}
.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-bottom .arrow {
    left: -6px;
    width: .4rem;
    height: .8rem;
    &::before{
        right: 0;
        border-width: .4rem .4rem .4rem 0;
        border-right-color: #000;
    }
}
// tooltip design section ends here


#spinner {
	&.spinner_category_listing {
		.lds-spinner {
				margin: 200px auto;
		}
	}
}

// placeholders for images
.skeleton_box {
	box-shadow: 0 8px 16px 2px rgba(0, 0, 0, .1);
	border-radius: 5px;
	background-color: #fff;
}
.skeleton_lines {
  padding: 10px 20px 30px;
	> span {
		display: block;
		&:first-child {
			max-width: 60%;
		}
	}
	.react-loading-skeleton {
		border-radius: 0;
		background-color: #d1d1d1;
	}
}
.skeleton_img {
  width: 100%;
  height: 210px;
	background-color: #d1d1d1;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px 5px 0 0;
	.reset_img {
		span {
			img {
				width: 100% !important;
				height: 210px !important;
				object-fit: cover;
			}
		}
	}
}
.services_pop_section {
	.services_home_listing {
		margin-bottom: -16px;
		li {
			&.list-inline-item {
				padding: 0 7px;
				margin-bottom: 16px;
			}
		}
	}
	.skeleton_img {
		height: 160px;
		background-size: cover;
		@media (max-width: 575px) {
			height: 110px;
		}
	}
}
.slider_placeholder {
  display: flex;
	.list-inline-item {
		width: 100%;
		@media (max-width: 1400px) {
			&:nth-child(n+6) {
				display: none;
			}
		}
		@media (max-width: 991px) {
			&:nth-child(n+4) {
				display: none;
			}
		}
		@media (max-width: 575px) {
			&:nth-child(n+3) {
				display: none;
			}
		}
	}
	.skeleton_img {
		border-radius: 5px;
		background-size: cover;
	}
}
.list-inline-item {
	margin-right: 0 !important;
}
.bg_placeholder {
	background-size: 0;
}
.public__header {
	.logo-section {
		img {
			background-size: 0;
		}
	}
}
.all_blogs_section {
	.skeleton_img {
		height: 360px;
		background-size: inherit;
		background-color: #d1d1d1;
		@media (max-width: 575px) {
			height: 250px;
		}
	}
	.skeleton_lines {
		@media (max-width: 575px) {
			padding-bottom: 10px;
		}
		&.title_lines {
			margin-top: 40px;
			max-width: 350px;
			@media (max-width: 575px) {
				margin-top: 20px;
			}
		}
		&.title_desc {
			margin-bottom: 20px;
			span {
				max-width: 100%;
			}
		}
	}
}
.faq_placeholder {
	.list-inline-item {
		width: 100%;
	}
	.skeleton_lines {
		@media (max-width: 575px) {			
			padding-bottom: 10px;
		}
	}
}

// google cache styling
[id*="__google-cache-hdr"] + div {
	.placeholder_align {
		.row {
			align-items: inherit;
			> div div {
				height: 100%;
			}
			span {
				height: 100% !important;
			}
			img {
				width: 100% !important;
				height: 450px !important;
				@media (max-width: 575px) {
					height: 280px !important;
				}
			}
		}
	}
	.bg_placeholder {
		background-color: #d1d1d1;
		background-size: inherit;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.public__header {
		.logo-section {
			img {
				height: 46px !important;
				background-size: contain;
				background-repeat: no-repeat;
				@media (max-width: 767px) {
					height: 34px !important;
				}
			}
		}
	}
}
// google cache end

.back_to_top {
	position: fixed;
	z-index: 999;
	bottom: 20px;
	right: 30px;
	background-color: #00cc83;
	color: #fff !important;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
	font-size: 19px;
	line-height: 1;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s 0s ease-in-out;
	@media (max-width: 575px) {
		bottom: 10px;
		right: 20px;
		width: 40px;
		height: 40px;
		font-size: 16px;
	}
}
.scroll_active {
	.back_to_top {
		opacity: 1;
		visibility: visible;
		bottom: 30px;
		@media (max-width: 575px) {
			bottom: 20px;
		}
	}
}