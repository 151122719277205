.compose_page{
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    .new_message{
        margin: 0;
        padding: 12px 20px;
        background: $blue_color;
        font-size: 18px;
        color: $white_color;
    }
    .content-wrap{     
        .inner-wrap{
            padding: 25px 30px 40px 30px;
        }
        .form-group{
            margin-bottom: 5px;
            .demo-wrapper{
             border: 1px solid #F1F1F1;
             border-top: 0px;
             .demo-editor{
                 padding: 0px 10px;
             }
            }
        }
				.mb-3 {
					margin-bottom: 5px !important;
				}
        .field_wrap{
            border-bottom: 1px solid #e7e7e7;
            padding: 10px 0;
            .field_title{
                display: inline-block;
                margin-right: 15px;
                width: 140px;
                @media(max-width:580px){
                    width: auto;
                }
            }
            .radio_custom{
                display: inline-block;
            }
            .input_text{
                border:0;
                display: inline-block;
                width: calc(100% - 160px);
                padding: 0;
								margin-bottom: 0;
                @media(max-width:580px){
                    width: calc(100% - 100px);
                }
            }
            .multiselect-container {
                .searchWrapper{
                   margin-top: 10px;
                   margin-bottom: 10px;
                //    border:0;
                //    border-bottom: 1px solid #ccc;
                }
            }
            .select_usr{
                margin-top: 20px;
                label{
                    display: inline-block;
                    margin-right: 15px;
                    width: 140px;
                    vertical-align: middle;
                }
                .multiselect-container{
                    display: inline-block;
                    vertical-align: middle;
                    width: calc(100% - 160px);
                }
            }
        }
        .field_wrap_msg{
            margin-bottom: 40px;
            padding: 10px 0;
            .input_text{
                border:0;
                min-height: 200px;
                padding: 0;
            }
        }
        .footer_composer{
            border: 1px solid #e7e7e7;
            padding: 10px 30px;
						@media (max-width: 575px) {
							padding: 10px 15px 10px 5px;
						}
            .attachment-wrap{
                display: inline-block;
                position: relative;
                .fas.fa-times{
                    color: $white_color;
                    position: absolute;
                    right: 4px;
                    background: red;
                    padding: 2px 5px;
                    border-radius: 50%;
                    top: 0;
                    cursor: pointer;
                 }
            }
            .attchment_icon{
                cursor: pointer;
                // margin-bottom: 0;
                vertical-align: middle;
                padding-left: 37px;
                max-width: 100%;
								min-height: 40px;
								word-break: break-word;
                &::before{
                    content: '\f0c6';
                    font-family: "Font Awesome 5 Pro";
                    max-width: 50px;
                    padding: 4px 10px;
                    border: 0;
                    font-size: 18px;
                    cursor: pointer;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                input{
                    height: 48px;
                }
                .add_file_icon{
                    margin-right: 10px;
                    border: 1px solid #ccc;
                    display: inline-block;
                    padding: 7px 10px;
                    border-radius: 5px;
                }          
            }
            .uploaded-img{
                display: inline-block;
                width: 100px;
                height: auto;   
            }
            .primary-btn{
                text-transform: capitalize;
            }
        }
    }
		.textarea_wrap {
			padding: 0 10px;
		} 
}


// user composer design code starts here

.user_composer{
    padding: 60px 15px;
    @media(min-width: 1200px){
        max-width: 1140px;
        width: 100%;
        margin: auto;
    }
    @media(min-width: 1440px){
        max-width: 1550px;
        width: 100%;
        margin: auto;
    }
}


// user composer design code ends here