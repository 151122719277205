// /switch design code starts here//
.custom-control-input{
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
}
.switch-toggle {
    cursor: pointer;
    text-indent: -9999px;
    width: 102px;
    margin-bottom: 20px;
    background: red;
    border-radius: 100px;
    border: 2px solid red;
    position: relative;
    overflow: hidden;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 29px;
    padding: 0;
    // line-height: 35px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.onoffswitch-inner:before {
    content: "Active";
    padding-right: 32px;
    background:$green_color;
    color: #ffffff;
    text-align: right;
}
.onoffswitch-inner:after {
    content: "In-Active";
    padding-right: 7px;
    background-color: red;
    color: $white_color;
    text-align: right;
}
.onoffswitch-switch {
    display: block;
    width: 18px;
    margin: 6px;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 69px;
    border: 2px solid red;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

input:checked + .switch-toggle {
    border-color: $green_color;
    background: $green_color;
    .onoffswitch-inner {
        margin-left: 0;
    }
    .onoffswitch-switch {
        right: 0;
        border-color: $green_color;
    }
}

input:checked + .switch-toggle:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}
//switch design code ends here//


.rdw-editor-wrapper{
    border: 1px solid #A4A4A4;
    border-radius: 5px;
    min-height: 300px;
}


// modal design start here

.modal{
    padding: 0 !important;
    .modal-dialog{
        width: 100%;
        position: relative;
        top: 10%;
        left: 0;
        right: 0;
        max-width: 600px;
        margin: auto;
        transform: translateY(0) !important;
        .modal-header{
            background: $blue_color;
            color: $white_color;
            @media(max-width:1366px){
              padding: 12px 20px;
            }
            .modal-title{
                font-size: 22px;
                @media(max-width:1366px){
                    font-size: 18px;
                }
            }
            .btn-close{
                position: relative;
                color: $white_color;
                opacity: 1;
                background: none;
                &:focus{
                    box-shadow: none;
                }
                &:hover{
                    &:before,&:after {  
                        background-color:$green_color;
                     }
                }
                &:before,&:after {                
                    position: absolute;
                    left: 15px;
                    content: ' ';
                    height: 25px;
                    width: 2px;
                    background-color: $white_color;
                    top: 3px;
                  }
                  &:before {
                    transform: rotate(45deg);
                  }
                  &:after {
                    transform: rotate(-45deg);
                  }
                  
            }
        }
        .modal-body{
            padding: 30px 20px;
            max-height: 509px;
            // height: 100%;
            overflow-y: auto;
            @media(max-width:1366px){
                padding:20px;
              }
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
        
            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 5px;
            }
        
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $blue_color;
                border-radius: 5px;
            }
        
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: $blue_color;
            }
            .ques_listing{
               padding-left: 0;
               margin-bottom: 0;           
                  li{
                      list-style: none;
                      border: 1px solid #ccc;
                      margin-bottom: 20px;
                      border-radius: 5px;
                      padding: 20px;
                      cursor: pointer;
                      .description-text{
                          font-weight:400;
                          color: $blue_color;
                          font-size: 18px;
                          margin-bottom: 0;
                          white-space: initial;
                      }
                      .description-text-sm{
                       white-space: initial;
                      }
                  }
              }
        }
    }
}
.agency-detail-lead{
    table{
        thead{
            tr{
                background: #494949;
            }
        }
    }
}
// modal design ends here


// pagination  code starts here
.custom-pagination-admin{
    margin-top: 20px;
    ul{
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        li{
            list-style: none;
            a{
                font-size: 16px;
                color: $grey_color;
                font-weight: 500;
                background: $white_color;
                border: 1px solid #dee2e6;
                border-radius: 0px;
                padding: 0.375rem 0.75rem;
                display: block;
                text-align: center;
                &:hover{
                    background: $blue_color;
                    color: $white_color !important;
                }
                &:focus{
                    outline: 0;
                }
            }
        }
        li.previous{
            a{
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }
        }
        li.next{
            a{
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
        }
        .disabled{
            a{
                opacity: 0.5;
                pointer-events: none;
            }
        }
        .selected{
            a{
                background: $blue_color;
                color: $white_color;
            }
        }
    }
}
.pagination-wrapper{
  margin-top: 30px;
  .pagination{
      justify-content: center;
      .page-item .page-link{
          &:hover,&:focus{
            background-color: $blue_color;
            border-color: $blue_color;
            box-shadow: none;
            color: $white_color;
          }
      }
      .page-item.active {
          .page-link{
            background-color: $blue_color;
            border-color: $blue_color;
          }
      }
  }
}
.filter_section{
    margin-bottom: 30px;
    .filter-content{
        margin-top: 20px;
    }
}

// pagination  code ends here



.grid-title{
    margin-bottom: 40px;
    h3{
        display: inline-block;
        vertical-align: middle;
        font-size: 26px;
        color: #444444;
        font-weight: 500;
        margin-bottom: 0;
    }
    .text-right{
        @media(max-width:767px){
            text-align: left;
            margin-top: 20px;
        }
    }
}
.table-responsive {
    margin-bottom: 10px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $blue_color;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $blue_color;
    }
}
.table{
    margin-bottom: 0;
    @media(max-width:1440px){
        white-space: nowrap;
    }
    thead{
        tr{
            background: $blue_color;
            color: $white_color;
            th{
                padding: 10px 12px;
                text-transform: capitalize;
            }
        }
    }
    tbody{
        tr{
            td{
                white-space: initial;
                padding: 10px 12px;
                a{
                    margin-right: 10px;
                    &:hover{
                        i{
                            color: $blue_color;
                        }
                    }
                    i{
                        color: $green_color;
                    }
                    #del__btn{
                         color: red;
                    }
                }
                .uploaded-img{
                    margin-bottom: 0;
                }
                .read-more-less--more,.read-more-less--less{
                  color: $green_color;
                  font-weight: 500;
                  cursor: pointer;
                }
                .url_links{
                    word-break: break-word;
                }
            }
            .user_toggle{
                .switch-toggle{
                    margin: auto;
                }
            }
        }
        .no_result{
            padding: 10px;
            text-align: center;
        }
    }
    .sort_wrapper{
        padding-right: 36px;
        position: relative;
        .sort-icon{
            display: inline-block;
            vertical-align: top;
            text-align: right;
            cursor: pointer;
            position: absolute;
            top: 13px;
            right: 10px;
            i{
                font-size: 20px;
            }
        }
        .sort-text{
            display: inline-block;
            vertical-align: top;
            // width: calc(100% - 36px);
        }
    }
}

.form-group{
    margin-bottom: 20px;
    .input_text{
       margin-bottom: 0;
    }
}



// add quote question model design code starts here


.add_quote_ques_model{
    .add-btn{
        font-size: 22px;
    }
    .remove-btn{
       background: red;
    }
    .modal-dialog{
        max-width: 750px;
        .modal-content{
            .nav-btn{
                border: 0;
                background: $white_color;
                box-shadow: none;
                color: $grey_color;
                padding:10px 4px;
                &:disabled{
                   opacity: 0.5;
                }
            }
            .page_count{
                padding: 6px 10px;
                font-size: 19px;
                margin: 0 6px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                background: $blue_color;
                border-radius: 5px;
                color: $white_color;
            }
            .modal-body{
                overflow-y: auto;
                height: 100%;
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                }
            
                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                    border-radius: 5px;
                }
            
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $blue_color;
                    border-radius: 5px;
                }
            
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: $blue_color;
                }
                .sub_option_section{
                    padding: 30px 30px;
                    border-radius: 5px;
                    margin: 20px 0;
                    margin-top: 50px;
                    position: relative;
                    box-shadow: 0 8px 16px 2px rgba(0 ,0 ,0 ,0.10);
                    padding-top: 60px;
                    background: #f7f7f7;
                    .close_icon{
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: $green_color;
                        color: $white_color;
                        padding: 5px 10px;
                        border-bottom-left-radius: 5px;
                        cursor: pointer;
                        &:hover{
                            background: $blue_color;
                        }
                    }             
                }
                .ques_btns{
                    @media(max-width:767px){
                        button{
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                }
                .primary-btn,.secondary-btn{
                    vertical-align: top;
                    padding: 8px 15px !important;
                }
                .level-heading{
                    font-size: 20px;
                    color: $black_color;
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 15px;
                    margin-bottom: 30px;
                }
                .parent_ques{
                    color: $black_color;
                    font-size: 16px;
                }
                select.input_text{
                    option{
                        padding: 10px 5px;
                    }
                }
            }
            .note-line{
                padding: 10px 20px;
            }
            .modal-footer{
                display: block;
            }
        }
    }
}
.already-has-ques-modal{
    .modal-body{
        text-align: center;
        h4{
            font-size: 20px;
            margin-bottom: 20px;
        }
        p{
            a{
                color: $blue_color;
                font-weight: 600;
                cursor: pointer;
                &:hover{
                    color: $green_color;
                }
            }
        }
    }
}
.submit-question-modal.modal-footer{
    .primary-btn{
        padding: 13px 27px !important;
    }
    .second-btn{
        padding: 12px 27px !important;
    }
}

// add quote question model design code ends here



// request review loader design code starts here

#spinner.spinner_req_review{
    // background-color: rgba(0 ,0 ,0 ,0.8);
    .lds-spinner {
        color: white;
        display: inline-block;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        width: 80px;
        height: 80px;
        margin: auto;
        div {
            transform-origin: 40px 35px;
            animation: lds-spinner 1.2s linear infinite;
            &::after{
                content: " ";
                display: block;
                position: absolute;
                top: 3px;
                left: 37px;
                width: 6px;
                height: 18px;
                border-radius: 20%;
                background: #00cc83;
            }
            &:nth-child(1) {
                transform: rotate(0deg);
                animation-delay: -1.1s;
              }
              &:nth-child(2) {
                transform: rotate(30deg);
                animation-delay: -1s;
              }
              &:nth-child(3) {
                transform: rotate(60deg);
                animation-delay: -0.9s;
              }
              &:nth-child(4) {
                transform: rotate(90deg);
                animation-delay: -0.8s;
              }
              &:nth-child(5) {
                transform: rotate(120deg);
                animation-delay: -0.7s;
              }
              &:nth-child(6) {
                transform: rotate(150deg);
                animation-delay: -0.6s;
              }
              &:nth-child(7) {
                transform: rotate(180deg);
                animation-delay: -0.5s;
              }
              &:nth-child(8) {
                transform: rotate(210deg);
                animation-delay: -0.4s;
              }
              &:nth-child(9) {
                transform: rotate(240deg);
                animation-delay: -0.3s;
              }
              &:nth-child(10) {
                transform: rotate(270deg);
                animation-delay: -0.2s;
              }
              &:nth-child(11) {
                transform: rotate(300deg);
                animation-delay: -0.1s;
              }
              &:nth-child(12) {
                transform: rotate(330deg);
                animation-delay: 0s;
              }
          }
      }
}
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .right__agency_loader{
    #spinner.spinner_req_review{
        .lds-spinner{
            bottom: auto;
        }
    }
}

  // request review loader design code starts here



// user edit profile page design code starts here

.user_edit_profile_page{
    .profile_section{
        margin-bottom: 60px;
        img{
            width: 88px;
            height: 88px;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;
            border: 1px solid #ccc;
            @media(max-width:767px){
                margin-bottom: 20px;
            }
        }
        .upload-btn{
            display: inline-block;
            vertical-align: middle;
        }
    }
    .user_detail_form_wrap{
        .radio-btn {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            &:checked + .c_label {
                &::after{
                    background: $green_color;
                }
            }
        }
        
        .c_label{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: $grey_color;
            font-size: 16px;
            margin-bottom: 20px;
            cursor: pointer;
            position: relative;
            &::before {
                display: inline-block;
                content: "";
                height: 20px;
                width: 20px;
                margin-right: 10px;
                border: 2px solid $blue_color;
                border-radius: 50%;
                transition: all 0.25s linear;
            }
            &::after{
                height: 10px;
                width: 10px;
                display: inline-block;
                content: "";
                background: $white_color;
                position: absolute;
                top: 7px;
                left: 5px;
                border-radius: 50%;
            }
        }
        .label-text{
            margin-bottom: 10px;
            font-weight: 500;
        }
    }
    .password_block{
        margin-top: 40px;
        .description-text{
            margin-bottom:30px;
            padding-bottom: 20px;
            font-weight: 600;
            border-bottom: 1px solid #ccc;
        }
    }
    .primary-btn{
        text-transform: capitalize;
    }
}

// user edit profile page design code ends here



// edit question design code starts here


.edit-question-table{
   table{
       tbody{
           tr{
               tr{
                   border: 0;
               }
               .action-wrp{
                   width: 80px;
                   margin: auto;
                   text-align: center;
                   span{
                       display: block;
                       text-align: center;
                       cursor: pointer;
                   }
                   i{
                       color: $blue_color;
                       cursor: pointer;
                   }
                   .close_icon{
                       color: red;
                   }        
                   .fa-plus{
                    color: $green_color;
                    }
                    .fa-edit{
                        padding-left: 10px;
                    }
               }
               .action-wrp.add-edit-action{
                .fa-edit.edit_ques{
                    pointer-events: none;
                    color: #bcbcbc;
                    margin-right: 10px;
                }
              }
           }
           .option-col-wrap{
               @media(max-width:1400px){
                width: 300px;
               }
               .left-side-block{
                   display: inline-block;
                   vertical-align: middle;
                   width: calc(100% - 35px);
                   .input_text{
                       margin-bottom: 0;
                   }
               }
            i{
                width: 20px;
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
                color: $blue_color;
                margin-left: 12px;
            }
            i.fa-check{
                color: $green_color;
            }
           }
       }
   }
}

.edit__question_modal {
    .first-option-add{
        font-size: 22px;
    }
    .sub_option_section{
        padding: 30px 30px;
        border-radius: 5px;
        margin: 20px 0;
        margin-top: 50px;
        position: relative;
        box-shadow: 0 8px 16px 2px rgba(0 ,0 ,0 ,0.10);
        padding-top: 60px;
        background: #f7f7f7;
        .close_icon{
            position: absolute;
            top: 0;
            right: 0;
            background: $green_color;
            color: $white_color;
            padding: 5px 10px;
            border-bottom-left-radius: 5px;
            cursor: pointer;
            &:hover{
                background: $blue_color;
            }
        }             
    }
    .cat-heading-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
        .left-wrap{
            width: calc(100% - 95px);
            h5{
                &:last-child{
                    margin-top: 10px;
                }
            }
        }
        h5{
            font-size: 20px;
            color: $black_color;
            margin: 0;
            padding-right: 20px;
        }
        .primary-btn{
            width: 95px;
            margin-left: auto;
        }
    }
    .edit-action-option{
        display: flex;
        align-items: center;
        .form-switch{
            margin-right: 20px;
            @media(max-width:767px){
                margin-right: 5px;
            }
            input{
                &:hover,&:focus{
                    box-shadow: none;
                }
                &:checked{
                    background-color: $blue_color;
                }
            }
        }
    }

}
.view__question_modal{
    .cat-heading-wrap{
        padding: 15px;
        border: 1px solid #ccc;
        margin-bottom: 30px;
        background: #f7f7f7;
        border-radius: 5px;
        h5{
            font-size: 20px;
            color: $black_color;
            margin: 0;
            padding-right: 20px;
            margin-bottom: 0;
            @media(max-width:1366px){
                font-size: 18px;
            }
        }
    }
    .view-ques-wrap{
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 20px;
        .ques-text{
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
            color: $blue_color;
            font-weight: 600;
            font-size: 20px;
            @media(max-width:1366px){
                font-size: 18px;
            }
        }
        .option-list{
            padding-left: 0;
            margin-bottom: 0;
            li{
                list-style: none;
                padding-left: 16px;
                position: relative;
                margin-bottom: 10px;
                font-size: 18px;
                @media(max-width:1366px){
                    font-size: 16px;
                }
                &::before{
                    content: "\f0da";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 900;
                    left: 0;
                    top: 0;
                    color: $green_color;
                    position: absolute;
                }
            }
        }
    }
}
// edit question design code ends here


// followers design code starts here

.dashboard_page-followers{
        .f-follower-list{
            position: relative;
            .ribbon {
                width: 110px;
                height: 110px;
                overflow: hidden;
                position: absolute;
                z-index: 1;
                &::before,&::after{
                    position: absolute;
                    z-index: -1;
                    content: '';
                    display: block;
                    border: 5px solid red;
                }
                span {
                    position: absolute;
                    display: block;
                    width: 176px;
                    padding: 10px 0;
                    background-color: red;
                    box-shadow: 0 5px 10px rgba(0,0,0,.1);
                    color: #fff;
                    font-weight: 600;
                    font-size: 12px;
                    text-shadow: 0 1px 1px rgba(0,0,0,.2);
                    text-transform: uppercase;
                    text-align: center;
                  }
              }
              /* top left*/
              .ribbon-top-left {
                top: -10px;
                left: 3px;
                &::before,&::after{
                    border-top-color: transparent;
                    border-left-color: transparent;
                }
                &::before{
                    top: 0;
                    right: 15px;
                }
                &::after{
                    bottom: 15px;
                    left: 0;
                }
                span {
                    right: -12px;
                    top: 15px;
                    transform: rotate(-45deg);
                  }
              } 
        }
    .followers-details{
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 200px;
    overflow-y: auto;
    padding: 20px;
    display: block;
    margin-bottom: 20px;
    background: #fcfcfc;
    position: relative;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $blue_color;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $blue_color;
    }
   
    .profile_detail_wrap{
        display: flex;
        width: 100%;
        @media(max-width:767px){
          margin-bottom: 20px;
          width: 100%;
        }
        .img-block{
            background: #fff;
            border-radius: 50%;
            display: block;
            margin:0 auto;
            max-width: 100%;
            overflow: hidden;
            width: 60px;
            height: 60px;
            border: 1px solid #ccc;
         
          span{
            display: block;
            height: 0;
            overflow: hidden;
            padding-bottom: 100% !important;
            position: relative;
            z-index: 0;
            background: $white_color;
            border-radius: 50%;
            img{
                bottom: 0;
                display: block;
                height: auto;
                left: 0;
                margin: auto;
                max-width: 100%;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;         
            }
          }
        }
        .description-wrap{
            padding-left: 20px;
            width: calc(100% - 60px);
            .name{
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 10px;
                text-transform: capitalize;
                @media(max-width:767px){
                 font-size: 18px;
                }
            }
            .address{
                margin-bottom: 0;
                color: $grey_color;
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 5px;
            }
            .follow-block{
                margin-top: 10px;
                .follow-btn{
                    background: $blue_color;
                    color: $white_color;
                    padding: 6px 20px;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 20px;
                    margin-right: 10px;
                    transition: all 0.4s ease-in-out;
                    cursor: pointer;
                    &:hover{
                        background: $green_color;
                    }
                }
                span{
                    margin-left: 10px;
                    font-weight: 600;
                }
            }
        }
    }
    }
    .nav-pills{
        border-bottom: 1px solid  #e4e4e4;
        padding: 0 20px;
        @media(max-width:767px){
            flex-direction: column !important;
            padding: 0;
            border-bottom: 0px;
        }
        .nav-item{
            .nav-link{
                background-color: $white_color;
                border-radius: 0;
                padding: 15px 20px;
                transition: all 0.4s ease-in-out;
                border:0;
                position: relative;
                font-size: 16px;
                color: #404040;
                font-weight: 500;
                margin-bottom: 0;
                @media(max-width:767px){
                    border-radius: 0px;
                    border: 1px solid #e4e4e4;
                    border-top: 0px;
                }
                &:focus-visible{
                    outline: 0;
                }
                &::before{
                    content: '';
                    background:#ecbe7a;
                    left: 0;
                    width: 0;
                    height: 3px;
                    bottom: 0;
                    position: absolute;
                    transition: all 0.4s ease-in-out;
                    @media(max-width:767px){
                      display: none;
                    }
                }
                &:hover{
                    &::before{
                        width: 100%;
                    }
                }
            }
            .nav-link.active{
                @media(max-width:767px){
                   background:#ecbe7a;
                }
                &::before{
                    width: 100%;
                }
            }
            &:first-child{
                .nav-link{
                    @media(max-width:767px){
                        border-top:  1px solid #e4e4e4;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }
                }
            }
            &:last-child{
                .nav-link{
                    @media(max-width:767px){
                        border-bottom:  1px solid #e4e4e4;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }
    }
    .tab-content{
        margin-top: 40px;
        .user_agency-like{
            margin-bottom: 25px;
            .agency_popup_detail{
                .detail-wrap{
                    .name{
                        min-height: 30px;
                    }
                } 
            }
        }
    }
}

// followers design code ends here


// widget design code starts here


.widgets-page{
    .style-review-listing{
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        overflow: auto;
        padding-top: 20px;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 5px;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $blue_color;
            border-radius: 5px;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $blue_color;
        }
        li{
            list-style: none;
            padding: 0 10px;
            padding-bottom: 10px;
            input[type=radio]{
                display: none;
            }
            .inner-option{
                padding: 20px;
                position: relative;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                &:after{
                    position: absolute;
                    content: '\f00c';
                    right: -9px;
                    top: -12px;
                    font-family: "Font Awesome 5 Pro";
                    display: none;
                    font-weight: 900;
                    font-size: 16px;
                    width: 32px;
                    height: 32px;
                    background: $blue_color;
                    padding: 4px;
                    border-radius: 50%;
                    text-align: center;
                    color: $white_color;
                    z-index: 1;
                }
                img{
                    max-width: 100%;
                }          
            }      
            p{
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                margin: 10px;
            }     
              /* Stuff after this is only to make things more pretty */
              input[type=radio] + label>.inner-option {
                border: 2px solid #e7e7e7;
                width: 180px;
                height: 150px;
                transition: 500ms all;
              }
              
              input[type=radio]:checked + label> .inner-option {
                border: 2px solid $blue_color;
              }
              input[type=radio]:checked + label> .inner-option::after{
                  display: block;
              }
        }
    }
}



// widget design code ends here