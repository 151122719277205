@import 'variables';


.all_categories_section{
    .main-heading{
        padding: 60px 10px;
    }
    .category_listing{
        box-shadow: 0px 10px 16px 2px rgba(0,0,0,0.2);
        background: $white_color;
        padding: 65px 60px;
        position: relative;
        #spinner{
            height: 100%;
            width: 100%;
            background-color: #eaf7ff;
            position: absolute;
            z-index: 99;
            display: block;
            top: 0;
            left: 0;
            .lds-spinner{ 
                div{&::after{
                    background: #00cc83;
                    }
                }

            }
            }
        @media(max-width:767px){
            padding: 45px 40px;
        }
        .cat_list_view{
            column-count: 3;
            @media(max-width:991px){
                column-count: 3;
            }
            @media(max-width:767px){
                column-count: 2;
            }
            @media(max-width:640px){
                column-count: 1;
            }
            .listing_cat_main{
                display: inline-block;
                width: 100%;
            }
        }
        ul{
            padding-left: 0;
            margin-bottom: 0;
            li{
                a{
                    font-size: 16px;
                    color: $grey_color;
                    font-weight: 400;
                    text-decoration: none;
                    margin-bottom: 28px;
                    display: inline-block;
                    transition: all 0.3s ease-in-out;
                    @media(max-width:1366px){
                        margin-bottom: 20px;
                        font-size: 14px;
                    }
                    @media(max-width:767px){
                        margin-bottom: 18px;
                    }
                    &:hover{
                        color: $green_color;
                    }
                }    
                #More_list_act{
                    font-size: 20px;
                    color: $blue_color;
                }  
            }
            .level0.parent_li{
                font-size: 20px;
                color: $blue_color;
                font-weight: 500;
                margin-bottom: 25px;
                @media(max-width:1366px){
                    margin-bottom: 18px;
                    font-size: 18px;
                }
                ul{
                    padding-top: 10px;
                }
            }
        }
    }
}