@import 'variables';


// agency listig page design code starts here


.agency_top_description{
    padding: 60px 0;
    .main-heading{
        color: $grey_color;
        font-size: 30px;
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    .breadcrumb_text{
         font-size: 14px;
         color: $grey_color;
         font-weight: 500;
         margin-bottom: 50px;
         span{
             color: $blue_color;
         }
    }
}


.listing_section_agency{
    background: #eaf7ff;
    padding: 60px 0;
    @media(max-width:767px){
       padding: 30px 0px;
    }
    .inner_block_wrapper{
        .location_sort_filter{
            padding-bottom: 60px;
            @media(max-width:767px){
                padding-bottom: 30px;
            }
               .filter_section{
                   background: transparent;
                   .loc_filter_wrap{
                       position: relative;
                       input{
                        font-family: "Font Awesome 5 Pro";
                        padding: 10px 12px;
                        border-radius: 5px;
                        border: 1px solid #a4a4a4;
                        font-size: 18px;
                        width: 100%;
                        min-height: 51px;
                        padding-right: 144px;
                        @media(max-width:1366px){
                            font-size: 16px;
                        }
                        &::placeholder{
                            color: #a4a4a4;
                            font-weight: 300;
                        }      
                        &:focus{
                            outline: 0;
                            box-shadow: none;
                            border-color: $green_color;
                        }           
                       }
                       .primary-btn {
                        top: 0;
                        position: absolute;
                        right: 0;
                        padding: 13.5px 27px !important;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        font-size: 16px;
                        &::before{
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                        }
                      
                       }
                   
                       .fal.fa-times{
                        top: 17px;
                        position: absolute;
                        right: 128px;
                        cursor: pointer;
                       }
                   }
               }
               .filter_sort_wrap{
                   text-align: right;
                   @media(max-width:991px){
                       text-align: left;
                   }
                h5{
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 0;
                    font-size: 20px;
                    color: #3c3c3c;
                    font-weight: 500;
                    margin-right: 15px;
                    @media(max-width:991px){
                      display: block;
                      margin-bottom: 10px;
                    }
                }
                .dropdown{
                 display: inline-block;
                 vertical-align: middle;
                 max-width: 200px;
                 width: 100%;
                 @media(max-width:991px){
                    display: block;
                    max-width: 100%;
                  }
                 button{
                    padding: 10px 12px;
                    border-radius: 5px;
                    text-align: left;
                    border: 1px solid #a4a4a4;
                    position: relative;
                    width: 100%; 
                    background: $white_color;
                    color: #a4a4a4;
                    &:focus{
                        outline: 0;
                        box-shadow: none;
                        border-color: $green_color;
                    }
                    &::after{                   
                        position: absolute;
                        right: 10px;
                        top: 18px;
                        border-top: 0.3em solid #00cc83;
                    }
                 }     
                 .dropdown-item{
                     position: relative;
                     i.checkBoxSort{
                         position: absolute;
                         right: 12px;
                         top: 6px;
                         color: $green_color;
                     }
                 }
                 .dropdown-menu {
                     width: 100%;
                 }
                }
            }
        }
        .filter_section{
            max-width: 455px;
            width: 100%;
            display: inline-block;
            vertical-align: top;
            background: $white_color;
            border-radius: 5px;
            @media(max-width:1440px){
                max-width:350px;
            }
            @media(max-width:991px){
                max-width: 100%;
                margin-bottom: 30px;
                background: transparent;
            }
            .filter_heading{
                font-size: 20px;
                color: $grey_color;
                font-weight: 500;
                background: #ecbe7a;
                margin: 0;
                padding: 13px 20px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                @media(max-width:991px){
                    background: transparent;
                    border-bottom: 1px solid #E4E4E4;
                    border-top: 1px solid #E4E4E4;
                }
            }
            .option-inner-wrap{
                padding: 0px 30px;
                width: 100%;
                display: block;
                @media(max-width:991px){
                    padding: 0px 20px;
                }
                li{
                    &:first-child{
                        .filter__options{
                            border-top: 0;
                        }            
                    }
                    &:last-of-type{
                        .filter__options{
                        border-bottom:0;
                        }
                    }
                    .form-group{
                        margin-bottom: 10px;
                    }
                }
                .filter__options{
                    font-size: 20px;
                    color: #3c3c3c;
                    display: block;
                    font-weight: 500;
                    border-bottom: 1px solid #E4E4E4;
                    // border-top: 1px solid #E4E4E4;
                    padding: 25px 0;
                    position: relative;
                    border-collapse: collapse;
                    &:focus-visible,&:visited{
                        &:after{
                            transform: rotate(180deg);
                        }
                    }
                    @media(max-width:1366px){
                        padding: 20px 0;
                    }
                    @media(max-width:991px){
                        padding:15px 0;
                        font-size: 18px;
                        border: 0;
                    }
                    &:after{
                        content: '\f0d7';
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 700;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        right: 0;
                        color: $green_color;
                    }
                }
                ul{
                    padding-left: 0;
                    margin-bottom: 0;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    li{
                        margin-bottom: 6px;
												.mb-3 {
													margin-bottom: 0 !important;
												}
                         input {
                            padding: 0;
                            height: initial;
                            width: initial;
                            margin-bottom: 0;
                            display: none;
                            cursor: pointer;
                          }         
                           label {
                            position: relative;
                            cursor: pointer;
                            font-size: 16px;
                            color: $grey_color;
                            font-weight: 300;
                            padding-left: 30px;
                            &:before {
                                content:'';
                                -webkit-appearance: none;
                                background-color: transparent;
                                border: 1px solid $grey_color;
                                padding: 6px;
                                display: inline-block;
                                position: absolute;
                                vertical-align: middle;
                                cursor: pointer;
                                margin-right: 15px;
                                left: 0;
                                top: 4px;
                              }
                          }                         
                           input:checked + label:after {
                            content: '\f00c';
                            font-family: "Font Awesome 5 Pro";
                            display: block;
                            position: absolute;
                            top: 5px;
                            // color: $grey_color;
                            color: $white_color;
                            left: 3px;
                            font-size: 9px;
                            font-weight: 600;
                          }
                          input:checked + label:before{
                            background-color: #00cc83;
                            border: 1px solid #00cc83;
                          }
                    }
                }
                .filter_opt_list{
                    /*padding-top: 25px;*/
                    padding-bottom: 15px;
                    border-bottom: 1px solid #E4E4E4;
                    &:last-of-type{
                        border-top: 1px solid #E4E4E4;
                    }
                    ul{
                        padding-bottom: 8px;
                    }
                    .load-more{
                        border:0;
                        border-bottom: 1px solid grey;
                        background-color: transparent;
                        color: $green_color;
                        text-align: center;
                        display: block;
                        text-align: center;
                        margin-bottom: 8px;
                        &:hover{
                            color: #00CC83;
                            border-color: #00CC83;
                        }
                    }
                }
            }   
            .filter_navbar{
                padding: 0;
                .navbar-toggler{
                    border: 0;
                    box-shadow: none;
                    padding: 0;
                    .navbar-toggler-icon{
                        position: relative;
                        &::after{
                            content: 'Filters';
                            position: absolute;
                            left: 38px;
                            top: 5px;
                            color: $grey_color;
                            display: inline-block;
                        }
                    }          
                }
                   .navbar-collapse{
                       display: block !important;
                       @media(max-width:991px){
                        //    display: none !important;
                           min-height: 100%;
                           width: 300px;
                           height: 100%;
                           top: 0;
                           right: -300px;
                           position: fixed;
                           background: $white_color;
                           overflow-y: auto;
                           z-index: 999;
                           padding-top: 60px;
                           padding-bottom: 30px;
                           box-shadow: 0 8px 16px 2px rgba(0, 0 ,0 ,0.1);
                           transition: all .4s ease-in-out;
                       }
                       .navbar-toggler{
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #2471eb;
                        color: #fff;
                        width: 32px;
                        height: 34px;
                        text-align: center;
                        border-radius: 0 0 10px 0 ;
                           .navbar-toggler-icon{
                               display: none;
                           }
                           &::before,&::after{
                            position: absolute;
                            left: 17px;
                            content: ' ';
                            height: 16px;
                            width: 2px;
                            background-color: $white_color;
                            top: 8px;
                           }
                           &::before{
                            transform: rotate(45deg);
                           }
                           &::after{
                            transform: rotate(-45deg);
                           }
                       }
                   }
                   .navbar-collapse.show{
                    display: block !important; 
                    right: 0;
                    .profile_section{
                        padding: 0px 20px;
                    }
                   }
            }      
        }
        .right__agency_section{
            width: calc(100% - 455px);
            display: inline-block;
            vertical-align: top;
            padding-left: 30px;
            position: relative;
            min-height: 100px;
            #spinner{
                height: 100%;
                width: 100%;
                background-color: #eaf7ff;
                position: absolute;
                z-index: 99;
                display: block;
                top: 0;
                left: 0;
                .lds-spinner{ 
                    div{&::after{
                        background: #00cc83;
                        }
                    }

                }
            }
            @media(max-width:1440px){
                width: calc(100% - 350px);
            }
            @media(max-width:991px){
                width: 100%;
                padding-left: 0;
            }
           .agency-list-detail_wrapper{
               background: $white_color;
               border-radius: 5px;
               padding: 0 30px;
               margin-bottom: 30px;
               position: relative;
               @media(max-width:640px){
                   padding: 30px;
               }
               &:last-child{
                   margin-bottom: 0;
               }
               .ribbon {
                width: 134px;
                height: 134px;
                overflow: hidden;
                position: absolute;
                z-index: 1;
                &::before,&::after{
                    position: absolute;
                    z-index: -1;
                    content: '';
                    display: block;
                    border: 5px solid red;
                }
                span {
                    position: absolute;
                    display: block;
                    width: 214px;
                    padding: 10px 0;
                    background-color: red;
                    box-shadow: 0 5px 10px rgba(0,0,0,.1);
                    color: #fff;
                    font-weight: 600;
                    text-shadow: 0 1px 1px rgba(0,0,0,.2);
                    text-transform: uppercase;
                    text-align: center;
                  }
              }
              /* top left*/
              .ribbon-top-left {
                top: -10px;
                left: -10px;
                &::before,&::after{
                    border-top-color: transparent;
                    border-left-color: transparent;
                }
                &::before{
                    top: 0;
                    right: 0;
                }
                &::after{
                    bottom: 0;
                    left: 0;
                }
                span {
                    right: -25px;
                    top: 30px;
                    transform: rotate(-45deg);
                  }
              }              
               .upper-section{
                border-bottom: 1px solid #E4E4E4;
                .p_img_wrap{
                    background: #fff;
                    display: inline-block;
                    vertical-align: middle;
                    margin: auto;
                    max-width: 100%;
                    overflow: hidden;
                    width: 146px;
                    padding: 10px 0;
                    @media(max-width:640px){
                        display: block;
                    }
                       span{
                        display: block;
                        height: 0;
                        overflow: hidden;
                        padding-bottom: 0 !important;
                        position: relative;
                        z-index: 0;
                            img{
                                bottom: 0;
                                display: block;
                                height: auto;
                                left: 0;
                                margin: auto;
                                max-width: 100%;
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 100%;
                                border-radius: 0;
                                border: 0;
                            }
                       }
                   }
                   .img_agency{
                    border-radius: 5px;
                    width: 186px;
                    display: inline-block;
                    vertical-align: top;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    text-align: center;
                    @media(max-width:1400px){
                        width: 150px;
                        padding-left:0;
                        padding-right: 25px;
                    }         
                    @media(max-width:640px){
                        width:100%;
                       text-align: center;
                       padding-bottom: 0;
                       padding-left: 0;
                       padding-right: 0;
                     }    
                    img{
                        @media(max-width:640px){
                            width:100px; 
                         }   
                        border-radius: 5px;
                        width: auto;
                        max-width: 100%;
                    }  
                   }
                   .content-wrap{
                    width: calc(100% - 404px);
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 49px;
                    padding-top: 20px;
                    position: relative;
                    padding-bottom: 20px;
                    border-right: 1px solid #E4E4E4;
                    @media(max-width:1400px){
                         width: calc(100% - 368px);
                         padding-left: 25px;
                         padding-right: 25px;
                    }
                    @media(min-width:992px)and (max-width:1199px){
                        width: calc(100% - 150px);
                        border:0;
                     }
                     @media(max-width:767px){
                        width: calc(100% - 150px);
                        border:0;
                        padding-bottom: 0;
                        padding-top: 20px;
                     }
                     @media(max-width:640px){
                        width:100%;
                        padding-left: 0;
                        padding-right: 0;
                        text-align: center;
                     }
                     a{
                         &:hover{
                            color: $green_color;
                            .agency-name{
                                color: $green_color;
                            }
                         }
                     }
                       .agency-name{
                        font-size: 20px;
                        color: $grey_color;
                        font-weight: bold;
                        margin: 0;
                        text-transform: capitalize;
                        @media(max-width:640px){
                         text-align: center;
                         }
                       }
                       .rating-list{
                           padding-left: 0;
                          margin: 0px 0;
                          @media(max-width:1366px){
                            margin: 0px 0;
                          }
                           li{
                            font-size: 20px;
                            color: $grey_color;
                            font-weight: 600;
                            margin-right: 10px;
                            vertical-align: middle;
                            margin-bottom: 0px;
                            a{
                                &:hover{
                                    .reviews{
                                    color:$green_color !important;
                                    }
                                }
                            }
                            @media(max-width:1366px){
                               font-size: 18px;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            .star-rating{
                                padding-left: 0;
                                margin-bottom: 0;
                                li{
                                    display: inline-block;
                                    margin-right: 0px;
                                    vertical-align: top;
                                    i{
                                        font-size: 15px;
                                        color: #ffba00;
                                    }
                                }
                            }
                            .reviews{
                                font-size: 16px;
                                font-weight: 300;
                                text-decoration: underline;
                                margin-bottom: 0;
                                transition: all 0.3s ease-in-out;
                                font-family: $secondary-font;
                                &:hover{
                                    color: $green_color;
                                }
                                @media(max-width:1366px){
                                    font-size: 14px;
                                 }
                            }
                            img{
                                margin-right: 20px;
                                display: inline-block;
                                vertical-align: top;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                           }
                       }
                       .description-text-sm{
                           text-align: justify;
                        a{
                            color: $green_color;
                            transition: all 0.3s ease-in-out;
                            display: inline-block;
                            &:hover{
                                color: $blue_color;
                            }
                        }
                    }                  
                   }  
                   .btn_block_right{
                    width: 218px;
                    display: inline-block;
                    vertical-align: middle;
                    padding-left: 30px;
                    // padding-top: 40px;
                    padding-bottom: 18px;
                    @media(max-width:1400px){
                        padding-top: 30px;
                    }
                    @media(min-width:992px)and (max-width:1199px){
                        width:100%;
                        padding-left: 0;
                        padding-top: 0px;
                        display: flex;
                     }
                     @media(min-width:641px)and (max-width:767px){
                        width:100%;
                        padding-left: 0;
                        padding-top: 20px;
                        display: flex;
                     }
                     @media (max-width:640px){
                        width:100%;
                        padding-left: 0;
                        padding-top: 20px;
                     }
                       .review_block{
                           margin-bottom: 30px;
                           @media(min-width:992px)and (max-width:1199px){
                            margin-bottom: 0;
                            flex: auto;
                            margin-right: 20px;
                            width: 100%;
                           }
                           @media(min-width:641px)and (max-width:767px){
                            margin-bottom: 0;
                            flex: auto;
                            margin-right: 20px;
                            width: 100%;
                           }
                           @media(max-width:640px){
                             margin-bottom: 20px;
                           }
                       }
                       .second-btn{
                        @media(min-width:1100px){
                            &:hover{
                                border: 1px solid transparent !important;
                                background: transparent;
                            }
                        }
                        @media(min-width:992px)and (max-width:1199px){
                        padding: 11px 27px !important;
                        }
                       }
                       .primary-btn,.second-btn{
                             width: 100%;
                             text-align: center;
                             @media(min-width:992px)and (max-width:1199px){
                              margin-bottom: 0;
                              flex: auto;
                             }
                             @media(min-width:641px)and (max-width:767px){
                                margin-bottom: 0;
                                flex: auto;
                               }
                       }
                       .primary-btn{
                           i{
                               display: none;
                           }
                       }
                       .adv{
                           margin-bottom: 0;
                           margin-top: 10px;
                           i{
                               font-size: 25px;
                           }
                       }
                   }           
               }
               .bottom-section{
                   .tags-wrap{
                       padding: 24px 0;
                       .tag-text{
                        font-size: 20px;
                        color: $grey_color;
                        font-weight: 400;
                        margin-right: 15px;
                        margin-bottom: 6px;
                       }
                       .tag-listing{
                           display: inline-block;
                           padding-left: 0;
                           margin-bottom: 0;
                           vertical-align: top;
                           li{
                            font-size: 14px;
                            color: $grey_color;
                            margin-bottom: 6px;
                            vertical-align: top;
                            font-weight: 400;
                            border-radius: 15px;
                            background-color: #ffffff;
                            border: 1px solid #a4a4a4;
                            padding: 4px 20px;
                            margin-right: 15px;
                            transition: all 0.3s ease-in-out;
                            &:last-child{
                                margin-right: 0;
                            }
                            &:hover{
                                border-color: $green_color;
                                color: $green_color;
                            }
                           }
                           li.active{
                               border-color: $green_color;
                               color: $green_color;
                           }
                       }
                   }
               }
           }
           .pagination-wrapper{
               padding-top: 30px;
           }
           .no_result{
               p{
                   text-align: center;
               }
           }
        }
    }
}



// agency listig page design code ends here



// agency detail page code starts here
.agency-banner_section{
    height: 290px;
    // @media(max-width:1440px){
    //     height: auto;
    // }
		span {
			position: static !important;
			width: 100% !important;
			height: 100% !important;
		}
    img {
				position: static !important;
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
        object-position: top;
    }
}

.agency_detail_wrapper{
    position: relative;
    @media (min-width: 1200px){
        .container-xl {
            max-width: 1500px;
        }
    } 
    @media (min-width: 1600px){
        .container-xl {
            max-width: 1795px;
        }
    }    
    &::before{
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         background: #eaf7ff;
         height: 80px;
        z-index: -1;
        @media(max-width:1366px){
            height:65px;
         }
    }
    .left_detail_wrapper{
        width: 100%;
        max-width: 420px;
        display: inline-block;
        vertical-align: top;
        background: $white_color;
        box-shadow: 0px 8px 8px rgba(0,0,0,0.1);
        padding: 30px;
        border-radius: 5px;
        margin-top: -125px;
        position: relative;
        @media(max-width:1440px){
            margin-top: -70px;
        }
        @media(max-width:1280px){
            margin-top: -30px;
        }
        @media(min-width:1281px){
            position: sticky;
            top: 20px;
        }
        @media(max-width:1366px){
            max-width: 350px;
        }
        @media(max-width:991px){
            max-width: 100%;
            margin-bottom: 80px;
            margin-top: 10px;
        }
        .ribbon {
            width: 134px;
            height: 134px;
            overflow: hidden;
            position: absolute;
            z-index: 1;
            &::before,&::after{
                position: absolute;
                z-index: -1;
                content: '';
                display: block;
                border: 5px solid red;
            }
            span {
                position: absolute;
                display: block;
                width: 214px;
                padding: 10px 0;
                background-color: red;
                box-shadow: 0 5px 10px rgba(0,0,0,.1);
                color: #fff;
                font-weight: 600;
                text-shadow: 0 1px 1px rgba(0,0,0,.2);
                text-transform: uppercase;
                text-align: center;
              }
          }
          /* top left*/
          .ribbon-top-left {
            top: -10px;
            left: -10px;
            &::before,&::after{
                border-top-color: transparent;
                border-left-color: transparent;
            }
            &::before{
                top: 0;
                right: 0;
            }
            &::after{
                bottom: 0;
                left: 0;
            }
            span {
                right: -25px;
                top: 30px;
                transform: rotate(-45deg);
              }
          } 
        .img_agency{
            text-align: center;
            border-radius: 5px;
            @media(min-width:641px) and (max-width:991px){
               display: inline-block;
               vertical-align: top;
               width: 120px;
            }
            img{
                width: 113px;
                border-radius: 5px;
                height: 113px;
            }
        }
        .company_address-wrap{
            color: $grey_color;
            padding: 15px 0;
            text-align: center;
            @media(min-width:641px) and (max-width:991px){
                display: inline-block;
                vertical-align: top;
                width: 100%;
                // text-align: left;
                // padding-left: 20px;
                // padding-top: 0;
             }
            .main-heading{
                color: $grey_color;
                text-transform: capitalize;
                @media(max-width: 991px){
                    font-size: 25px;
                }
            }
            .company_cat{
                font-size: 16px;
                margin: 10px 0;
                margin-top: 10px;
                display: inline-block;
                @media(max-width: 1366px){
                    font-size: 16px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                span{
                    display: inline-block;
                }
            }
            .description-text{
                padding-right: 20px;
            }
        }
        .follow-btn{
            color: $white_color;
            font-weight: 600;
            padding: 8px 20px;
            display: block;
            border-radius: 20px;
            font-size: 16px;
            text-align: center;
            margin-bottom: 20px;
            background: $green_color;
            transition: all 0.4s ease-in-out;
            cursor: pointer;
            border: 1px solid $green_color;
            &:hover{
                background: $blue_color;
                border-color: $blue_color;
            }
            @media(max-width:991px){
                max-width: 300px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
            i{
                font-weight: 900;
            }
        }
        .follow-btn-like{
            background: #f5f8ff;
            border-color: #dee8ff;
            color: $blue_color;
            &:hover{
                background:#b7cdff;
            }
        }
        .btn_block{
            display: flex;
            width: 100%;
            align-items: center;
            @media(min-width:641px) and (max-width:991px){
               display: flex;
               margin-top: 20px;
             }
             @media(max-width:480px){
                 display: block;
             }
             .second-btn{
                padding: 13px 11px !important;
                @media(max-width:480px){
                   margin-top: 20px;
                }
             }
            .primary-btn,.second-btn{
                width: 100%;
                text-align: center;
            }
            .review_block,.second-btn{
                @media(min-width:641px) and (max-width:991px){
                    width: 50%;
                    flex: 1;
                 }
            }
            .primary-btn{
                padding: 14px 27px !important;
                @media(max-width:400px){
                    padding: 14px 15px !important; 
                }
                i{
                    display: none;
                }
            }
            .review_block{
                margin-bottom: 0;
                width: 100%;
                margin-right: 5px;
                display: inline-block;
                @media(min-width:641px) and (max-width:991px){
                    margin-right: 20px;
                    margin-bottom: 0;
                 }
                 @media(max-width:480px){
                  margin-right: 0;
                }
            }
        }
        .table-content-section{
            padding: 15px 0;
            .table {
                tbody{
                    tr{
                        td{
                            font-size: 16px;
                            color: $grey_color;
                            font-weight: 300;
                            padding: 5px 5px;
                            @media(max-width:1366px){
                                padding: 4px 12px;
                                font-size: 15px;
                            }
                            &:last-child{
                                text-align: right;
                                word-break: break-word;
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
        .tags-wrap{
            padding: 30px 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #E4E4E4;
            border-top: 1px solid #E4E4E4;
            @media(max-width:1366px){
                padding: 16px 0;
                padding-bottom: 16px;
             }
            @media(max-width:991px){
                padding: 15px 0;
             }
            .tag-listing{
                display: inline-block;
                padding-left: 0;
                margin-bottom: 0;
                vertical-align: top;
                li{
                 font-size: 14px;
                 color: $grey_color;
                 margin-bottom: 15px;
                 vertical-align: top;
                 font-weight: 400;
                 border-radius: 15px;
                 background-color: #ffffff;
                 border: 1px solid #a4a4a4;
                 padding: 4px 20px;
                 margin-right: 15px;
                 transition: all 0.3s ease-in-out;
                 &:last-child{
                     margin-right: 0;
                 }
                 &:hover{
                     border-color: $green_color;
                     color: $green_color;
                 }
                }
                li.active{
                    border-color: $green_color;
                    color: $green_color;
                }
            }
        }
        .about-section{
            padding: 15px 0;
            padding-bottom: 10px;
            .description-text-sm{
                text-align: justify;
            }
            @media(max-width:991px){
                padding: 15px 0;
             }
            .description-text-sm{
                .read-more-less--more,.read-more-less--less{
                    font-weight: 500;
                    color: $green_color;
                    &:hover{
                     color: $blue_color;
                    }
                }
            }
        }
        .sub-headings{
            font-size: 20px;
            color: $grey_color;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .awards-section{
            padding: 25px 0;
            border-bottom: 1px solid #E4E4E4;
            border-top: 1px solid #E4E4E4;
            .award_list{
                padding-left: 0;
                margin-bottom: 0;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                li{
									margin-bottom: 10px;
									max-width:33%;
									list-style: none;
									width: 100%;
									padding: 0 10px;
									padding-top: 10px;
									display: flex;
									align-items: center;
									justify-content: center;
									@media(max-width: 991px){
											max-width: 15%;
									}
									@media(max-width: 640px){
											max-width: 25%;
									}
									@media(max-width: 480px){
											max-width: 50%;
									}
									span, img {
										position: static !important;
										width: 100% !important;
										height: 100% !important;
									}
                }
            }
        }
        .social-links{
            padding-top: 20px;
            .tag-text{
                font-size: 16px;
                color: $grey_color;
                font-weight: 300;
                margin-bottom: 0;
                display: block;
                vertical-align: top;
            }
            .links-listing{
                display: inline-block;
                vertical-align: top;
                li{
                    margin-right: 0;
                    padding-right:30px;
                    margin-bottom: 15px;
                    &:last-child{
                        padding-right: 0;
                    }
                    a{
                        background:  #ebf7ff;
                        width: 53px;
                        height: 53px;
                        display: flex;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.4s ease-in-out;
                        &:hover{
                            background: $green_color !important;
                        }
                        i{
                            color: $white_color;
                        }
                        // .fa-facebook-f{
                        //     color:  #3b5998;
                        // }
                        // .fa-twitter{
                        //     color:  #55acee;
                        // }
                        // .fa-instagram{
                        //     color:  #3b5998;
                        // }
                        // .fa-linkedin-in{
                        //     color:   #007ab9;
                        // }
                    }
                    a.facebook{
                        background: #3b5998;
                    }
                    a.twitter{
                        background: #55acee;
                    }
                    a.insta{
                        background: #d6249f;
                        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
                    }
                    a.linkedin{
                        background: #007ab9;
                    }
                    i{
                        cursor: pointer;
                        font-size: 18px;
                        color: $grey_color;
                        transition: all 0.4s ease-in-out;
                        // &:hover{
                        //     color: $green_color;
                        // }
                    }
                }
            }
        }
        .p_img_wrap{
            background: #fff;
            display: block;
            vertical-align: middle;
            margin: auto;
            max-width: 100%;
            overflow: hidden;
            width: 113px;
               span{
                display: block;
                height: 0;
                overflow: hidden;
                padding-bottom: 100%!important;
                position: relative;
                z-index: 0;
                    img{
                        bottom: 0;
                        display: block;
                        height: auto;
                        left: 0;
                        margin: auto;
                        max-width: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 100%;
                        border-radius: 0;
                        border: 0;
                    }
               }
           }         
    }
    .follow-mb-show{
        display: none;
        @media(max-width:991px){
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 20px;
            padding-top: 10px;
            .bookmark-btn{
                margin-left: 20px;
                i{
                    color: $blue_color;
                }
            }
            .primary-btn{
                font-weight: 600;
                i{
                    font-weight: 600;
                }
            }
        }
    }
    .right_tab_wrapper{
        width: calc(100% - 420px);
        display: inline-block;
        vertical-align: top;
        padding-left: 60px;
        @media(max-width:1366px){
            width: calc(100% - 350px);
            padding-left: 32px;
        }
        @media(max-width:991px){
            width: 100%;
            padding-left: 0;
        }
        .tab_nav_wrap{
            .nav-pills{
                position: relative;
                align-items: center;
            }
            .nav-item{
                @media(max-width:991px){
                    width: 100%;
                    display: block;
                }
                a{
                    color: $grey_color;
                    font-weight: 400;
                    border-radius: 0;
                    padding: 28px 30px;
                    transition: all 0.3s ease-in-out;
                    @media(max-width:1440px){
                       padding-left: 20px;
                       padding-right: 20px;
                    }
                    @media(max-width:1366px){
                      padding: 21px 20px;
                     }
                    @media(max-width:1024px){
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    @media(max-width:991px){
                        padding: 15px 30px;
                        background: #eaf7ff;
                        border-bottom: 1px solid $grey_color;
                    }
                    &:hover{
                        background: $blue_color;
                        color: $white_color;
                    }
                    h5{
                        margin-bottom: 0;
                        font-size: 20px;
                        @media(max-width:1366px){
                           font-size: 18px;
                        }
                    }
                }
                a.active{
                    background: $blue_color;
                    color: $white_color;
                }
            }
            .follow-d-show{
                display: flex;
                align-items: center;
                margin-left: auto;
                @media(max-width:991px){
                    display: none;
                }
                .primary-btn{
                    margin-left: auto;
                    width: 160px;
                    padding: 13px 15px !important;
                    @media(max-width:1366px){
                        width: auto;
                    }
                    i{
                        font-weight: 600;
                    }
                }
                .bookmark-btn{
                    // position: absolute;
                    // top: 50%;
                    // transform: translate(0, -50%); 
                    // right: 0;
                    margin-left: 20px;
                    i{
                       color: $blue_color;
                    }
             }
            }         
            .tab-content{
                margin-top: 60px;
                .col-xl-4{
                    padding: 0 15px;
                }        
                .tab_agency_review{
                    border: 1px solid #ccc;
                    padding: 20px;
                    border-radius: 5px;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .left_block{
                        width: 150px;
                        display: inline-block;
                        vertical-align: top;
                        .description-text-sm{
                            font-size: 14px;
                            line-height: 1.5;
                        }
                        @media(max-width:767px){
                            display: block;
                            margin: auto;
                            margin-bottom: 20px;
                        }
                        .user_name{
                            text-align: center;
                            font-weight: 600;
                            text-transform: capitalize;
                            margin-top: 10px;
                            margin-bottom: 0;
                            color: $blue_color;
                            font-size: 20px;
                        }
                        .icon_wrap{
                            border-radius: 50%;
                            border: 1px solid #ccc;
                            background: #eaf7ff;
                            width: 100px;
                            height: 100px;
                            margin: auto;
                            text-align: center;  
														span {
															position: static !important;
															width: 100% !important;
															height: 100% !important;
														}
                            img{
                              border-radius: 50%;
															position: static !important;
                            }             
                            i{
                                width: 100px;
                                height: 100px;
                                padding:30px 20px;
                                font-size: 30px;
                            }
                        }
                    }
                    .right_block{
                        width: calc(100% - 150px);
                        display: inline-block;
                        vertical-align: top;
                        padding-left: 20px;
                        @media(max-width:767px){
                            display: block;
                            margin: auto;
                            width: 100%;
                            padding-left: 0;
                        }
                        .review_title{
                            font-size: 20px;
                            color: $blue_color;
                            font-weight: 600;
                            position: relative;
                            text-transform: capitalize;
                            padding-right: 55px;
                            .rating_stars{
                                padding-left: 10px;
                                margin-bottom: 0;
                                vertical-align: top;
                                display: inline-block;
                                li{
                                    list-style: none;
                                    vertical-align: middle;
                                    display: inline-block;
                                    font-size: 15px;
                                    color: #ffba00;
                                }
                            }
                            .description-text-sm{
                               position: absolute;
                               top: 0;
                               right: 0;
                               font-size: 20px;
                               color: $blue_color;
                               font-weight: 500;
                            }
                        }
                        .description-text-sm{
                            b{
                                font-weight: 600;
                            }
                        }
                        .pics_listing{
                            margin-top: 20px;
                            padding-left: 0;
                            margin-bottom: 0;
                            li{
                                background: #fff;
                                display: inline-block;
                                vertical-align: middle;
                                margin: auto;
                                max-width: 100%;
                                overflow: hidden;
                                width: 113px;
                                margin-right: 10px;
                                span{
                                    display: block;
                                    height: 0;
                                    overflow: hidden;
                                    padding-bottom: 100% !important;
                                    position: relative;
                                    z-index: 0;
                                    img{
                                        bottom: 0;
                                        display: block;
                                        height: auto;
                                        left: 0;
                                        margin: auto;
                                        max-width: 100%;
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        width: 100%;
                                        border-radius: 0;
                                        border: 0;
                                    }
                                }
                            }
                        }
                        .cost-service{
                            span{
                                font-family: $secondary-font;
                            }
                        }
                    }
                }
            }
        }
        .agency_overview_wrapper{
            .custom_col{
                @media(min-width:991px) and (max-width:1199px){
                    width: 100%;
                }
                &:first-child{
                    .content_wrap{
                       min-height: calc(100% - 30px);
                    }
                   }
            }
            .content_wrap{
                border-radius: 5px;
                box-shadow: -6.928px 1px 19px 1px  rgba(0, 0, 0  ,.10);
                padding: 30px;
                margin-bottom: 30px;
                .block_heading{
                    font-size: 30px;
                    color: $grey_color;                  
                    font-weight: 800;
                    margin-bottom: 20px;
                    font-family: $secondary-font;
                }
                .description-text-sm{
                    text-align: justify;
                }
                .rating-count_block{
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    .count{
                        font-size: 20px;
                        color: #3c3c3c;
                        font-weight: 400;
                        margin-right: 15px;
                        display: inline-block;
                    }
                }
                .progress_rating{
                    padding-left: 0;
                    margin-bottom: 0;
                    li{
                        list-style: none;
                        label{
                            width: 105px;
                            display: inline-block;
                            vertical-align: top;
                            font-size: 16px;
                            color: #3c3c3c;
                            font-weight: 300;
                        }
                        .progress-wrap{
                            width: calc(100% - 110px);
                            display: inline-block;
                            vertical-align: top;
                            padding-left: 20px;
                            .progress{
                                border-radius: 20px;
                                .progress-bar{
                                   background-color: $green_color;
                                   border-radius: 20px;
                                }
                            }
                        }
                    }
                }
                .service_wrapper{
                    padding-left: 0;
                    margin-bottom: 0;
                    flex-wrap: wrap;
                    display: flex;
                    li{
                        list-style: none;
                        flex: 0 0 auto;
                        width: 50%;
                        margin-bottom: 30px;
                        @media(max-width:991px){
                            width: 100%;
                        }
                        .icon-block{
                            display: inline-block;
                            width: 45px;
                            vertical-align: middle;
                        }
                        span{
                            background: $green_color;
                            border-radius: 50%;
                            padding: 5px;
                            width: 50px;
                            height: 50px;
                            justify-content: center;
                            display: inline-block;
                            align-items: center;
                            text-align: center;
                            padding: 15px;
                            i{
                                color: $white_color;
                                font-size: 20px;
                                font-weight: 900;
                            }
                        }
                        .service_name{
                            width: calc(100% - 65px);
                            vertical-align: middle;
                            display: inline-block;
                            padding-left: 15px;                      
                            p{
                                margin-bottom: 0;
                                font-size: 18px;
                                color: $grey_color;
                                font-weight: 400;
                                @media(max-width:1500px){
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
                .map_view_wrap{
                    .map-block{
                        height: 400px !important;
                        width: 100% !important;
                        position: relative !important;
                    }
                    .address{
                        display: flex;
                        align-items: center;
                        padding-top: 30px;
                        @media(max-width:640px){
                            display: block;
                            text-align: center;
                        }
                        p{
                            font-size: 20px;
                            color: $grey_color;
                            font-weight: 400;
                            margin-bottom: 0;
                            padding-left: 15px;
                            @media(max-width:1199px){
                                font-size: 18px;
                            }
                        }
                        .primary-btn{
                            margin-left: auto;
                            max-width: 232px;
                            width: 100%;
                            @media(max-width:640px){
                               max-width: 100%;
                               margin-top: 10px;
                            }
                        }
                    }
                }
            }
            .sub-cat-overview{
                height: 400px;
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                }
            
                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                    border-radius: 5px;
                }
            
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $blue_color;
                    border-radius: 5px;
                }
            
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: $blue_color;
                }
            }
        }
    }
}
.awards-tooltip{
    .tooltip.show{
        opacity: 1;
    }
    .tooltip-inner{
        // background-color: $green_color !important;
        color: $white_color !important;
        font-size: 16px;
        padding: 8px 15px;
        min-width: 80px;
        width: 100%;
        text-align: center;
        box-shadow: 0px 0px 8px 0px rgba(0, 0 ,0 ,.36);
    }
    .arrow{
        &::before{
            // border-top-color: $green_color !important;
        }
    }
}
.modal-backdrop.show {
    opacity: 0.9;
}
.portfolio-blocks-grid{
    margin-bottom: 30px;
    .img_portfolio{
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        height: 235px;
        cursor: pointer;
        border: 1px solid #ccc;
        &:hover{
            .over-layer{
                bottom: 0;
                &::before{
                    opacity: 1;
                    height: 90%;
                }
            }
        }
        img,video{
            border-radius: 5px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .over-layer{
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: -100%;
            left: 0;
            background: rgba(0, 0, 0, 0.42);
            transition: all 0.4s ease 0s;
            &::before{
                content: "";
                width: 90%;
                height: 0;
                position: absolute;
                top: 5%;
                left: 5%;
                border-top: 5px solid $white_color;
                border-left: 5px solid $white_color;
                opacity: 0;
                transition: all 0.4s ease 0.4s;
            }
        }
    }
    .des_wrap{
        display: flex;
        align-items: flex-start;
        padding: 10px 0;
        .name{
            font-size: 16px;
            font-weight: 600;
            color: $black_color;
            margin-bottom: 0;
            width: calc(100% - 100px);
        }
        .likes_block{
            margin-left: auto;
            width: 100px;
            text-align: right;
            span{
                font-size: 14px;
                font-weight:500;
                margin-left: 10px;
                i{
                    font-weight: 900;
                    color: #959595;
                }
            }
        }
    }
}     
.portfolio_lightbox{
    .modal-dialog{
        max-width: 1500px;
        width: 100%;
        top: 0;
        height: 100%;
        background: transparent;
        box-shadow: none;
        transform: none !important;
        .modal-content{
            padding: 0 10px;
            background: transparent;
            .modal-header{
                background: transparent;
                display: block;
                position: relative;
                padding: 20px 65px;
                @media(max-width:640px){   
                    padding: 12px 20px;
                }
                .profile_section{
                    display: flex;
                    align-items: center;
                    .img-block{
                        display: block;
                        vertical-align: middle;
                        max-width: 100%;
                        overflow: hidden;
                        width: 64px;
                        height: 64px;                                             
                        border-radius: 50%;
                        margin: auto;
                        background: $white_color;
                        span{
                            display: block;
                            height: 0;
                            overflow: hidden;
                            padding-bottom: 100% !important;
                            position: relative;
                            z-index: 0;
                            background: $white_color;
                            // border: 3px solid $white_color;
														span {
															display: block !important;
															height: 100% !important;
														}
                            img{
                                width: 100%;
                                border-radius: 50%;
                                background: $white_color;
                                height: 100%;
                                border: 3px solid $white_color;
                                bottom: 0;
                                display: block;
                                height: auto;
                                left: 0;
                                margin: auto;
                                max-width: 100%;
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 100%;
                                border-radius: 0;
                                border: 0;
                            }
                        }
                    }
                    .content-block{
                        width: calc(100% - 70px);
                        padding-left: 20px;
                        .agency_name{
                            font-size: 16px;
                            color: $white_color;
                            font-weight: 700;
                            margin-bottom: 0;
                            text-transform: capitalize;
                        }
                        .follow_wrap{
                            .dropdown{
                                .dropdown-toggle{
                                    background: transparent;
                                    border: 0;
                                    padding: 0;
                                    font-size: 14px;
                                    color: $white_color;
                                    font-weight: 500;
                                    text-transform: capitalize;
                                    text-align: left;
                                    white-space: initial;
                                    &:focus{
                                        outline: 0;
                                        box-shadow: none;
                                    }
                                    @media(max-width:480px){
                                        display: block;
                                    }
																		a {
																			color: inherit;
																		}
                                }
                                .dropdown-menu{
                                    padding: 0;
                                    min-width: 370px;
                                    width: 100%;
                                    border-radius: 5px;
                                    @media(max-width:640px){
                                        min-width: 320px
                                    }
                                    .dropdown-item{
                                        padding: 5px;
                                        background: #fefefe;
                                        box-shadow: 0 8px 16px 2px rgba(0 ,0 ,0 ,0.25);
                                        &:hover,&:active,&:focus{
                                            background-color: $white_color !important;
                                            outline: 0;
                                            color: initial;
                                        }                                 
                                    }
                                }
                            }
                            .follow_link{
                                padding-left: 30px;
                                font-size: 14px;
                                color: $white_color;
                                font-weight: 500;
                                display: inline-block;
                                cursor: pointer;
                                &:hover{
                                    color: $green_color;
                                }
                                @media(max-width:480px){
                                    padding-left: 0;
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .btn-close{
                    position: fixed;
                    right: 8px;
                    top: 9px;
                    width: 32px;
                    z-index: 1;
                    &::before,&::after{
                        height: 14px;
                    }
                }
            }
            .modal-body{
                display: flex;
                max-height: initial;
                overflow: visible;
                // min-height: 600px;
                padding: 0;
                @media(max-width:640px){
                    padding: 16px 0;
                }
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                }
            
                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px transparent;
                    border-radius: 5px;
                }
            
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: transparent;
                    border-radius: 5px;
                }
            
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: transparent;
                }
                .first-col{
                    width: 70px;
                    position: relative;
                }
                .second-col{
                    width: calc(100% - 140px);
                    padding: 0px;
                    background: $white_color;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    min-height: 89vh;
                    padding: 0 15px;
                    padding-top: 10px;
                }
                .portfolios-imgs{
                    margin-bottom: 60px;
                    width: 100%;
										span {
											position: static !important;
											width: 100% !important;
											height: 100% !important;
										}
                    img{
											position: static !important;
											margin: auto;
											display: block;
											width: auto !important;
											height: auto !important;
											min-width: auto !important;
											min-height: auto !important;
											max-width: 100% !important;
											max-height: 100% !important;
                    }
                }
                .right-icon-wrap{
                    position: fixed;
                    top: 105px;
                    padding: 0 15px;
                    ul{
                        padding-left: 0;
                        margin-bottom: 0;
                        li{
                            list-style: none;
                            margin-bottom: 20px;
                            text-align: center;
                            // &:nth-child(2){
                            //     a{
                            //         .icon-wrap{
                            //             background: $white_color;
                            //             i{
                            //                 color: $black-color;
                            //             }
                            //         }
                            //     }
                            // }
                            a{
                                display: block;
                                .icon-wrap{
                                    background: $white_color;
                                    width: 42px;
                                    height: 42px;
                                    border-radius: 50%;
                                    margin: auto;
                                    align-items: center;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    i{
                                        color: $blue_color;
                                        font-size: 18px;
                                    }
                                }
                                p{
                                    margin-bottom: 0;
                                    font-size: 12px;
                                    color: $white_color;
                                    font-weight: 600;
                                    margin-top: 10px;
                                }
                            }
                            a.liked{
                                .icon-wrap{
                                    background: $blue_color;
                                    i{
                                        color:$white_color;
                                    }
                                }
                            }
                        }
                    }
                }
                .next-prev-wrap{
                   height: 100%;
                    .nav-btn{
                        background: transparent;
                        border: 0;
                        box-shadow: none;
                        opacity: 1;          
                        z-index: 1;     
                        position: fixed;
                        bottom: 0;
                        &:disabled{
                            opacity: .7;
                        }       
                        i{
                            background: $green_color;
                            color: $white_color;
                            font-weight: 500;
                            width: 42px;
                            height: 42px;
                            text-align: center;
                            border-radius: 50%;
                            padding: 6px;
                            font-size: 30px;
                            transition: all 0.4s ease-in-out;
                            &:hover{
                                background: $blue_color;
                            }
                        }
                        span{
                            display: block;
                            font-size: 10px;
                            color: $white_color;
                            font-weight: 600;
                            margin-top: 10px;
                        }
                    }
                    .nav-btn{
                        &:disabled{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.agency_popup_detail{
    text-align: center;
    border: solid 1px #e8e8e8;
    border-radius: 5px;
    height: 100%;
    position: relative;
    .ribbon {
        width: 134px;
        height: 134px;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        &::before,&::after{
            position: absolute;
            z-index: -1;
            content: '';
            display: block;
            border: 5px solid red;
        }
        span {
            position: absolute;
            display: block;
            width: 214px;
            padding: 10px 0;
            background-color: red;
            box-shadow: 0 5px 10px rgba(0,0,0,.1);
            color: #fff;
            font-weight: 600;
            text-shadow: 0 1px 1px rgba(0,0,0,.2);
            text-transform: uppercase;
            text-align: center;
          }
      }
      /* top left*/
      .ribbon-top-left {
        top: -10px;
        left: -10px;
        &::before,&::after{
            border-top-color: transparent;
            border-left-color: transparent;
        }
        &::before{
            top: 0;
            right: 0;
        }
        &::after{
            bottom: 0;
            left: 0;
        }
        span {
            right: -25px;
            top: 30px;
            transform: rotate(-45deg);
          }
      }  
    .cover-img-wrap{
        height: 90px;
				span {
					position: static !important;
					width: 100% !important;
					height: 100% !important;
				}
        img{
					position: static !important;
					height: 100% !important;
					width: 100% !important;
					object-position: top;
					object-fit: cover;
        }
    }
    .detail-wrap{
        margin-top: -35px;
        margin-bottom: 20px;
        padding: 0 20px;
        .logo-img{
            display: block;
            vertical-align: middle;
            max-width: 100%;
            overflow: hidden;
            width: 75px;
            height: 75px;                                             
            border-radius: 50%;
            margin: auto;
            background: $white_color;
            box-shadow: 0 0 15px 0px #000000c9;
            span{
                display: block;
                // height: 0;
                overflow: hidden;
                position: relative;
								// padding-bottom: 100% !important;
                z-index: 0;
                background: $white_color;
                // border: 3px solid $white_color;
								span {
									position: static !important;
									height: auto !important;
									width: auto !important;
								}
                img{
                    width: 100% !important;
                    border-radius: 50%;
                    background: $white_color;
                    height: 100% !important;
                    border: 3px solid $white_color;
                    bottom: 0;
                    display: block;
                    height: auto;
                    left: 0;
                    margin: auto;
                    max-width: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    border-radius: 0;
                    border: 0;
										position: static !important;
										width: 75px !important;
										height: 75px !important;
										object-fit: contain;
                }
            }
        }
        .name{
            font-size: 20px;
            margin: 20px 0;
            margin-bottom: 10px;
            font-weight: 600;
            // text-decoration: underline;
            text-transform: capitalize;
            white-space: initial;
            word-break: break-word;
						a {
							color: inherit;
							text-decoration: none !important;
						}
        }
        .address{
            font-size: 16px;
            color: #707070;
            white-space: initial;
            font-weight: 400;
            min-height: 48px;
            @media(max-width:576px){
                min-height: auto;
            }
        }
        .breadcrumb-list{
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            li{

               border-left: 2px solid #e8e8e8;
               list-style: none;
               padding: 6px 14px;
               &:first-child{
                   border: 0;
               }
               span{
                   font-weight: 600;
                   font-size: 18px;
               }
               p{
                   margin-bottom: 0;
                   font-size: 14px;
                   color: #707070;
                   font-weight: 500;
               }
            }
        }
        .follow-btn{
            width: 100%;
            display: block;
            color: $white_color;
            font-weight: 600;
            background: $blue_color;
            border-radius: 20px;
            padding: 4px 15px;
            font-size: 16px;
            transition: all 0.4s ease-in-out;
            cursor: pointer;
            border: 1px solid $blue_color;
            &:hover{
                background: $green_color;
                border-color: $green_color;
            }
            i{
                font-weight: 900;
                padding-right: 3px;
            }
        }
        .follow-btn-like{
            background: #f5f8ff;
            border-color: #dee8ff;
            color: $blue_color;
            margin-top: 10px;
            &:hover{
                background:#b7cdff;
                border-color: $blue_color;
            }
        }
    }
}
// agency detail page code ends here



// follow section design code starts here


.followers-scetion{
    .follow-open-count{
        color: $green_color;
        margin-right: 0;
        font-weight: 500;
        text-decoration: underline;
    }
}

.followers-modal{
  .modal-dialog{
      max-width: 1180px;
      width: 100%;
      top: 135px;
      .modal-content{
          .modal-header{
              padding: 0;
              padding-right: 20px;
              background:#0d6efd12;
              border-bottom: 1px solid #ccc;
              .nav-pills{
                  .nav-item{
                      .nav-link {
                          padding: 20px 30px;
                          border-radius: 0;
                          transition: all 0.4s ease-in-out;
                          border-top-left-radius: 5px;
                          border-top-right-radius: 5px;
                          font-size: 18px;
                          font-weight: 600;
                          color: $blue_color;
                      }
                      .nav-link.active{
                        margin-top: -20px;
                        padding: 30px;
                        color: $white_color;
                      }
                  }
              }
              .btn-close{
                  &::after,&::before{
                    background: $blue_color;
                  }          
              }
          }
          .modal-body{
            max-height: 700px;
            padding: 30px;
            @media(max-width:767px){
               padding: 20px;
            }
          }
      }
  }
}
.followers-details{
    display: flex;
    align-items: center;
    padding: 20px 0;
    &:not(:first-child){
        border-top: 1px solid #e8e8e8;
    }
    @media(max-width:767px){
        display: block;
    }
    .profile_detail_wrap{
        display: flex;
        width: 400px;
        @media(max-width:767px){
          margin-bottom: 20px;
          width: 100%;
        }
        .img-block{
            background: #fff;
            border-radius: 50%;
            display: block;
            margin:0 auto;
            max-width: 100%;
            overflow: hidden;
            width: 92px;
            height: 92px;
            border: 1px solid #ccc;
          @media(max-width:767px){
           width: 70px;
           height: 70px;
          }
          span{
            display: block !important;
            height: 0 !important;
            overflow: hidden !important;
            padding-bottom: 100% !important;
            position: relative !important;
            z-index: 0 !important;
            background: $white_color !important;
            border-radius: 50% !important;
            img{
                bottom: 0 !important;
                display: block !important;
                height: auto !important;
                left: 0 !important;
                margin: auto !important;
                max-width: 100% !important;
                position: absolute !important;
                right: 0 !important;
                top: 0 !important;
                width: 100% !important;         
            }
          }
        }
        .description-wrap{
            padding-left: 20px;
            width: calc(100% - 92px);
            @media(max-width:767px){
                width: calc(100% - 70px);
            }
            .name{
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 10px;
                text-transform: capitalize;
                @media(max-width:767px){
                 font-size: 18px;
                }
            }
            .address{
                margin-bottom: 0;
                color: $grey_color;
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 5px;
            }
            .follow-block{
                margin-top: 10px;
                .follow-btn{
                    background: $blue_color;
                    color: $white_color;
                    padding: 6px 20px;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 20px;
                    margin-right: 10px;
                    transition: all 0.4s ease-in-out;
                    cursor: pointer;
                    &:hover{
                        background: $green_color;
                    }
                }
                span{
                    margin-left: 10px;
                    font-weight: 600;
                }
            }
        }
    }
    .project-wrap{
        width: calc(100% - 400px);
        padding-left: 15px;
        @media(max-width:767px){
           width: 100%;
           padding-left: 0;
           }
        @media(max-width:640px){
           display: none;
          }
        ul{
            padding-left: 0;
            margin-bottom: 0;
            flex-wrap: wrap;
            margin-left: auto;
            max-height: 90px;
            overflow: hidden;
            display: flex;
            align-items: center;
            @media(max-width:767px){
                flex-wrap: initial;
              }
            li{
                list-style: none;
                width: 115px;
                height: 90px;
                &:not(:first-child){
                    margin-left: 10px;
                }
                img, span {
                    height: 100% !important;
                    width: 100% !important;
                    object-fit: cover;
                }
            }
        }
        .portfolio-blocks-grid{
            .img_portfolio{
                height: 90px;
            }
        }
    }
}
// follow section design code ends here