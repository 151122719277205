// Reviews design code starts here

.review-list-dashboard{
    border: 1px solid #ccc;
    padding: 20px;
    padding-top: 0;
    margin-bottom: 30px;
    border-radius: 5px;
    .main_heading{
        background:#494949;
        margin: 0 -20px;
        padding: 10px 20px;
        margin-bottom: 30px;
        h2{
            color: $white-color;
            margin-bottom: 0;
            font-size: 30px;
        }
    }
    .admin_lead_listing{
        margin-bottom: 20px;
    }
    .review-view{
        .review-data{
            display: flex;
            align-items: top;
            padding: 20px 0;
            border-bottom: 1px solid #e7e7e7;
            &:last-child{
                border: 0;
            }
            img{
                height: 50px;
                width: 50px;
                border-radius: 50%;
            }
            .default-img-wrap{
                height: 50px;
                width: 50px;
                border: 1px solid #e7e7e7;
                border-radius: 50%;
                padding: 12px 10px;
                text-align: center;
                i{
                    font-size: 20px;
                }
            }
            .left_col-view{
                padding-left: 15px;
                h6{
                    font-size: 14px;
                    color: #303030;
                    margin-bottom: 5px;
                    font-weight: 600;
                }
                p{
                    font-size: 14px;
                    color: #303030;
                    margin: 0;
                }
            }
            .stars-wrap{
                text-align: right;
                margin-left: auto;
                margin-top: 5px;
                width: 122px;
            }
        }
    }
}



// Reviews design code ends here