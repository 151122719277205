@import "variables";

.terms-section-set {
  .content-wrap-set {
    padding-bottom: 30px;
    p {
      color: $grey_color;
      margin-bottom: 10px;
      line-height: 30px;
      font-weight: 400;
      font-size: 16px;
    }
    h3 {
      margin-bottom: 15px;
    }
    h4 {
      margin-bottom: 15px;
      color: $grey_color;
      font-weight: 600;
      font-size: 22px;

    }
  }
  .in-wrap{
    padding-left: 30px;
    h4{
      font-size: 20px;
    }
}
  .main-top {
    h2 {
      margin-bottom: 15px;
    }
      margin-bottom: 30px;
    p {
      color: $grey_color;
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;
      margin-bottom: 0;
      max-width: 900px;
      margin: auto;
      padding-bottom: 20px;
    }
  }ul{
      li{
        color: $grey_color;
        margin-bottom: 0;
        line-height: 30px;
        font-weight: 300;
        font-size: 16px;
      }
  }
}
.padding-set{
   
    padding:40px 10px 80px 10px;
    @media(max-width:600px){
        padding-top: 0px;
    }
  }
