.input_text{
    border: 1px solid #A4A4A4;
    color: $black_color;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 14px 15px;
    background-color: $white_color !important;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    &:focus,&:hover{
        box-shadow: none !important;
        background-color: $white_color !important;
        border-color: $green_color !important;
        color: $black_color !important;
    }
    &::placeholder{
        color: #969696;
    }
}
.dropdown_field{
    .input_text{
        text-align: left;
        position: relative;
        &:after{
            right: 10px;
            position: absolute;
            top: 22px;
            font-size: 15px;
        }
    }
    .dropdown-menu{
        width: 100%;
    }
}
label,.label{
    margin-bottom: 10px;
    display: block;
    color: $black_color;
}
.login-page{
    min-height: 100vh;
    background-image: url('../../../public/admin/login-bg.jpg');
    background-size: cover;
    background-position: right;
    padding-bottom: 85px;
    position: relative;
    @media(max-width:480px){
        background-position: 80% 15%;    
    }
    .form_Section{
        max-width: 465px;
        width: 100%;
        background:$white-color;
        padding: 35px;
        a{
            &:hover{
                color: $green_color !important;
            }
        }
        @media (max-width:480px) {
            background:#ffffffdb;
        }
        .login-title{
            color: $grey_color;
            font-weight: bold;
            margin-bottom: 30px;
            font-family: $secondary-font;
        }
        .primary-btn{
            width: 100%;
        }
        .already_text{
            font-size: 14px;
            color: #3c3c3c;
            margin-top: 20px;
            font-weight: 400;
            margin-bottom: 0;
            text-align: center;
            a{
                color: #3c3c3c;
                display: inline-block;
                &:hover{
                    color: $green_color;
                }
            }
        }
    }
    .logo_section{
        padding: 30px;
        margin-bottom: 60px;
    }
}
.forget-page {
    .form_Section{
        @media(max-width:580px){
        max-width: 100% !important;
        }
    }
}