body {
    font-family: "Raleway",sans-serif;
}
.container-xl{
    @media(min-width: 1400px){
        max-width: 1550px;
    }
    @media(max-width:1200px){
        padding: 0px 20px;
    }
}
.user_dashboard_main .flash{
	color: white;
	border-radius: 5px;
	top: 0px;
	right: 0px;
	padding: 15px 20px;
   margin-bottom: 20px;
   margin-top: 0;
    position: relative;
    // position: fixed;
    width: 100%;
    // z-index: 99;
	span {
		color: white;
		cursor: pointer;
        position: absolute;
        top: 15px;
        right: 20px;
		margin-left: 10px;
		display: inline-block;
        vertical-align: top;
	}
	p {
	 	margin: 0px; 
	}
}

.flash-success {
	background: #9ee19e;
}

.flash-error {
	background: #ff9494;
}
.errorMsg {
	color: red;
    margin-top: 8px;
    font-family:$secondary-font;
}
.text-right{
    text-align: right;
}
.padding-wrap{
   padding: 120px 0;
   @media(max-width:1366px){
    padding: 60px 10px;
    }
   @media(max-width:767px){
       padding: 40px 10px;
   }
}
.top-spacing{
    padding-top: 120px;
    @media(max-width:1366px){
        padding-top: 60px;
        }
    @media(max-width:767px){
       padding-top: 40px;
    }
		.top-spacing-img {
			span {
				position: static !important;
				overflow: inherit !important;
			}
			img {
				position: static !important;
				width: auto !important;
				height: auto !important;
				min-height: auto !important;
				min-width: auto !important;
				margin: 0 !important;
			}
		}
 }
 .bottom-spacing_margin{
    margin-bottom: 120px;
    @media(max-width:1366px){
       margin-bottom: 60px;
        }
    @media(max-width:767px){
       margin-bottom: 40px;
    }
 }
 .table-responsive{
     overflow-y: auto;
     margin-bottom: 0;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $blue_color;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $blue_color;
    }
 }
.main-heading{
    color: $blue_color;
    font-family: $secondary-font;
    font-size: 40px;
    margin-bottom: 0;
    font-weight: 800;
    @media(max-width:1366px){
        font-size: 30px;
    }
}
.main-heading-lg{
    color: $blue_color;
    font-family: $secondary-font;
    font-size: 60px;
    margin-bottom: 0;
    font-weight: 800;
    @media(max-width:1366px){
        font-size: 40px;
    }
    @media(max-width:767px){
        font-size: 30px;
    }
}
.description-text{
    color: $grey_color;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    margin-bottom: 0;
    @media(max-width:1366px){
        font-size: 18px;
    }
    @media(max-width:767px){
        font-size: 16px;
    }
}
.description-text-sm{
    color: $grey_color;
    margin-bottom: 0;
    line-height: 30px;
    font-weight: 300;
    font-size: 16px;
}
.content-wrap {
    .description-text-sm{
        line-height: 1.5;
        @media(max-width:1366px){
            font-size: 14px;
         }
    }
}
.text-center{
    text-align: center;
}
.shadow-image{
    box-shadow: 0px 10px 16px 2px rgba(0,0,0,0.2);
    transition: all 0.4s ease-in-out;
    &:hover{
        box-shadow: 0px 10px 16px 2px rgba(0,77,192,0.2);
    }
}
img{
    max-width: 100%;
}
.primary-btn{
    text-transform: uppercase;
    font-size: 16px;
    background:$green_color;
    color: $white_color !important;
    // width: 100%;
    border: 0;
    padding: 13px 27px !important;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    @media(max-width:1366px){
        font-size: 14px;
    }
		&.btn {
			background: $green_color !important;
		}
    &::before{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 0%;
        border-radius: 5px;
        background-color: $blue_color;
    }
    &::after{
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 0%;
        background-color: $blue_color;
        -webkit-transition: width .5s;
        -o-transition: width .5s;
        border-radius: 5px;
        transition: width .5s;
    }
 span,i{
     position: relative;
     z-index: 1;
     display: inline-block;
 }
    &:hover{  
        background-color: $green_color;
            color:$white-color;
        &::before{
            -webkit-transition: width .5s;
            -o-transition: width .5s;
            transition: width .5s;
            width: 100%;
        }
        &::after{
            -webkit-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
            visibility: hidden;
            width: 100%;
        }
    }
    @media (hover: none) {
        &:hover{
            background-color: $blue_color;
            color:$white-color;
            &::before,&::after{
                display: none;
            }
        }
    }
    &:focus{
        outline: 0;
        box-shadow: none !important;
        background-color: $blue_color;
        color:$white-color !important;
    }
}

.second-btn{
    text-transform: uppercase;
    font-size: 16px;
    background:$white_color;
    color: $grey_color !important;
    // width: 100%;
    border:1px solid $green_color;
    padding: 13px 27px !important;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    &:hover{
        border: 1px solid transparent !important;
        background: transparent !important;
    }
    @media(max-width:1366px){
        font-size: 14px;
    }
    &::before{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 0%;
        border-radius: 5px;
        background-color: $blue_color;
    }
    &::after{
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 0%;
        background-color: $blue_color;
        -webkit-transition: width .5s;
        -o-transition: width .5s;
        border-radius: 5px;
        transition: width .5s;
    }
 span,i{
     position: relative;
     z-index: 1;
     display: inline-block;
 }
    &:hover{  
        background-color: $green_color;
            color:$white-color !important;
        &::before{
            -webkit-transition: width .5s;
            -o-transition: width .5s;
            transition: width .5s;
            width: 100%;
        }
        &::after{
            -webkit-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
            visibility: hidden;
            width: 100%;
        }
    }
    @media (hover: none) {
        &:hover{
            background-color: $blue_color;
            color:$white-color;
            &::before,&::after{
                display: none;
            }
        }
    }
    &:focus{
        outline: 0;
        box-shadow: none !important;
        background-color: $blue_color;
        color:$white-color !important;
    }
    &:disabled{
        background-color: $white_color;
        border:1px solid $green_color ;
    }
}
.loc_filter_wrap{
    .primary-btn.disabled{
        background-color: #00cc83 !important;
    }
}
.btn_block {
    .second-btn{
        &:hover{
            border: 1px solid transparent !important;
            background: transparent;
        }
    }
}

.reset_img {
	span {
		position: static !important;
		width: auto !important;
		height: auto !important;
		overflow: inherit !important;
		img {
			position: static !important;
			width: auto !important;
			height: auto !important;
			min-width: auto !important;
			min-height: auto !important;
			margin: 0 !important;
			max-width: 100% !important;
			max-height: 100% !important;
		}
	}
	&.img_right {
		img {
			margin-left: auto !important;
		}
	}
}

// public header section code starts here

.public__header{
    box-shadow: 0 0 16px 2px rgba(0,0,0,0.2);
    background: $white_color;
    .logo-section{
        width: 250px;
        padding:24px 0px;
        display: inline-block;
        vertical-align: top;
        @media(max-width:1366px){
            width: 200px;
        }
        @media(max-width:767px){
					width: 150px;
					padding: 18px 0px;
					img{
							max-width: 100%;
					}
        }
			span {
				display: block !important;
				position: static !important;
			}
			img {
				width: 100% !important;
				height: auto !important;
				min-width: auto !important;
				min-height: auto !important;
				position: static !important;
				margin: 0 !important;
				max-width: 203px !important;
				cursor: pointer;
			}
    }
    .menu-section{
        width: calc(100% - 250px);
        display: inline-block;
        vertical-align: top;
        text-align: right;
        @media(max-width:1366px){
            width: calc(100% - 200px);
        }
        @media(max-width:991px){
            padding: 26px 0px;
            padding-right: 36px;
        }
        @media(max-width:767px){
            width: calc(100% - 150px);
           padding-top: 10px;
           padding-bottom: 10px;
        }
        .navbar-nav{
            @media(max-width:991px){
                display: block;
                width: 100%;
                text-align: left;
            }
            li{
                padding-left:10px;
                padding-right: 10px;
                @media(max-width:991px){
                   padding: 0;
                }
            }
            .nav-link{
                padding: 37px 10px;
                color: #3C3C3C;
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width:0;
                    height: 5px;
                    transition: all 0.4s ease-in-out;
                    background: #ECBE7A;
                }
                &:hover{
                    &::before{
                        width: 100%;
                    }
                }
                @media(max-width:1366px){
                   font-size: 14px;
                }
                @media(max-width:1400px){
                    padding: 37px 0px;
                }
                @media(max-width:1199px){
                    font-size: 14px;
                }
                @media(max-width:991px){
                    font-size: 16px;
                    font-weight: 400;
                    padding: 12px 20px;
                    display: block;
                    color: $white_color;
                    transition: all 0.3s ease-in-out;
                    &::before{
                        background: $white_color;
                        height: 1px;
                        width:0;
                    }
                    &:hover{
                    //  background: $white_color;
                     color: $white_color; 
                     &::before{
                        background: #ECBE7A;
                     }
                    }
                }
            }
            .nav-link.active{
                &::before{
                    width: 100%;
                }
            }
        }
        .mb_toggle{
            display: none;
            vertical-align: middle;
            margin-right: 20px;
            @media(max-width:991px){
                display: inline-block;
            }
            i{
                font-size: 24px;
                font-weight: 700;
                color: $blue_color;
            }
        }
        .navbar-toggler{
            border: 0;
            box-shadow: none;
            padding: 0 5px;
        }
           .navbar-collapse{
               display: block !important;
               @media(max-width:991px){
                //    display: none !important;
                   min-height: 100%;
                   width: 300px;
                   height: 100%;
                   top: 0;
                   left: -300px;
                   position: fixed;
                   background: linear-gradient(320deg,#00CC83, #004dc0)!important;
                   overflow-y: auto;
                   z-index: 9999999;
                   padding-bottom: 20px;
                   padding-top: 55px;
                   box-shadow: 0 8px 16px 2px rgba(0, 0 ,0 ,0.1);
                   transition: all .3s ease-in-out;
               }
               .navbar-toggler{
                position: absolute;
                top: 0;
                right: 0;
                background: $green_color;
                color: $green_color;
                width: 32px;
                height: 34px;
                text-align: center;
                border-radius: 0 0 0 10px;
                   .navbar-toggler-icon{
                       display: none;
                   }
                   &::before,&::after{
                    position: absolute;
                    left: 17px;
                    content: ' ';
                    height: 16px;
                    width: 2px;
                    background-color: $white_color;
                    top: 8px;
                   }
                   &::before{
                    transform: rotate(45deg);
                   }
                   &::after{
                    transform: rotate(-45deg);
                   }
               }
           }
           .navbar-collapse.show{
            display: block !important; 
            left: 0;
           }
        .left_navbar{
            padding: 0;
            display: inline-block;
            @media(max-width:991px){
                position: absolute;
                right: 10px;
                top: 35px;
            }
            @media(max-width:767px){
               top: 20px;
            }
        }
        .right_navbar{
            display: inline-block;
            .nav-link{
                color: #3C3C3C;
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
                padding: 13px 43px;
                @media(max-width:1366px){
                   font-size: 14px;
                }
                @media(max-width:1400px){
                    padding: 13px 15px;
                }
                @media(max-width:991px){
                    padding: 13px 10px;
                }
                i{
                    font-weight: 500;
                }
            }
            .dropdown{
                .dropdown-toggle{
                    padding: 13px 43px;
                    background: transparent;
                    color: #3C3C3C;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: uppercase;
                    border: 0;
                    box-shadow: none;
                    @media(max-width:1400px){
                        padding: 13px 15px;
                    }
                    @media(max-width:1366px){
                        font-size: 14px;
                    }
                    @media(max-width:1199px){
                        &::after{
                            display: none;
                        }
                    }
                    @media(max-width:991px){
                        padding: 13px 10px;
                    }
                    i{
                        font-weight: 500;
                        display: none;
                        @media(max-width:1199px){
                            display: inline-block;
                        }
                    }
                    span{
                        @media(max-width:1199px){
                            display: none;
                        }
                    }
                }
                .dropdown-menu{
                   padding: 0;
                   .dropdown-item{
                       border-bottom: 1px solid #ccc;
                       padding: 15px;
                       position: relative;
                       color: $grey_color;
                       font-weight: 500;
                       &::after{
                           content: '';
                           position: absolute;
                           left: 0;
                           height: 2px;
                           width: 0;
                           background: #ECBE7A;
                           transition: all 0.4s ease-in-out;
                           bottom: 0;
                       }
                       &:hover{
                           background: transparent;
                           color: $blue_color;
                           &::after{
                               width: 100%;
                           }
                       }
                       &:last-child{
                           border: 0;
                       }
                   }
                }
            }
            .primary-btn{
                text-transform: capitalize;
            }
            .search-btn{
                border-right:1px solid#E4E4E4; 
								span {
									@media(max-width:991px) {
										display: none;
									}
								}
            }
            .signin-link{
                i{
                    display: none;
                    @media(max-width:1199px){
                        display: inline-block;
                    }
                }
                span{
                    @media(max-width:1199px){
                        display: none;
                    }
                }
            }
            .primary-btn{
                @media(max-width:1366px){
                    padding: 13px 20px !important;
                }
                @media(max-width:991px){
                   &::before,&::after{
                       display: none;
                   }
                   color: #3c3c3c !important;
                   background: transparent !important;
                   padding: 13px 8px !important;
                }
                span{
                    @media(max-width:991px){
                        display: none;
                    }
                }
                i{
                    display: none;
                    @media(max-width:991px){
                        display: inline-block;
                    }
                }
            }
        }
    }
}

// public header section code ends here

// public Footer section code starts here

.public__footer{
    background: $grey_color;
    .footer-p{
        color: $white_color;
        font-size: 16px;
        font-weight: 300;
        @media(max-width:1366px){
            font-size: 14px;
        }
    }
    .footer_menu_section{
        .menu_heading_f{
            color: $white_color;
            font-size: 20px;
            position: relative;
            margin-bottom: 16px;
            padding-bottom: 18px;
            @media(max-width:1366px){
                font-size: 18px;
            }
            &::before{
                background: $white_color;
                opacity: 0.5;
                height: 1px;
                width: 201px;
                content: '';
                left: 0;
                bottom: 0;
                position: absolute;
            }
        }
        .menu_f_listing{
            padding-left: 0;
            margin-bottom: 0;
            @media(max-width:991px){
                margin-bottom: 30px;
            }
            li{
                list-style: none;
                margin-bottom: 6px;
                a{
                    position: relative;
                    display: block;
                    padding-left: 16px;
                    color: $white_color;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 300;
                    transition: all 0.4s ease-in-out;
                    @media(max-width:1366px){
                        font-size: 14px;
                    }
                    &:hover{
                        color: $green_color;
                    }
                    &::before{
                        content: "\f0da";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 900;
                        left: 0;
                        top: 0;
                        color: $green_color;
                        position: absolute;
                    }
                }
            }
        }
        .newsletter_f_wrapper{
            max-width: 310px;
            position: relative;
            margin-bottom: 16px;
            .input_text{
                border: 1px solid #A4A4A4;
                color: #969696;
                font-size: 14px;
                padding: 14px 15px;
                margin-bottom: 0;
                background-color: $white_color !important;
                box-sizing: border-box;
                border-radius: 5px;
                width: 100%;
                &:focus,&:hover{
                    box-shadow: none !important;
                    background-color: $white_color !important;
                    border-color: $green_color !important;
                    color: $black_color !important;
                    outline: 0;
                }
                &::placeholder{
                    color: #969696;
                }
            }
            .primary-btn{
                padding: 15px 18px !important;
                position: absolute;
                font-size: 14px;
                right: 0;
                top: 0;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                &::before,&::after{
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0; 
                }
            }
        }
    }
    .footer_bottom_section{
        border-top: 1px solid #a0a0a0;
        .social-links{
            padding-left: 0;
            margin-bottom: 0;
            text-align: center;
            li{
                display: inline-block;
                list-style: none;
                margin: 0 8px;
                a{
                    color: $white_color;
                    display: inline-block;
                    text-align: center;
                    i{
                        width: 32px;
                        height: 32px;
                        padding: 8px;
                        border-radius: 50%;
                    }
                }
                .fa-facebook-f{
                    background: #3b5998;
                }
                .fa-twitter{
                    background: #55acee;
                }
                .fa-instagram{
                    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
                }
                .fa-pinterest-p{      
                    background: #cb2027;
                }
                .fa-linkedin-in{
                    background: #007ab9;
                }
            }
        }
        .block_bottom{
            display: inline-block;
            padding: 25px 0;
            vertical-align: middle;
            @media(max-width:767px){
                max-width: 100% !important;
                text-align: center;
                width: 100% !important;
            }
            p{
                margin-bottom: 0;
            }
        }
        .logo-f-block{
            padding-right: 20px;
            max-width:212px;
            width:100% ;
        }
        .social-f-block{
            border-left: 1px solid #a0a0a0;
            border-right: 1px solid #a0a0a0;      
            padding: 28px 20px;
            max-width:  325px;
            width:100%;
            @media(max-width:767px){
                border: 0;
                padding:  0;
            }
        }
        .copyright-block{
            padding-left: 20px;
          width: calc(100% - 537px);
        }
    }
}

// public Footer section code ends here

// home quote section starts here


.home-quote-started_section{
    background: #EDFDEF;
    .main-heading-lg{
        color: $grey_color;
    }
    .description-text-sm{
        font-size: 30px;
        font-weight: 800;
        font-family: $secondary-font;
        margin: 30px 0;
        @media(max-width:1366px){
            font-size: 25px;
        }
        @media(max-width:767px){
            font-size: 20px;
        }
    }
}

// home quote section ends here



// pagination design code starts here


.public_pagination .pagination-wrapper{
    .pagination{
        justify-content: center;
        .page-item{
            margin: 0 5px;
            .page-link{
                font-size: 20px;
                color: $grey_color;
                font-weight: 500;
                background: $white_color;
                border: 0;
                border-radius: 5px;
                box-shadow: 0px 8px 8px rgba(0,0,0,0.1);
                padding: 10px 16px;
                width: 50px;
                text-align: center;
                position: relative;
                z-index: 1;
            }
            .page-link[aria-label="Previous"],.page-link[aria-label="Next"]{
                background: transparent;
                box-shadow: none;
                span{
                    font-size: 0;
                }
                &::before{
                    position: absolute;
                    content: '\f177';
                    font-size: 30px;
                    left: 0;
                    color: $grey_color;
                    top: 5px;
                    font-family: "Font Awesome 5 Pro";
                }       
              }
              .page-link[aria-label="Next"]{
                  &::before{
                    content: '\f178';
                    right: 0;
                    left: auto;
                  }
              }
        }
        .page-item.disabled{
            .page-link[aria-label="Previous"],.page-link[aria-label="Next"]{
                &::before{
                    opacity: .5;
                }
            }
        }
        .page-item.active{
            .page-link{
                background: $green_color;
                color: $white_color;
            }         
        }
    }
}

.custom-pagination-fr{
    margin-top: 20px;
    ul{
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        li{
            list-style: none;
            margin: 0 5px;
            a{
                font-size: 20px;
                color: $grey_color;
                font-weight: 500;
                background: $white_color;
                border: 0;
                border-radius: 5px;
                box-shadow: 0px 8px 8px rgba(0,0,0,0.1);
                padding: 10px 16px;
                width: 50px;
                display: block;
                text-align: center;
                @media(max-width:767px){
                    padding: 8px 14px;
                    width: 38px;
                    font-size: 18px;
                }
                &:hover{
                    background: $green_color;
                    color: $white_color !important;
                }
            }
        }
        .disabled{
            a{
                opacity: 0.5;
                pointer-events: none;
            }
        }
        .selected{
            a{
                background: $green_color;
                color: $white_color;
            }
        }
    }
}
// pagination design code ends here


// top search design code starts here


.search_1st_step{
   position: fixed;
   top: 0;
   z-index: 9;
   width: 100%;
   left: 0;
   .search_menu_filter{
    max-height: initial;
    overflow: initial;
   }
}

.search_menu_filter{
    padding: 23px 0;
    max-height: 300px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $green_color;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $green_color;
    }
    .menu-filter-section{
        display: inline-block;
        width:100%;  
        vertical-align: middle;
        .left__col{
            position: relative;
            i.fa-search{
                position: absolute;
                left: 20px;
                top:17px;
                z-index: 9;
                color: #a4a4a4;
                font-weight: 400;
            }
            .input_text{
                padding: 14px 55px;
                margin-bottom: 0;
            }
        }
        .cancel_filters{
            position: absolute;
            top: 0;
            right: 0;
            span{
                background: $blue_color;
                display: inline-block;
                padding: 14px 20px;
                border-bottom-right-radius: 5px;
                cursor: pointer;
                border-top-right-radius: 5px;
            }
            i{
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;
                color: $white_color;
            }
        }
    }
}
.filter_by_header_wrap.search_active{
    display: block;
    // position: absolute;
    // left: 0;
    // width: 100%;
    // top: 98px;
    // z-index: 1;
    // @media(max-width:1199px){
    //     top: 93px;
    // }
    // @media(max-width:991px){
    //     top: 70px;
    // }
}
.filter_by_header_wrap{
    box-shadow: 0px 15px 20px 0px rgba(0 ,0, 0,.05);
    display: none;
    .filter_bar {
        ul{
            margin-bottom: 0;
            padding-left: 0;
            li{
                list-style: none;
                display: inline-block;
                vertical-align: top;
                a{
                    padding: 15px 40px;
                    color: #3C3C3C;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: uppercase;
                    text-decoration: none;
                    display: inline-block;
                    position: relative;  
                    @media(max-width:991px){
                       padding-left: 20px;
                       padding-right: 20px;
                    }      
                    i{
                        font-weight: 400;
                        padding-left: 10px;
                    }
                }
                &:first-child{
                    a{
                        border-right: 1px solid #E4E4E4;
                        font-weight: 600;
                        padding-left: 0;
                        i{
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
.cat_filter_header.search_active{
    display: block;
    // position: absolute;
    // left: 0;
    // width: 100%;
    // top: 98px;
    // z-index: 9;
    // @media(max-width:1199px){
    //     top: 93px;
    // }
    // @media(max-width:991px){
    //     top: 70px;
    // }
}
.cat_filter_header{
    box-shadow: 0px 15px 20px 0px rgba(0 ,0, 0,.05);
    display: none;
    .left_cat_col{
        width: 330px;
        padding-right: 50px;
        display: inline-block;
        vertical-align: top;
        border-right: 1px solid #E4E4E4;
        @media(max-width:767px){
            width: 100%;
            padding-right: 0;
            border: 0;
            border-bottom: 1px solid #E4E4E4;
        }
        ul{
            margin-bottom: 0;
            li{
                padding: 20px;
                color: $grey_color;
                font-size: 20px;
                cursor: pointer;
                transition: all 0.4s ease-in-out;
                &:hover{
                    background:#EAF7FF ;
                    border-radius: 5px;
                }
            }
            .listing_cat_main.category_active{
                background:#EAF7FF ;
                border-radius: 5px;
            }
        }
    }
    .right_tech_col{
        display: inline-block;
        vertical-align: top;
        padding-left: 50px;
        width: calc(100% - 330px);
        @media(max-width:767px){
            width: 100%;
            padding-left: 0;
        }
        ul{
            margin-bottom: 0;
            column-count: 5;
            @media(max-width:1500px){
                column-count: 3;
            }
            @media(max-width:991px){
                column-count: 2;
            } 
            @media(max-width:567px){
                column-count: 1;
            }      
            li{
                display: inline-block;
                width: 100%;
                a{
                    padding: 20px;
                    color: $grey_color;
                    font-size: 16px;
                    display: inline-block;
                    text-decoration: none;
                }          
            }
        }
    }
    .tags_filter{
            margin-bottom: 0;
            column-count: 5;
            @media(max-width:1500px){
                column-count: 3;
            }
            @media(max-width:991px){
                column-count: 2;
            } 
            @media(max-width:567px){
                column-count: 1;
            }
            li{
                display: inline-block;
                vertical-align: top;      
                width: 100%;    
                padding: 20px;
                color: $grey_color;
                font-size: 16px;
                display: inline-block;
                text-decoration: none;                   
            }
    }
    .technology_filter{
        margin-bottom: 0;
        li{
            display: inline-block;
            width: 25%;
            vertical-align: top;
            // @media(max-width:1500px){
            //     column-count: 3;
            // }
            @media(max-width:991px){
                width: 50%;
            } 
            @media(max-width:567px){
                width: 100%;
            }
            a{
                padding: 20px;
                color: $grey_color;
                font-size: 16px;
                display: inline-block;
                text-decoration: none;
            }          
        }
    }
}
// top search design code ends here




    .modal-dialog{
        background: $white_color;
        box-shadow: -6.928px 4px 8px rgba(0,0,0,0.1);
        max-width: 700px;
        width: 100%;
        border-radius: 5px;
        left: 0;
        top:98px;
        right: 0;
        margin: auto;
        position: absolute;
        transform: translateY(0) !important;
        .modal-content{
            border:0;
            .success_progress-bar{
                height: 5px;
                .progress-bar{
                    background-color: $green_color;
                }
            }
            .modal-header{
                border: 0;
            }
            .modal-body{
                .modal-title{
                    font-size: 30px;
                    color: $blue_color;
                    font-weight: 700;
                    font-family: $secondary-font; 
                    line-height: 1;
                    margin-bottom: 30px;
                    @media(max-width:1366px){
                       font-size: 26px;
                       margin-bottom: 10px;
                       line-height: 1.5;
                    }
                }
                .form-group{
                    margin-bottom: 20px;
                }
                .input_text{
                    border: 1px solid #A4A4A4;
                    color: $black_color;
                    font-size: 14px;                 
                    padding: 14px 15px;
                    background-color: $white_color !important;
                    box-sizing: border-box;
                    border-radius: 5px;
                    width: 100%;
                    outline: 0;
                    &:focus,&:hover{
                        box-shadow: none !important;
                        background-color: $white_color !important;
                        border-color: $green_color !important;
                        color: $black_color !important;
                        outline: 0;
                    }
                    &::placeholder{
                        color: #969696;
                    }
										.search-wrapper {
											border: none;
											padding: 0;
											border-radius: 0;
											min-height: auto;
										}
                }
                .phone-text-field{
                    font-family: $secondary-font;
                    &::placeholder{
                        font-family: $primary-font;
                    }
                }
                .checkbox__field_c{
                    margin-bottom: 20px;
                    @media(max-width:1366px){
                        margin-bottom: 12px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    input {
                        padding: 0;
                        height: initial;
                        width: initial;
                        margin-bottom: 0;
                        display: none;
                        cursor: pointer;
                      }
                      label {
                        position: relative;
                        cursor: pointer;
                        margin-bottom: 0;
                        font-size: 20px;
                        color: $grey_color;
                        padding-left: 35px;
                        @media(max-width:1366px){
                            font-size: 18px;
                          }
                        &::before{
                            content:'';
                            -webkit-appearance: none;
                            background-color: transparent;
                            border:1px solid #A4A4A4;
                            box-shadow: none;
                            width: 22px;
                            height: 22px;
                            border-radius: 5px;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            cursor: pointer;
                            position: absolute;
                            left: 0;
                            top: 5px;
                            @media(max-width:1366px){
                                width: 20px;
                                height: 20px;
                            }
                        }
                      }
                      input:checked + label:after  {
                        content: '\f00c';
                        display: block;
                        position: absolute;
                        top: 8px;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: bold;
                        left: 5px;
                        color: $white_color;
                        font-size: 12px;
                      }
                      input:checked + label::before {
                        background-color: $blue_color;
                        border-color: $blue_color;
                      }
                }
                .checkbox__field{
                    margin-bottom: 20px;
                    @media(max-width:1366px){
                        margin-bottom: 12px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    input {
                        padding: 0;
                        height: initial;
                        width: initial;
                        margin-bottom: 0;
                        display: none;
                        cursor: pointer;
                      }
                      label {
                        position: relative;
                        cursor: pointer;
                        margin-bottom: 0;
                        font-size: 20px;
                        color: $grey_color;
                        padding-left: 35px;
                        @media(max-width:1366px){
                          font-size: 18px;
                        }
                        &::before{
                            content:'';
                            -webkit-appearance: none;
                            background-color: transparent;
                            border:1px solid #A4A4A4;
                            box-shadow: none;
                            width: 22px;
                            height: 22px;
                            border-radius: 5px;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            cursor: pointer;
                            position: absolute;
                            left: 0;
                            top: 5px;
                            @media(max-width:1366px){
                                width: 20px;
                                height: 20px;
                            }
                        }
                      }
                      .isChecked + label:after  {
                        content: '\f00c';
                        display: block;
                        position: absolute;
                        top: 8px;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: bold;
                        left: 5px;
                        color: $white_color;
                        font-size: 12px;
                      }
                      .isChecked + label::before {
                        background-color: $blue_color;
                        border-color: $blue_color;
                      }
                }
                .remove_option{
                    border: 0 !important;
                    padding: 0 !important;
                }
                .inner_ques-wrapper{
                    border-radius: 5px;
                    background: $white_color;
                    border: 1px solid #a4a4a4;
                    padding:24px 30px;
                    @media(max-width:1366px){
                      padding: 18px 25px;
                    }
                    .login_ques_tabs{
                        .tab_option_list{
                            padding-left: 0;
                            margin: -25px -31px;
                            margin-bottom: 30px;
                            display: flex;
                            flex: 1;
                            @media(max-width:1366px){
                                margin: -19px -26px;
                                margin-bottom: 30px;
                            }
                            li{
                                list-style: none;
                                flex: auto;
                                a{
                                    padding: 15px;
                                    text-align: center;
                                    font-size: 18px;
                                    font-weight: 500;
                                    // border-radius: 0;
                                    border-bottom: 1px solid #a4a4a4;
                                    color: $blue_color;
                                    transition: all 0.4s ease-in-out;
                                }
                                a.active{
                                    background: $green_color;
                                    color: $white_color;
                                }
                                &:nth-child(1){
                                    a{
                                        border-top-right-radius: 0;
                                        border-bottom-right-radius: 0;
                                    }
                                }
                                &:nth-child(2){
                                    a{
                                        border-top-left-radius: 0;
                                        border-bottom-left-radius: 0;
                                    }
                                }
                            }
                        }
                    }
                    .option-label{
                        color: $blue_color;
                        border-bottom: 1px solid #ccc;
                        padding-bottom: 6px;
                        margin-bottom: 18px;
                    }
                }
                .quote_loader_remove{
                    border: 1px solid transparent;
                    #spinner{
                        background: #ffffff;
                    }
                }
								.autocomplete-dropdown-container {
									box-shadow: 0 6px 10px 0 #f0e5e5;
									margin-top: -20px;
									> div {
										font-size: 14px;
										padding: 7px 10px;
									}
								}
            }
        }
    }
    .back-btn{
        font-size: 16px;
        color: #a4a4a4;
        font-weight: 500;
        background: transparent;
        border:0;
        padding: 13px 0px !important;
        text-transform: uppercase;
        &:focus,&:hover,&:active{
            outline: 0;
            box-shadow: none !important;
            color: #a4a4a4;
            background: transparent;
        }
    }

.quit_ques_modal{
    .modal-dialog{
       .modal-content{
      padding-top: 20px;
        .modal-body{
            margin-top: 20px;
        }
       } 
    }
}
.quote__question_modal.modal .modal-dialog{
        .modal-header{
            background: $white_color;
            .btn-close{
                color: $grey_color;
                opacity: .5;
                position: relative;
                background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
                &:focus{
                    box-shadow: none;
                    outline: 0;
                }
                // &:hover{
                //     background: transparent;
                // }
                &:before,&:after {                
                    position: absolute;
                    left: 15px;
                    content: ' ';
                    height: 25px;
                    width: 2px;
                    background-color: transparent;
                    top: 3px;
                  }
            }
        }
    .modal-body{
        margin-top: 20px;
        padding-top: 0;
        padding-bottom: 12px;
        padding-right: 60px;
        padding-left: 60px;
        @media(max-width:1366px){
            margin-top: 0;
        }
        @media(max-width:991px){
            padding-right: 28px;
            padding-left: 28px;
        }
    }
    .modal-footer{
        border:0;
        padding-right: 58px;
        padding-left: 60px;
        padding-bottom: 60px;
        display: block;
        @media(max-width:991px){
            padding-right: 28px;
            padding-left: 28px;
        }
        .back-btn{
            display: inline-block;
            vertical-align: middle;
        }
        .second-btn:hover{
            background: $blue_color !important;
        }
        .primary-btn{
            float: right;
            .spinner-border{
                margin-left: 5px;
            }
        }
    }
}

.thank_you_model{
    .main-heading{
        margin-top: 40px;
        margin-bottom: 10px;
    }
    .modal-content,.modal-header{
        background: #eaf7ff !important;
    }
    .modal-body{
        padding-bottom: 80px !important;
        .primary-btn{
            margin-top: 30px;
        }
    }
}
.validation_error{
    color: red;
    display: block;
    width: 100%;
}
.filter_section{
    .btn-secondary{
        &.primary-btn{
        padding: 13px 15px !important;
        }
    }
}



// datepicker design code starts here

.react-datepicker__tab-loop{
    .react-datepicker-popper{
        z-index: 2;
        .react-datepicker__triangle{
            &::before,&::after{
                border-bottom-color:$blue_color !important;
                left: -13px !important;
            }
        }
        .react-datepicker{
            border-radius: 0;
            .react-datepicker__navigation{
                top: 9px;
            }
            .react-datepicker__header {
                border-radius: 0;
                background: $blue_color;      
            }
            .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,.react-datepicker__day-name, .react-datepicker__time-name{
                color: $white_color;
                }
            .react-datepicker__day--selected,.react-datepicker__day--keyboard-selected,.react-datepicker__month--selected,.react-datepicker__year-text--selected ,.react-datepicker__month-text--keyboard-selected,.react-datepicker__year-text--keyboard-selected{
                background: $blue_color;
                &:hover{
                    background: $blue_color;
                }
            }
            .react-datepicker__month-text,.react-datepicker__year-text{
                &:hover{
                    background: $blue_color;
                    color: $white_color;
                }
            }
            .react-datepicker__navigation-icon{
                &::before{
                    border-color: $white_color;
                }
            }
        }
    }
}


// datepicker design code ends here

.error-page{
    background-color: #f7f7f7;
    position: relative;
    overflow: hidden;
    img{
    text-align: center;
    max-width: 0px;
    width: 100%;
    max-width: 600px;
    @media(max-width:1280px){
            text-align: center;
            max-width: 400px;
        }
        @media(min-width:481px) and (max-width:767px){
            max-width: 200px;
        }
    }
    
    h4{
        font-size: 40px;
        margin-bottom: 30px;
        @media(max-width:1280px){
            font-size: 28px;
        }
        @media(max-width:767px){
            font-size: 24px;
        }
    }
    .primary-btn{
        z-index: 1;
    }
    .error-container {
        text-align: center;
        margin-bottom: 30px;
         span {
        line-height: 1;
        position: relative;
        color: $blue_color;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        font-size: 180px;
        font-weight: 800;
        @media(max-width:1199px){
            font-size: 100px;
        }
        &:nth-of-type(1) {
            color: $blue_color;
            animation: colordancing 4s infinite;
          }
          &:nth-of-type(3) {
            color: $green_color;
            animation: colordancing2 4s infinite;
          }
          &:nth-of-type(4) {
            color: $blue_color;
            animation: colordancing2 4s infinite;
          }
      }
      span.o_text {
        width: 120px;
        height: 120px;
        border-radius: 999px;
        @media(max-width:1199px){
            width: 80px;
            height: 80px;
        }
        &::before,&::after{
            border-radius: 0%;
            content:"";
            position: absolute;
            top: 0; left: 0;
            width: inherit; height: inherit;
          border-radius: 999px;
            box-shadow:inset 30px 0 0 rgba(0 ,77, 192,1), 
            inset 0 30px 0 rgba(153, 220 ,218,1),
             inset -30px 0 0 rgba(40 ,106, 190,1), 
             inset 0 -30px 0 rgba(101, 150 ,187,1);
          animation: shadowsdancing 4s infinite;
        }
        &:before {
            -webkit-transform: rotate(45deg);
               -moz-transform: rotate(45deg);
                    transform: rotate(45deg);
        }
      }
      }   
      .screen-reader-text {
          position: absolute;
          top: -9999em;
          left: -9999em;
      }
      @keyframes shadowsdancing {
        0% {
            box-shadow:inset 30px 0 0 rgba(0 ,77, 192,0.4), 
            inset 0 -30px 0 rgba(101, 150 ,187,0.4),
            inset -30px 0 0 rgba(40 ,106, 190,0.4),
            inset 0 30px 0 rgba(153, 220 ,218,0.4);
        }
        25% {
            box-shadow:inset -30px 0 0 rgba(40 ,106, 190,0.4), 
            inset 30px 0 0 rgba(0 ,77, 192,0.4), 
            inset 0 30px 0 rgba(153, 220 ,218,0.4),
             inset 0 -30px 0 rgba(101, 150 ,187,0.4);
        }
        50% {
            box-shadow:
             inset -30px 0 0 rgba(40 ,106, 190,0.4), 
             inset 0 -30px 0 rgba(101, 150 ,187,0.4),
             inset 30px 0 0 rgba(0 ,77, 192,0.4), 
             inset 0 30px 0 rgba(153, 220 ,218,0.4);
        }
        75% {
            box-shadow:
            inset 0 30px 0 rgba(153, 220 ,218,0.4),
             inset -30px 0 0 rgba(40 ,106, 190,0.4), 
             inset 30px 0 0 rgba(0 ,77, 192,0.4), 
             inset 0 -30px 0 rgba(101, 150 ,187,0.4);
        }
        100% {
            box-shadow:inset -30px 0 0 rgba(40 ,106, 190,0.4), 
            inset 30px 0 0 rgba(0 ,77, 192,0.4), 
            inset 0 30px 0 rgba(153, 220 ,218,0.4),
             inset 0 -30px 0 rgba(101, 150 ,187,0.4);
        }
      }
      @keyframes colordancing {
        0% {
          color: $blue_color;
        }
        25% {
          color: $green_color;
        }
        50% {
          color: $blue_color;
        }
        75% {
          color: #59f3bc;
        }
        100% {
          color: #4085ed
        }
      }
      @keyframes colordancing2 {
        0% {
          color: $blue_color;
        }
        25% {
          color: #59f3bc;
        }
        50% {
          color: #4085ed;
        }
        75% {
          color: $green_color;
        }
        100% {
          color: $blue_color;
        }
      }
      #background-wrap {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        @media(max-width:640px){
           display: none;
          }
    }
    
   
    @-webkit-keyframes animateBubble {
        0% {
            margin-top:700px;
        }
        100% {
            margin-top: -100%;
        }
    }
    
    @-moz-keyframes animateBubble {
        0% {
            margin-top: 700px;
        }
        100% {
            margin-top: -100%;
        }
    }
    
    @keyframes animateBubble {
        0% {
            margin-top: 700px;
        }
        100% {
            margin-top: -100%;
        }
    }
    
    @-webkit-keyframes sideWays { 
        0% { 
            margin-left:0px;
        }
        100% { 
            margin-left:50px;
        }
    }
    
    @-moz-keyframes sideWays { 
        0% { 
            margin-left:0px;
        }
        100% { 
            margin-left:50px;
        }
    }
    
    @keyframes sideWays { 
        0% { 
            margin-left:0px;
        }
        100% { 
            margin-left:50px;
        }
    }
    
    // / ANIMATIONS /
    
    .x1 {
        -webkit-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
        -moz-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
        animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
        
        left: -5%;
        top: 5%;
        
        -webkit-transform: scale(0.6);
        -moz-transform: scale(0.6);
        transform: scale(0.6);
    }
    
    .x2 {
        -webkit-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
        -moz-animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
        animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
        
        left: 5%;
        top: 80%;
        
        -webkit-transform: scale(0.4);
        -moz-transform: scale(0.4);
        transform: scale(0.4);
    }
    
    .x3 {
        -webkit-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
        -moz-animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
        animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
        
        left: 10%;
        top: 40%;
        
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        transform: scale(0.7);
    }
    
    .x4 {
        -webkit-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
        -moz-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
        animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
        
        left: 20%;
        top: 0;
        
        -webkit-transform: scale(0.3);
        -moz-transform: scale(0.3);
        transform: scale(0.3);
    }
    
    .x5 {
        -webkit-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
        -moz-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
        animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
        
        left: 30%;
        top: 50%;
        
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        transform: scale(0.5);
    }
    
    .x6 {
        -webkit-animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
        -moz-animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
        animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
        
        left: 50%;
        top: 0;
        
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        transform: scale(0.8);
    }
    
    .x7 {
        -webkit-animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate;
        -moz-animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate;
        animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate;
        
        left: 65%;
        top: 70%;
        
        -webkit-transform: scale(0.4);
        -moz-transform: scale(0.4);
        transform: scale(0.4);
    }
    
    .x8 {
        -webkit-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
        -moz-animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
        animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
        
        left: 80%;
        top: 10%;
        
        -webkit-transform: scale(0.3);
        -moz-transform: scale(0.3);
        transform: scale(0.3);
    }
    
    .x9 {
        -webkit-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
        -moz-animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
        animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
        
        left: 90%;
        top: 50%;
        
        -webkit-transform: scale(0.6);
        -moz-transform: scale(0.6);
        transform: scale(0.6);
    }
    
    .x10 {
        -webkit-animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
        -moz-animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
        animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
        
        left: 80%;
        top: 80%;
        
        -webkit-transform: scale(0.3);
        -moz-transform: scale(0.3);
        transform: scale(0.3);
    }
    
    // / OBJECTS /
    
    .bubble {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        
        -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $green_color;
        -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $green_color;
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px $green_color;
        
        height: 200px;
        position: absolute;
        width: 200px;
        @media(max-width:991px){
            width: 150px;
            height: 150px;
        }
    }
    
    .bubble:after {
        background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%); 
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(255,255,255,0.5)), color-stop(70%,rgba(255,255,255,0)));
        background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); 
        background: -o-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%);
        background: -ms-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%);
        background: radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#00ffffff',GradientType=1 );
    
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        
        -webkit-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
        -moz-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
        box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
        
        content: "";
        height: 180px;
        left: 10px;
        position: absolute;
        width: 180px;
        @media(max-width:991px){
            width: 100px;
            height: 100px;
        }
    }
    .cat_listing{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        li{
            list-style: none;
            padding: 0 10px;
            margin-bottom: 20px;
            a{
                color: $white_color;
                text-decoration: none;
                padding: 10px 30px;
                font-size: 14px;
                font-weight: 700;
                border-radius: 5px;
                box-shadow: 0 1px 20px rgba(0 ,0 ,0 , 0.20);
                position: relative;
                display: inline-block;
                transition: all 0.4s ease-in-out;
                background-color: $blue_color;
                font-size: 18px;
                text-transform: uppercase;
                color: $white_color;
                z-index: 1;
                @media(max-width:991px){
                    font-size: 16px;
                }
                &:hover{
                    box-shadow: 0 7px 18px 0 rgba(0, 0 ,0 , 0.30);
                    transform: translateY(-5%);
                    transition: all ease-in-out .3s;
                    background-color: $green_color;
                    color: $white_color;
                }
            }
        }
    }
}

// find us on section
.find-us-on {
    .main-heading {
        font-size: 32px;
    }
.social-links{
padding-left: 0;
margin-bottom: 0;
margin-top: 20px;
text-align: center;
    li{
    display: inline-block;
    list-style: none;
    margin: 0 8px;
    a{
    color: $white_color;
    display: inline-block;
    text-align: center;
    i{
        width: 32px;
        height: 32px;
        padding: 8px;
        border-radius: 50%;
    }
}
    .fa-facebook-f{
    background: #3b5998;
    }
    .fa-twitter{
    background: #55acee;
    }
    .fa-instagram{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    }
    .fa-pinterest-p{      
    background: #cb2027;
    }
    .fa-linkedin-in{
    background: #007ab9;
    }
}
}
}

.nw-contact-page {
/*    padding-top: 80px !important;*/
    display: flex;
    flex-flow: column;
    justify-content: center;
    @media(min-width: 991px){
        min-height: 600px;
    }
    .thank-heading {
    color: #004dc0;
    font-family: "Nanum Myeongjo",serif;
    font-size: 60px;
    margin-bottom: 0;
    font-weight: 800;
    @media(max-width: 767px){
       font-size: 40px; 
    }
    }
    .main-heading{
        font-size: 26px;
        margin-top: 15px;
        color: #333;
        margin-bottom: 20px;
    @media(max-width: 767px){
       font-size: 20px; 
    }
    }
    .primary-btn {
        margin: 0px auto;

    }
}