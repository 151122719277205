@import 'variables';


// banner design code starts here


.banner_breadcrumb_section{
    background: url('../../../public/public/about-bg.png');
    background-size: cover;
    padding: 150px 20px;
    min-height: 500px;
    @media(max-width:1366px){
     background-position: 15%;
     min-height: 390px !important;
     padding-top: 100px !important;
    }
    
    @media(max-width:991px){
        padding: 100px 20px;
        min-height: auto;
    }
    .main-heading-lg{
        color: $white_color;
        text-shadow: 0px 0px 10px $blue_color;
    }
}

// banner design code ends here

// about sec1 intro code starts here

.about_sec1-intro{
    max-width: 1200px;
    width: 100%;
    margin: auto;
    @media(max-width:1366px){
        padding-top: 30px;
    }
}


// about sec1 intro code ends here


// about why section code starts here

.about_why-toprow {
    .row{
        align-items: center;
    }
    .right_col{
        padding-left: 70px;
        @media(max-width:767px){
            padding-left: 0;   
             margin-top: 40px;
        }
        .main-heading{
            margin-bottom: 20px;
        }
    }
}


// about why section code ends here


// about tools section code starts here

.about-tools-section{
    background: #EAF7FF;
    .tools-upper-section{
         max-width: 950px;
         width: 100%;
         margin: auto;
         .description-text-sm{
            margin-top: 30px;
            margin-bottom: 60px;
         }
    }
    .tool-listing-section{
        text-align: center;
        padding-left: 0;
        margin-bottom: 0;
        li{
            max-width: 365px;
            width: 100%;
            display: inline-block;
            background: $white_color;
            box-shadow: 0 8px 16px 2px rgba(0,0,0,0.1);
            margin: 0 20px;
            @media(max-width:1400px){
                max-width: 300px;
            }
            @media(max-width:1199px){
              margin-bottom: 40px;
              &:last-child{
                  margin-bottom: 0;
              }
            }
            @media(max-width:767px){
               max-width: none;
               margin-left: 0;
               margin-right: 0;
             }
            .tools-listing-inner{
                padding: 46px;
                text-align: left;
                position: relative;
                .list_number{
                    font-size: 40px;
                    font-weight: 800;
                    opacity: 0.1;
                    color: $grey_color;
                    margin-bottom: 0;
                    position: absolute;
                    bottom: 0px;
                    right: 26px;
                    line-height: initial;
                }
                .list_head{
                  border-bottom: 1px solid #E4E4E4;
                  padding-bottom: 25px;
                  margin-bottom: 25px;
                  @media(max-width:1400px){
                    min-height: 74px;
                    display: flex;
                    align-items: center;
                 }
                 @media(max-width:767px){
                    min-height: auto;
                 }
								 span {
									display: inline-block !important;
									vertical-align: middle;
								 }
                  img{
                      display: inline-block;
                  }
                  h5{
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 0;
                    color: #3C3C3C;
                    font-size: 20px;
                    font-weight: 400;
                    margin-left: 10px;
                    width: calc(100% - 55px);
                  }
                }
            }
        }
    }
}



// about tools section code ends here


// About what we do starts here

.wht-do-about-section{
  
    .row{
        align-items: center;
    }
    .left_col_block{
        padding-right: 70px;
        @media(max-width:767px){
           padding-right: 0;
           margin-bottom: 30px;
         }
         .main-heading{
             margin-bottom: 15px;
         }
    }
    .text-right{
        @media(max-width:767px){
            text-align: center;
        }
    }
}


// About what we do ends here


// About Company section code starts here

.about-company_section {
    .abt-company-listing{
        padding-left: 0;
        margin-bottom: 0;
         margin-top: 60px;
        li{
            display: inline-block;
            list-style: none;
            width: 33%;
            padding: 0px 40px;
            @media(max-width:991px){
                padding: 0 25px;
            }
            @media(max-width:767px){
               width: 100%;
               margin-bottom: 20px;
               &:last-child{
                   margin-bottom: 0;
               }
            }
            .icon-section{
                margin-bottom: 30px;
                position: relative;
                .icon-inner{
                    background: $white_color;
                    border: 2px solid $blue_color;
                    height: 82px;
                    width: 82px;
                    border-radius: 50%;
                    padding: 18px;
                    margin: auto;
                    position: relative;
                }
                .arrow{
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    right: -50%;
                    height: 2px;
                    max-width: 376px;
                    width: 100%;
                    background: $blue_color;
                    @media(max-width:767px){
                      display: none;
                     }
                    &::after{
                        border-bottom-style: solid;
                        border-bottom-width: 2px;
                        border-right-style: solid;
                        border-right-width: 2px;
                        content: "";
                        display: inline-block;
                        height: 28px;
                        right: 2px;
                        position: absolute;
                        top: -12.8px;
                        width: 28px;
                        transform: rotate(315deg);
                        border-color: $blue_color;
                    }
                }
                .disable-arrow{
                    max-width: 168px;
                    width: 100%;
                    background: #E4E4E4;
                    right: 0px;
                    @media(max-width:1199px){
                        display: none;
                    }
                    &::after{
                        border-color:#E4E4E4;
                    }
                }
                .first-arrow.disable-arrow{
                    max-width: 150px;
                    right: 70%;
                }
            }
            .content-section{
                h6{
                    color: #3C3C3C;
                    margin-bottom: 16px;
                    font-size: 20px;
                    font-weight: normal;
                }
            }
        }
    }
}
// About Company section code ends here
// New Content section
.about_grid {
    padding: 0px 0px 40px 0px;
    .about_grid_content{
        @media(max-width:767px){
            margin-bottom: 20px;
        }
    }
    .btn-soft-primary {
        background-color: #004dc0;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        @media (max-width:767px) {
            font-size: 18px;
            width: 40px;
            height: 40px;
        }
        span{
            color: #EAF7FF;
        }
    }

    .content_ab_wrap{
        padding: 40px 10px;
    }
    .learn-how{
        padding-top: 30px;
    }
}
// New Content section end

// awards
.awards-page{
    .awards-section {
        text-align: center;
            h2 {
                margin-top: 50px;
                margin-bottom: 30px;
                @media(max-width:480px){
                margin-top: 30px;
                }
            }
            .awards-flex {
                display: flex;
                flex-flow: wrap;
                justify-content: center;
                .awards-top {
                    width: 25%;
                    padding: 15px;
                    @media(max-width:991px) {
                        padding: 10px;
                    }
                    @media (max-width:600px) {
                        width: 50%;
                    }
                }
            }
    }
    .awards-toprow{
        text-align: center;
        padding: 40px 10px 80px 10px;
        .award-btn-flex{
            display: flex;
            justify-content: center;
            padding-top: 20px;

            a{
                margin: 10px;
                &.primary-btn{
                    @media (max-width:480px) {
                        padding: 13px 18px !important;
                    }
                }
            }
        }
    }
}

// About Company section code ends here


//pro center page design code starts here


.pro-center-page{
   .services_pop_section{
       background: $white_color;
   }
   .home-quote-started_section{
       .main-heading{
           margin-bottom: 30px;
       }
       .primary-btn{
           &:last-child{
               margin-left: 20px;
               @media(max-width: 640px){
                   margin-left: auto;
               }
           }
           @media(max-width: 640px){
            margin-bottom: 20px;
            display: block;
            margin-left: 0;
            max-width: 280px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
           }
       }
   }
}


//pro center page design code ends here


// privacy page design code starts here

.privacy-page{
    .main-heading{
        margin: 50px 0;
    }
}

// privacy page design code ends here


// review guidelines page code starts here


.review-guidelines-page{
   .description-text{
       margin-bottom: 20px;
       a{
           color: $blue_color;
       }
   }
    .signup-section-blue{
        background:#EAF7FF ;
        .primary-btn{
            margin-bottom: 40px;
        }
        .row{
            align-items: center;
        }
        video{
            box-shadow: 0 0 16px 2px rgba(0 ,0, 0 ,.20);
        }
    }
}
.review-quote-section{
    .main-heading-lg{
        margin-bottom: 40px;
    }
}

// review guidelines page code ends here