@import 'variables';

// search page design code starts here

.search-page{
    padding: 50px 0;
    .search-bar-wrap{
        display: flex;
        border-radius: 5px;
        background: #f9f9f9;
        align-items: center;
        padding-right: 0;
        border: 1px solid #e5e5e5;
        position: relative;
        margin-bottom: 20px;
        @media(max-width:640px){
           display: block;
        }
        i.fa-search{
            font-size: 20px;
            color: $grey_color;
            font-weight: 500;
            position: absolute;
            left: 20px;
            top: 20px;
            z-index: 9;
            color: #a4a4a4;
            font-weight: 400;
        }
        .input_text{
   
            border: 0;
            margin-bottom: 0;
            padding: 13px 15px;
            font-size: 20px;
            padding-left: 50px;
            color: $grey_color;
            font-weight: 500;
            background-color: #f9f9f9 !important;
        }
        .left-col-block{
            position: relative;
            width: calc(100% - 300px);
            @media(max-width:640px){
                width: 100%;
                border-bottom: 1px solid #e5e5e5;
            }
            .close-icon{
                display: inline-block;
                position: absolute;
                cursor: pointer;
                right: 10px;
                top: 20px;
                font-size: 18px;
                color: $blue_color;
            }
        }      
        .btn-wrap{
            width: 300px;
            margin-left: auto;
            text-align: center;
            background: $white_color;
            padding: 8px 15px;
            border-radius: 5px;
            border-left: 1px solid #e5e5e5;
            @media(max-width:640px){
                width: 100%;
            }
            button{
                background: $white_color;
                padding: 8px 10px;
                color: $grey_color;
                font-weight: 500;
                border: 0;
                font-size: 15px;
                text-transform: uppercase;
                transition: all 0.4s ease-in-out;
                &:not(:first-child){
                    margin-left: 10px;
                }
                &:hover,&:focus{
                    background: $blue_color;
                    color: $white_color;
                    box-shadow: none;
                    outline: 0 ;
                }
            }
            button.active{
                background: $blue_color;
                color: $white_color;
            }
        }
    }
    .sub-filter-listing{
        padding-left: 0;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        li{
            list-style: none;
            max-width: 200px;
            width: 100%;
            padding-right: 20px;
            margin-bottom: 20px;
            @media(max-width:640px){
                width: 50%;
                max-width: 100%;
            }
        }
    }
    .sub-filters{
        position: relative;
        .select-label{
        position: absolute;
        top: 12px;
        left: 10px;
        font-size: 14px;
        z-index: 1;
        margin-bottom: 0;
        background: $white_color;
        padding:2px 4px;
        transition: all 0.4s ease-in-out;
        }
    }
    .sub-filters.is_disabled{
       pointer-events: none;
    }
    .sub-filters.active_sub_filters{
        .select-label{
            font-size: 12px;
            top: -10px;
        }
    }
    .agency_popup_detail{
        margin-bottom: 25px;
        transition: all 0.4s ease-in-out;
        height: auto;
        position: relative;
        &:hover{
            box-shadow:0 1px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            .detail-wrap .follow-wrapping{
                transform: translateY(0);
            }
        }
        .ribbon {
            width: 134px;
            height: 134px;
            overflow: hidden;
            position: absolute;
            z-index: 1;
            &::before,&::after{
                position: absolute;
                z-index: -1;
                content: '';
                display: block;
                border: 5px solid red;
            }
            span {
                position: absolute;
                display: block;
                width: 214px;
                padding: 10px 0;
                background-color: red;
                box-shadow: 0 5px 10px rgba(0,0,0,.1);
                color: #fff;
                font-weight: 600;
                text-shadow: 0 1px 1px rgba(0,0,0,.2);
                text-transform: uppercase;
                text-align: center;
              }
          }
          /* top left*/
          .ribbon-top-left {
            top: -10px;
            left: -10px;
            &::before,&::after{
                border-top-color: transparent;
                border-left-color: transparent;
            }
            &::before{
                top: 0;
                right: 0;
            }
            &::after{
                bottom: 0;
                left: 0;
            }
            span {
                right: -25px;
                top: 30px;
                transform: rotate(-45deg);
              }
          }  
        .cover-img-wrap{
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            img{
                border-top-left-radius: 5px;
                border-top-right-radius: 5px; 
            }
        }
        .detail-wrap{
            position: relative;
            margin-bottom: 0;
            overflow: hidden;
            .name{
                text-decoration: none;
								a {
									cursor: pointer;
								}
            }
            .address{
                min-height: 48px;
            }
            .follow-wrapping{
                position: absolute;
                transform: translateY(100%);
                width: 100%;
                display: flex;
                left: 0;
                align-items: center;
                background: $white_color;
                padding: 32px 10px;
                box-shadow: 0px -3px 4px 2px #00000008;
                transition: all 0.4s ease-in-out;
                bottom: 0;
                @media(min-width:1200px) and (max-width:1400px){
                  display: block;
                 padding: 15px 10px;
                 }
                 @media(max-width:640px){
                    display: block;
                   padding: 15px 10px;
                   }
                .follow-btn{
                    width: 50%;
                    &:last-child{
                        margin-left: 10px;
                    }
                    @media(min-width:1200px) and (max-width:1400px){
                       width: 100%;
                       margin: 0;
                       &:last-child{
                        margin-left: 0px;
                        }
                    }
                    @media(max-width:991px){
                       font-size: 14px;
                     }
                     @media(max-width:640px){
                        width: 100%;
                        margin: 0;
                        &:last-child{
                         margin-left: 0px;
                         }
                     }
                }
                .follow-btn-like{
                    margin-top: 0;
                    @media(min-width:1200px) and (max-width:1400px){
                       margin-top: 10px;
                     }
                     @media(max-width:640px){
                     margin-top: 10px;
                       }
                }
            }
        }
    }
    .total_count{
        color: #9e9e9e;
        font-weight: 500;
        text-align: right;
    }
}
// search page design code ends here