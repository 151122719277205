// success tracker code starts here

.success_tracker__page{
    .trcaker_in_per{
        font-size: 40px;
        font-family: $secondary-font;
        color: #004dc0;
        margin: 10px 0;
        font-weight: 700;
    }
    .table-responsive{
        margin-bottom: 30px;
        padding-right: 60px;
        @media(max-width:767px){
            padding-right: 0;
        }
        .table{
            th{
                    font-weight: normal;
                    font-size: 16px;
                    color: $grey_color;
                    padding: 4px 0;
                    border: 0;
                    b{
                        font-weight: 500;
                    }
                    i{
                        font-size: 10px;
                        padding-right: 4px;
                        font-weight: 500;
                    }
            }
            td{
                    font-size: 16px;
                    color: $grey_color;
                    border: 0;
                    text-align: right;
                    padding: 4px 0;
            }
        }
    }
    .visit_table{
        margin-top: 20px;
    }
    .chart-section{
        margin: 40px 0;
        padding-bottom: 40px;
        .chart_success-wrap{
            max-width: 500px;
            width: 100%;
            margin: auto;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 20px;
            .lead-heading{
                margin-top: 20px;
            }
        }
    }
}

// success tracker code ends here