@import 'variables';


// find firm section code starts here

.find-firm-section{
    .small-text-title{
        color: $grey_color;
        font-family: $secondary-font;
        font-size: 30px;
        font-weight: 800;
    }
    .main-heading{
        font-size: 60px;
        margin: 20px 0;
        @media(max-width: 1366px){
            font-size: 40px;
        }
        @media(max-width: 1199px){
            font-size: 35px;
        }
    }
    .row{
        align-items: center;
    }
    .left__col{
        padding-left: 21%;
        padding-right: 86px;
        padding-top: 30px;
        padding-bottom: 30px;
        @media(max-width: 1366px){
            padding: 30px 20px;
        }
    }
    .right__col{
        padding-right: 0;
        padding-left: 0;
    }
}


.select_quote_wrapper{
    margin: 30px 0;
    .project_quote__section{
        width: calc(100% - 150px);
        display: inline-block;
        vertical-align: top;
        @media(max-width:991px){
            width: calc(100% - 64px);
        }
        @media(max-width:640px){
            width: 100%
        }
        .first_field{
            width: 50%;
            float: left;
            position: relative;
            .input-text{
                .css-1adrie-Control{
                    @media(max-width:1366px){
                        font-size: 14px;
                    }
                }
            }
            .input_text{
                border: 1px solid #A4A4A4;
                color: $black_color;
                font-size: 14px;
                padding: 11px 15px;
                background-color: $white_color !important;
                box-sizing: border-box;
                border-radius: 5px;
                width: 100%;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                margin-bottom: 0px;
                min-height: 51px;
                border-left-color: #e4e4e4;
                font-family: $primary-font;
                @media(max-width:1366px){
                    font-size: 14px;
                }
                &:focus,&:hover{
                    box-shadow: none !important;
                    background-color: $white_color !important;
                    // border-color: $green_color !important;
                    color: $black_color !important;
                }
                &::placeholder{
                    color: #969696;
                }
            }
            &:last-child{
                font-family: $secondary-font;              
            }
            #find-btn{
                color: $blue_color;
                font-size: 16px;
                display: block; 
                position: absolute;
                right: 0;
                top: 0;
                padding: 13px 10px;
            }

        }
    }
    .primary-btn{
        display: inline-block;
        vertical-align: top;
        width: 150px;
        text-align: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -4px;
        padding: 12px 18px !important;
        min-height: 51px;
        @media(max-width:991px){
           width: auto;
           padding: 16px 22px !important;
        }
        @media(max-width:640px){
            width: 100%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            margin-top: 20px;
            margin-left: 0;
        }
        i{
            display: none;
            font-size: 20px;
            @media(max-width:991px){
                display: block;
            }
            @media(max-width:640px){
                display: none;
            }
        }
        span{
            @media(max-width:991px){
                display: none;
            }
            @media(max-width:640px){
                display: inline-block;
            }
        }
    }
}

.find-loc-model{
    .modal-dialog{
        max-width: 1100px;
        .map-view{
            height: 400px;
        }
    }
}
// find firm section code ends here


// Popular Category section code starts here


.category_pop_section{
    .cat_pop_listing{
        margin-top: 50px;
        ul{
            padding-left: 0;
            margin-bottom: 0;
            li{        
                width: 20%;
                // max-width:305px;
                margin: 0;
                padding:0 15px;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 50px;
                @media(max-width:1440px){
                    padding:0 12px;
                }
                @media(max-width:1199px){
                    width: 33%;
                    margin-bottom: 25px;
                }
                @media(max-width:767px){
                    width: 50%;
                }
                @media(max-width:640px){
                    width: 100%;
                }
                &:hover{
                    .inner_wrap .img_section{
                        &::before{
                           height: 100%;
                           opacity: 0.8;
                        }
                        .icon_block{
                            opacity: 1;
                            transform: translate(0%, -50%) !important;
                        }
                    }
                }
                &:nth-child(even){
                    .inner_wrap .img_section{
                      &::before{
                          top: 0;
                          bottom: auto;
                      }
                      .icon_block{
                        transform: translate(0%, -160%);
                      }
                  }
                }
                .inner_wrap{              
                    box-shadow: 0 8px 16px 2px rgba(0,0,0,0.1);
                    background: $white_color;
                    border-radius: 5px;   
                .img_section{
                    position: relative;
                    // height: 295px;
                    background: #fff;
                    display:block;
                    max-width: 100%;
                    overflow: hidden;
                    width: 100%;             
                    &::before{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 0;
                        background: $blue_color;
                        bottom: 0;
                        left: 0;
                        transition: all 0.4s ease-in-out;
                        z-index: 1;
                    }
                    // .cat_img{
                    //     height: 100%;
                    //     object-fit: cover;
                    //     width: 100%;
                    // }
                    .icon_block{
                        position: absolute;
                        top: 50%;
                        transform: translate(0%, 15%);
                        margin: auto;
                        left: 0;
                        right: 0;
                        opacity: 0;
                        transition: all 0.4s ease-in-out;
                        z-index: 1;
												img {
													min-width: auto !important;
													min-height: auto !important;
												}
                    }
                    span{
                        display: block !important;
                        height: 0 !important;
                        overflow: hidden !important;
                        padding-bottom: 100% !important;
                        position: relative !important;
                        z-index: 0;
                            img{
                                bottom: 0 !important;
                                display: block !important;
                                height: 51px !important;
                                left: 0 !important;
                                margin: auto !important;
                                max-width: 100% !important;
                                position: absolute !important;
                                right: 0 !important;
                                top: 0 !important;
                                width: 64px !important;
                                border-radius: 0 !important;
                                border: 0 !important;
																min-height: auto !important;
																max-height: none !important;
																height: auto !important;
                            }
                       }
                }
                .cat_name_section{
                    p{
                        font-size: 20px;
                        color: $grey_color;
                        margin-bottom: 0;
                        padding: 18px;
                        min-height: 96px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media(max-width:1550px){
                            font-size: 18px;
                        }
                        @media(max-width:1440px){
                           min-height: 94px;
                           display: flex;
                           justify-content: center;
                           align-items: center;
                        }
                    }
                }
                }
            }
        }
    }
}


// Popular services section code starts here

.services_pop_section{
    background-color: #EAF7FF;
    .services_home_listing{
        margin-top: 40px;
        margin-bottom: 0;
        li{
            width: 16%;
            vertical-align: top;
            padding: 20px 40px;
            padding-bottom: 0;
            &:first-child{
                padding-left: 0;
            }
            @media(max-width:1366px){
                padding: 20px 30px;
            }
            @media(max-width:1199px){
                padding: 20px 20px;
            }
            @media(max-width:991px){
              width: 33%;
              margin-right: 0;
            }
            @media(max-width:640px){
                width: 50%;
              }
            &:hover{
                .icon_block{
                    &:before{
                        width: 110px;
                        background: $green_color;
                    }
                    .woo_com{
                        background-position: -130px -37px;              
                }
                .logo_design{
                    background-position:-130px  -122px;
                }
                .seo{
                    background-position: -130px -207px;
                }
                .wordpress{
                    background-position: -130px  -292px;
                }
                .ppc{
                    background-position: -130px  -377px;
                }
                .shopify{
                    background-position: -130px  -462px;
                    }
                }
             }
            .icon_block{
                position: relative;
                padding-bottom: 20px;
                &::before{
                    height: 2px;
                    width: 60px;
                    background-color: #C8C8C8;
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    transition: all 0.4s ease-in-out;
                }
                .img_sec {
                    width: 64px;
                    height: 52px;
                    text-align: center;
                    background-image: url('../../../public/public/services-icons.png');
                    margin: auto;
                    transition: all 0.4s ease-in-out;
                }
                .woo_com{
                    background-position: -32px -37px;              
                }
                .logo_design{
                    background-position: -32px -122px;
                }
                .seo{
                    background-position: -32px -207px;
                }
                .wordpress{
                    background-position: -32px -292px;
                }
                .ppc{
                    background-position: -32px -377px;
                }
                .shopify{
                    background-position: -32px -462px;
                }
                .inner-wrap{
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
										span {
											position: static !important;
										}
										img {
											max-width: 60px !important;
											max-height: 60px !important;
											width: auto !important;
											height: auto !important;
											min-width: auto !important;
											min-height: auto !important;
											position: static !important;
										}
                }           
            }
            .service_name{
                margin-top: 20px;
                p{
                    color: $grey_color;
                    margin-bottom: 0;
                    font-size: 20px;
                    @media(max-width:1366px){
                        font-size: 18px;
                    }
                    @media(max-width:640px){
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

// Popular services section code ends here



// vendor design code starts here

.vendor-section{
   background: #fff6ed;
   .main-heading{
       margin-bottom: 30px;
   }
   .description-text{
       margin-bottom: 10px;
   }
   .primary-btn{
       margin-top: 10px;
   }
   img{
       @media(max-width:767px){
           margin-bottom: 30px;
       }
   }
   .row{
       align-items: center;
   }
   .second-part{
       margin-top: 30px;
   }
	 .vendor-section-img {
		span {
			position: static !important;
		}
		img {
			position: static !important;
			width: auto !important;
			height: auto !important;
			min-height: auto !important;
			min-width: auto !important;
			margin: 0 !important;
			@media (max-width: 767px)  {
				margin-bottom: 30px !important;
			}
		}
	 }
}


// vendor design code ends here



// Home tehnology section starts here

.home-technology-section{
    .description-text-sm{
        max-width: 1150px;
        width: 100%;
        margin: 20px auto;
    }
    .main-heading-sm{
        color: $grey_color;
        font-family: $secondary-font;
        font-size: 30px;
        font-weight: 800;
    }
    .technology_listing{
        .slick-slider{
            @media(max-width:480px){
            padding: 0px 15px;
            background: #1a0104;
            }
        }
        margin-top: 60px;
        .slick-arrow {
            z-index: 1;
            &::before{
                opacity: 1;
                font-size: 35px;
                font-family: "Font Awesome 5 Pro";
            }
        }
        .slick-prev{
            left: 12px;
            height: 35px;
            &::before{
                content: '\f053';            
            }
            @media(max-width:480px){
                left: 5px;  
            }
        }
        .slick-next{
            right: 12px;
            height: 35px;
            &::before{
                content: '\f054';
            }
            @media(max-width:480px){
                right: 5px;  
            }
        }
        .slick-list{
           .slick-slide {
               .inner_wrap{
                   position: relative;
                //    height: 188px;
                   &:hover{
                       .img_section{
                           &::after{
                               height: 100%;
                           }
                       }
                   }
                   .img_section{
                       position: relative;
                       height: 100%;
                       background: #fff;
                       display: inline-block;
                       vertical-align: middle;
                       margin: auto;
                       max-width: 100%;
                       overflow: hidden;
                       width:100%;
                       &::before{
                           content: '';
                           top: 0;
                           width: 100%;
                           height: 100%;
                           background: $black_color;
                           position: absolute;
                           left: 0;
                           transition: all 0.4s ease-in-out;
                           opacity: 0.7;
                           z-index: 1;
                           @media(max-width: 767px){
                               opacity: 0.6;
                           }
                       }
                       &::after{
                        content: '';
                        top: 0;
                        width: 100%;
                        height: 0;
                        background: $blue_color;
                        position: absolute;
                        left: 0;
                        transition: all 0.4s ease-in-out;
                        opacity: 0.9;
                        z-index: 1;
                       }
                       span{
                        display: block;
                        height: 0;
                        overflow: hidden;
                        padding-bottom: 100%!important;
                        position: relative;
                        z-index: 0;
                            img{
                                bottom: 0;
                                display: block;
                                height: auto;
                                left: 0;
                                margin: auto;
                                max-width: 100%;
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: auto;
                                border-radius: 0;
                                border: 0;
                            }
                       }
                   }
                   .tech_name_section{
                       position: absolute;
                       top: 50%;
                       left: 0;
                       right: 0;
                       width: 100%;
                       margin: auto;
                       text-align: center;
                       z-index: 1;
                       transform: translate(0,-50%);
                       padding: 0 10px;
                       p{
                        color: $white_color;
                        font-weight: 500;
                       }
                   }
               }
           } 
        }
    }
}


// Home tehnology section ends here


// find a talent section code starts here


.find-talent-section{
   background: #EAF7FF;
   .inner-wrap{
       ul{
        margin-top: 70px;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li{
            list-style: none;
            position: relative;
            width: 33%;
            font-size: 25px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            @media(max-width:991px){
                font-size: 18px;
            }
            @media(max-width:767px){
             width: 50%;
             margin-bottom: 20px;
             justify-content: left;
            }
            @media(max-width:640px){
                width: 100%;
               }
            span{
                background: $green_color;
                border-radius: 50%;
                padding: 5px;
                width: 50px;
                height: 50px;
                justify-content: center;
                margin-right: 15px;
                display: flex;
                align-items: center;
                i{
                    color: $white_color;
                    font-size: 20px;
                    font-weight: 900;
                }
            }
        }
    }
   }
}

// find a talent section code ends here


// Home choose tab section starts here


.home-choose-section{
    .row{
        align-items: center;
    }
    .tab-col_left{
        padding-right: 32px;
        @media(max-width:991px){
            padding-right: 0;
            margin-bottom: 40px;
        }
            .description-text-sm{
                margin:25px 0;
            }
            .nav.nav-pills{
                margin-bottom: 40px;
                .nav-item{
                    border-bottom: 1px solid  #E4E4E4;
                    &:last-child{
                        border: 0;
                    }
                    .nav-link{
                        padding: 23px 30px;
                        position: relative;
                        padding-right: 50px;
                        @media(max-width:1366px){
                            padding: 18px 24px;
                        }
                        &:after{
                            content: '\f178';
                            font-family: "Font Awesome 5 Pro";
                            font-weight: 500;
                            position: absolute;
                            right: 40px;
                            top: 50%;
                            font-size: 22px;
                            transform: translate(-50%, -50%);
                            color: $grey_color;
                            opacity: 0;
                            transition: all 0.4s ease-in-out;
                        }
                        h5{
                            color: #3C3C3C;
                            font-size: 20px;
                            margin: 0;
                            font-weight: 400;
                            @media(max-width:1366px){
                                font-size: 18px;
                            }
                        }
                    }
                    .active.nav-link{
                        background-color: #ECBE7A;
                        &::after{
                            transform: translate(-50%, -50%);
                            opacity: 1;
                            right: 20px;
                        }
                    }
                }
            }
    }
    .tab-content{
        padding-left: 20px;
        .tab-inner-wrap{
            @media(max-width:1199px){
                position: relative;
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    content: '';
                    background: rgba(0,0,0,0.6);
                    z-index: 1;
                }
            }
             @media(min-width:1200px){
                position: relative;
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    content: '';
                    background: rgba(0,0,0,0.4);
                    z-index: 1;
                }
            }
        }
        @media(max-width:991px){
            padding-left: 0;
        }
        .tab-pane{
            /*background: url('../../images/public/choose-tab1.webp');*/
            background-size: cover;
            min-height: 634px;
            position: relative;
            border-radius: 5px;
            @media(max-width:991px){
               min-height: auto;
               background-position: top;
            }
           .inner-content{
                position: absolute;
                bottom: 0;
                padding: 50px;
                z-index: 2;
                @media(max-width:1199px){
                    position: relative;
                    // background: rgba(0,0,0,0.6);
                    z-index: 2;
                 }
                @media(max-width:991px){
                //    position: initial;
                   padding: 30px;
                }
                @media(max-width:480px){
                //   background: #1a1a1a61;
                }
                .tab_content_heading{
                    font-size: 20px;
                    color: $white_color;
                    margin: 20px 0;
                }
                .description-text-sm{
                    color: $white_color;
                    font-weight: 500;
                }
           }
        }
    }
}
.ready-start-wrap{
    .main-heading{
        margin-bottom: 30px;
    }
}
// Home choose tab section ends here


// Home yellow section starts here


.home-yellow_section{
    background: url('../../../public/public/yellow-bg.jpg');
    background-size: cover;
    background-position: top;
    @media(max-width:767px){
        text-align: center;
    }
    .row{
        align-items: center;
    }
    .description-text-sm{
        margin: 20px 0;
    }
    .sec-1-yellow-right{
        padding-left: 50px;
        @media(max-width:767px){
            padding-left: 0;
            margin-top: 40px;
        }
    }
    .sec-1-yellow-left{
        padding-right: 50px;
        @media(max-width:767px){
            padding-right: 0;
            margin-bottom: 40px;
        }
    }
		.text-right {
			span {
				position: static !important;
			}
			img {
				position: static !important;
				width: auto !important;
				height: auto !important;
				min-height: auto !important;
				min-width: auto !important;
				margin: 0 0 0 auto !important;
			}
		}
}

// Home yellow section ends here



// Blogs section code starts here
.home-blogs{
    .container-xl{
        @media(max-width:480px){
            padding: 0px 0px;
        }
        .home-blog_section{
            padding: 40px 0px;
        }
    }

}

.home-blog_section{
    .blog_listing{
        margin:60px 0;
        @media(max-width:480px){
            margin:40px 0; 
        }
        .col-md-6{
            @media(max-width:991px){
                margin-bottom: 20px;
            }
        }
        .blogs-home_section{
						background: url('../../../public/public/home-blog.png');
            box-shadow: 0 8px 16px 2px rgba(0,0,0,0.1);
            position: relative;
            padding: 147px 40px 30px;
            overflow: hidden;
            border-radius: 5px;
            background-size: cover;
            height: 100%;
            @media(max-width:480px){
                padding: 120px 30px 30px;    
            }
            &::before{
                background-color: #EAF7FF;
                height: 100%;
                width: 100%;
                content: '';
                // transform: scale(1);
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                border-radius: 5px;
                top: 0;
                transition: all 0.4s ease-in-out;
            }
            &::after{
                background: linear-gradient(180deg, #ffffff00 52%, #404040 95%);
                height: 100%;
                width: 100%;
                content: '';
                // transform: scale(0);
                position: absolute;
                opacity: 0;
                z-index: 1;
                left: 0;
                right: 0;
                border-radius: 5px;
                top: 0;
                transition: all 0.4s ease-in-out;
            }
            &:hover{           
                &::before{
                    height: 0;
                    // transform: scale(0);
                }
                &::after{
                    // transform: scale(1);
                    opacity: 1;
                }
                .content_section{
                    .blog_name,.description-text-sm{
                        color: $white_color;
                    }
                }
            }
            .b_category {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                min-width: 150px;
                font-size: 14px;
                margin-bottom: 0;
                padding: 9px 10px;
                text-align: center;
                color: $white_color;
                background-color: #ECBE7A;
                border-bottom-right-radius: 5px;
            }
            .b_category.Design{
                color: #3C3C3C;
            }
            .b_category.Web_Development{
                background-color: $blue_color;
            }
            .b_category.IT_Services{
                background-color: $green_color;
            }
            .b_category.Web_Design{
                background-color: $grey_color;
            }
            .b_category.SEO{
                color: $grey_color;
                background-color: #a8efb1;
            }
            .b_category.Ecommerce{
                color: $grey_color;
                background-color: #eaf7ff;
            }
            .content_section{
                position: relative;
                z-index: 2;
                .blog_name{
                    color: #3C3C3C;
                    font-size: 20px;
                    margin-bottom: 20px;
                    min-height: 72px;
                }
                .description-text-sm{
                    min-height: 120px;
                    a{
                        color: $green_color;
                        text-decoration: none;
                        display: inline-block;
                        font-weight: 500;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}



// Blogs section code ends here

