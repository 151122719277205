// service request design code starts here


.service_req_tabs_section{
    margin-top: 30px;
    .nav-pills{
        border-bottom: 1px solid  #e4e4e4;
        padding: 0 20px;
        @media(max-width:767px){
            flex-direction: column !important;
            padding: 0;
        }
        .nav-item{
            .nav-link{
                background-color: $white_color;
                border-radius: 0;
                padding: 15px 20px;
                transition: all 0.4s ease-in-out;
                border:0;
                position: relative;
                @media(max-width:767px){
                    border-radius: 0px;
                    border:1px solid #e4e4e4;
                    border-bottom: 0px;
                }
                &:focus-visible{
                    outline: 0;
                }
                &::before{
                    content: '';
                    background:#ecbe7a;
                    left: 0;
                    width: 0;
                    height: 3px;
                    bottom: 0;
                    position: absolute;
                    transition: all 0.4s ease-in-out;
                    @media(max-width:767px){
                      display: none;
                    }
                }
                &:hover{
                    &::before{
                        width: 100%;
                    }
                }
                h5{
                    font-size: 16px;
                    color: $grey_color;
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }
            .nav-link.active{
                @media(max-width:767px){
                   background:#ecbe7a;
                }
                &::before{
                    width: 100%;
                }
            }
        }
    }
    .tab-content{
        margin-top: 40px;
        #service_req_tabs-tabpane-tab1,.tab-pane {
            .request_list{
                padding-left: 0;
                margin-bottom: 50px;
                // li[aria-expanded="true"]{
                //      position: absolute;
                //      left: 0;
                //      right: 0;
                //      top: 100px;
                //      margin: auto;
                //      width: 100%;
                //      z-index: 4;
                //      background: $white_color;
                //      box-shadow: 0 0 16px 2px #0000005e;
                // }
                li[aria-expanded="false"]{
                    cursor: pointer;
                }
                li{
                    list-style: none;
                    border: 1px solid #ccc;
                    margin-bottom: 20px;
                    border-radius: 5px;
                    padding: 20px;
                    
                    // cursor: pointer;               
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .service_name{
                        display: inline-block;
                        margin-bottom: 5px;
                        width: 100%;
                        position: relative;
                        z-index: -1;
                        .reason{         
                            display: inline-block;
                            font-weight: 500;
                            color: $black_color;
                            font-size: 16px;
                            b{
                                color: Red;
                                font-size: 18px;
                            }
                        }
                    }
                    .time-ago{
                        color: #ababab;
                        display: inline-block;
                        position: relative;
                        z-index: -1;
                    }
                    .collapse_section {
                       border-top: 1px solid #ccc;
                       padding-top: 20px;
                       margin-top: 20px;
                       .row{            
                        @media(min-width:768px){        
                            white-space: nowrap;
                            overflow-x: auto;
                            flex-direction: row;
                            flex-wrap: nowrap;
                        }
                       &::-webkit-scrollbar {
                           width: 5px;
                           height: 5px;
                       }
                   
                       /* Track */
                       &::-webkit-scrollbar-track {
                           box-shadow: inset 0 0 5px grey;
                           border-radius: 5px;
                       }
                   
                       /* Handle */
                       &::-webkit-scrollbar-thumb {
                           background: $blue_color;
                           border-radius: 5px;
                       }
                   
                       /* Handle on hover */
                       &::-webkit-scrollbar-thumb:hover {
                           background: $blue_color;
                       }
                       .col-md-4{
                           @media(min-width:768px) and (max-width:1366px){
                            min-width: 350px;
                           }                          
                       }
                    }
                       .sub-section-heading{
                        border-bottom: 1px solid #ccc;
                        padding-bottom: 10px;
                        background: $blue_color;
                        color:$white_color;
                        padding: 10px;
                        margin: 0;
                        font-weight: 500;
                        min-height: 69px;
                        display: flex;
                        align-items: center;
                        white-space: initial;
                        margin-bottom: 20px;
                       }
                       .convo_status_wrap{
                           border-bottom: 1px solid #ccc;
                           margin-bottom: 15px;
                           padding-bottom: 20px;
                           &:last-child{
                               border-bottom: 0;
                           }
                       }
                       .convo_status{                 
                        all: unset;
                        white-space: initial;
                        .INTERESTED{
                            background-color: rgba(8, 197 ,100,1);
                            color: $green_color;     
                        }
                        .lead_status{
                            // padding: 6px 10px;
                            color: $blue_color;
                            // background: $blue_color;
                            min-width: 120px;
                            max-width: 120px;
                            margin-top: 5px;
                            display: block;
                            text-align: center;
                            width: 100%;
                            font-style: italic;
                            font-weight: 700;
                            font-size: 14px;
                            border-radius: 5px;
                            padding: 5px;
                       }
                       .INTERESTED{
                        color: #ECBE7A;
                        // background: #ECBE7A;
                       }  
                       .HIRED{
                           color: $white_color;
                           background: $blue_color;
                       }
                       .INTERESTED.NOT,.HIRED.NOT{
                        background-color: red;
                        color: $white_color;
                        // background: red;
                       }      
                       .Waiting{
                           color: $black_color;
                           background: #ECBE7A;
                           max-width: max-content;
                       }   
                       }
                       .msg_section{
                           margin-bottom: 30px;
                           .description-text-heading{
                               color: $grey_color;
                               border-bottom: 1px solid #ccc;
                               padding-bottom: 10px;
                               font-weight: 500;
                               margin-bottom: 20px;
                           }
                           .ques_listing{
                             height: 390px;
                            overflow-y: auto;
                            padding-left: 0;
                            &::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                            }
                        
                            /* Track */
                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 5px grey;
                                border-radius: 5px;
                            }
                        
                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: $blue_color;
                                border-radius: 5px;
                            }
                        
                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: $blue_color;
                            }
                               li{
                                   .description-text{
                                       font-weight:400;
                                       color: $blue_color;
                                       font-size: 18px;
                                       white-space: initial;
                                   }
                                   .description-text-sm{
                                    white-space: initial;
                                    b{
                                        font-weight: 600;
                                    }
                                   }
                               }
                           }
                       }
                       .msg_send_wrap{
                           margin-bottom: 30px;
                           .primary-btn{
                            text-transform: capitalize;
                           }
                       }
                       .contact_info_block{
                           .map_section{
                               position: relative;
                               height: 300px;
                               width: 100%;
                           }
                           .contact-info{
                               margin: 20px 0;
                               .description-text-sm{
                                   b{
                                       font-weight: 600;
                                   }
                               }
                           }
                           .btns-section{
                               .primary-btn{
                                   margin-right: 20px;
                                   margin-bottom: 20px;
                                   vertical-align: top;
                                   padding: 14px 15px !important;
                               }
                               .second-btn{
                                vertical-align: top;
                               }
                           }
                       }
                    }
                    .agency_detail_block{
                        .agency_detail_wrapper{
                            &:before{
                                display: none
                            }
                            .description-text-sm{
                                background: #EAF7FF;
                                padding: 5px;
                                text-align: justify;
                                line-height: 1.5;
                            }
                        }
                        .inner_wrapper{   
                            height:400px;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                            }
                        
                            /* Track */
                            &::-webkit-scrollbar-track {
                                box-shadow: inset 0 0 5px grey;
                                border-radius: 5px;
                            }
                        
                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: $blue_color;
                                border-radius: 5px;
                            }
                        
                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: $blue_color;
                            }
                            .agency-img_section{
                                .description-text-sm{
                                    font-weight: 500;
                                    margin-bottom: 20px;
                                    padding-bottom: 10px;
                                    border-bottom: 1px solid #ccc;
                                }
                                img{
                                    margin-bottom: 20px;
                                }
                            }
                            .agency-cover_section{
                                position: relative;
                                .cover_img_wrap{
                                    position: relative;
                                    height: 200px;
                                    &::before{
                                        background:rgba(0 ,0, 0 , 0.5);
                                        content: '';
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }
                                    img{
                                        // height: 300px;
                                        height: 100%;
                                        width: 100%;
                                        object-fit: cover;
                                        object-position: center;
                                    }
                                }
                                .logo_section{ 
                                    // position: absolute;
                                    // bottom: 0;
                                    // left: 0;
                                    // width: 100%;
                                    // padding:5px  20px;
                                    position: relative;
                                    background: #fff;
                                    max-width: 175px;
                                    display: flex;
                                    flex-flow: column;
                                    justify-content: center;
                                    align-items: center;
                                    margin-top: -55px;
                                    padding: 10px;
                                    margin-left: 15px;
                                    box-shadow: 0px 8px 8px #0000001a;
                                    border-radius: 5px;
                                    white-space: normal;
                                    @media(max-width:1100px){
                                        margin-top: 0px;
                                        margin-left: 0;
                                        max-width: 100%;
                                        margin: auto;
                                        border: 1px solid #f7f7f7;
                                    }
                                    img{
                                        height: 60px;
                                        width: 60px;
                                        border-radius: 5px;
                                        display: inline-block;
                                        vertical-align: middle;
                                    }
                                    .description-text-sm{
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-left: 0px;
                                        color: $grey_color;
                                        font-weight: 500;
                                        font-size: 18px;
                                        text-transform: capitalize;
                                        margin-top: 5px;
                                        text-align: center;
                                        line-height: 1.5;
                                        @media(max-width:1440px){
                                            font-size: 16px;
                                        }
                                    }
                                    .p_img_wrap{
                                        background: #fff;
                                        display: block;
                                        margin: auto;
                                        max-width: 100%;
                                        overflow: hidden;
                                        width: 100px;
                                           span{
                                            display: block;
                                            height: 0;
                                            overflow: hidden;
                                            padding-bottom: 100%!important;
                                            position: relative;
                                            z-index: 0;
                                                img{
                                                    bottom: 0;
                                                    display: block;
                                                    height: auto;
                                                    left: 0;
                                                    margin: auto;
                                                    max-width: 100%;
                                                    position: absolute;
                                                    right: 0;
                                                    top: 0;
                                                    border-radius: 0;
                                                    width: 100%;
                                                }
                                           }
                                       }
                                }
                            }
                            .agency_detail_wrapper{
                                margin-top: 20px;
                                .description-text-sm{
                                    white-space: initial;
                                    padding: 15px 20px;
                                    b{
                                        font-weight: 600;
                                    }
                                    .read-more-less--more,.read-more-less--less{
                                        color: $green_color;
                                        font-weight: 500;
                                        &:hover{
                                            color: $blue_color;
                                        }
                                      }
                                }
                                .see_full_p{
                                    color: $green_color;
                                    font-weight: 500;
                                    margin-top: 10px;
                                    display: block;
                                    padding-bottom: 15px;
                                    text-align: center;
                                    margin-bottom: 10px;
                                    border-bottom: 1px solid #ccc;
                                    &:hover{
                                        color: #004dc0;
                                    }
                                }
                                .tags-wrap{
                                    margin-top: 20px;
                                    .tag-listing{
                                        display: flex;
                                        flex-wrap: wrap;
                                        div{
                                            padding: 4px 20px;
                                            border: 1px solid #ccc;
                                            border-radius: 5px;
                                            display: inline-block;
                                            vertical-align: top;
                                            margin-right: 5px;
                                            margin-bottom: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .msg-box{
                        .left-msg{
                            border: 1px solid #00000017;
							border-radius: 15px;
						    padding: 10px 20px;
							background-color:rgba(12 ,253, 130 , 0.19);
							margin: 10px 20px 10px 0px;
                            p{
                                margin-bottom: 0;
                                white-space: initial;
                                word-break: break-word;
                            }
                        }
                        .right-msg{
                            border: 1px solid #00000017;
                            border-radius: 15px;
                            padding: 10px 20px;
                            background-color: rgba(253, 12 ,112 ,0.19);
                            margin: 10px 20% 10px 0px;
                            p{
                                margin-bottom: 0;
                                word-break: break-word;
                                white-space: initial;
                            }
                        }
                        .send{
                            border: 1px solid #00000017;
                            border-radius: 5px;
                            padding: 10px 20px;
                            background-color: #0c6efd30;
                            margin: 10px 20% 10px 0px;
                            p{
                                margin-bottom: 0;
                                white-space: initial;
                                word-break: break-word;
                            }
                        }
                        .receive{
                            border: 1px solid #00000017;
    							border-radius: 15px;
    						    padding: 10px 20px;
    							background-color: white;
    							margin: 10px 0px 10px 20px;
                                p{
                                    margin-bottom: 0;
                                    white-space: initial;
                                    word-break: break-word;
                                }
                        }
                    }
                }
            }
        }
    }
}


// service request design code ends here


.service_toggle{
    display: none;
    cursor: pointer;
    font-size: 20px;
    margin-left: 20px;
}
.service_request_active{
    width: auto;
    background: transparent;
    .sidebar_nav{
        background: transparent;
        padding:8px  0;
    }
    .service_toggle{
        display: block;
        @media(max-width:991px){
            display: none;
        }
    }
    .navbar-collapse{
        display: block !important;
            min-height: 100%;
            width: 300px;
            height: 100%;
            top: 0;
            left: -300px;
            position: fixed;
            background: $white_color;
            overflow-y: auto;
            z-index: 4;
            padding-top: 60px;
            padding-bottom: 30px;
            box-shadow: 0 8px 16px 2px rgba(0, 0 ,0 ,0.1);
            transition: all .4s ease-in-out;
        }
}
.right_col_user.service_requestpage_active{
width: 100%;
margin-top: 50px;
@media(max-width:1366px){
    margin-top: 16px;
}
}


// 
.status_wrap_set {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .toogle-message{
        padding-left: 5px;
        .btn-group{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            .btn-primary{
                border-radius: 5px;
                border: 0px;
                width: 100%;
                padding: 5px 15px !important;
                &:after{
                    display: none;
                }
                &:before{
                    display: none
                }
                
            }
            .service-btn{
                &:hover{
                    background-color: $blue_color;
                }
                &:focus{
                    background-color: $green_color;
                }
            }
            .message-btn{
                    margin-bottom: 5px;
                    cursor: pointer;
                    background-image: url('../../../public/public/envelope.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 16px;
                &:hover{
                    background-color: $green_color;
                }
                &:focus{
                    background-color: $blue_color;
                }
                i{
                    
                }
                span{
                    position: relative;
                    &:after{
                        content: "\f0e0";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 900;
                        display: none;

                    }
                }
            }
         }
    }
}
.tooltip {
.tooltip-inner{
    background-color: #606060 !important;
    color: #fff !important;
}
.arrow{
       &::before{
        border-top-color:#606060  !important;
       }
}
}