@import 'variables';


// Signup process 1 page code starts here

.signup_1_section{
    background-image: url('../../../public/public/signup1.jpg');
    background-size: cover;
    padding: 110px 0;
    // min-height: 90vh;
    @media(max-width:1199px){
        background-position: right;
    }
    @media(max-width:767px){
       padding: 40px 0px;
    }
    .row{
        align-items: center;
        @media(max-width:767px){
       flex-direction: column-reverse;
        } 
    }
    .form_Section{
        padding: 30px 40px;
        background: $white_color;
        border-radius: 5px;
        box-shadow: 0px 3px 19px 0px rgba(0,0,0,0.2);
        max-width: 464px;
        width: 100%;
        @media(max-width:1199px){
          max-width: 100%;
        }
        @media(max-width:767px){
            margin-top: 40px;
            padding: 40px 30px;
        }
        .primary-btn{
            width: 100%;
        }
        .already_text{
            font-size: 14px;
            color: #3c3c3c;
            margin-top: 20px;
            font-weight: 400;
            margin-bottom: 0;
            a{
                color: #3c3c3c;
                display: inline-block;
                &:hover{
                    color: $green_color;
                }
            }
        }
        .account_info_section{
            margin-top: 38px;
            @media(max-width:767px){
              margin-top: 30px;
            }
        }
        .row{
            align-items: flex-start;
        }
    }
    .right_col_section{
        padding-left: 60px;
        @media(max-width:767px){
          padding-left: 0;
          }
        .main-heading-lg{
            margin-bottom:20px;
        }
        .description-text{
            font-weight: 400;
        }
    }
}
.signup_title{
    font-size: 30px;
    color: $grey_color;
    font-weight: 800;
    font-family: $secondary-font;
    margin-top: 15px;
    margin-bottom: 25px;
    @media(max-width:1366px){
        font-size:24px;
    }
}
.form-group{
    margin-bottom: 20px;
}
.input_text{
    border: 1px solid #A4A4A4;
    color: $black_color;
    font-size: 14px;
    padding: 14px 15px;
    background-color: $white_color !important;
    width: 100%;
    border-radius: 5px;
    min-height: 51px;
    &:focus,&:hover,&:focus-visible{
        box-shadow: none !important;
        background-color: $white_color !important;
        border-color: $green_color !important;
        color: $black_color !important;
        outline: 0 !important;
    }
    &::placeholder{
        color: #969696;
    }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.dropdown_field{
    .input_text{
        text-align: left;
        position: relative;
        &:after{
            right: 10px;
            position: absolute;
        }
    }
    .dropdown-menu{
        width: 100%;
    }
}
label,.label{
    margin-bottom: 10px;
    display: block;
    color: $black_color;
}
.textarea-field{
    min-height: 196px;
    border: 1px solid #A4A4A4;
    color: $black_color;
    font-size: 14px;margin-bottom: 20px;
    padding: 14px 15px;
    background-color: $white_color !important;
    width: 100%;
    border-radius: 5px;
    &:focus,&:hover,&:focus-visible{
        box-shadow: none !important;
        background-color: $white_color !important;
        border-color: $green_color !important;
        color: $black_color !important;
        outline: 0 !important;
    }
    &::placeholder{
        color: #969696;
    }
}
// Signup process 1 page code ends here



// Signup process 2 page code starts here

.additional_info_section{
    background:#eaf7ff;
    padding:50px;
    margin-top: 100px;
    margin-bottom: 100px;
    border-radius: 5px;
    max-width: 1416px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media(max-width:767px){
     padding: 40px 30px;
     margin-bottom: 60px;
     margin-top: 60px;
     }
    .signup_title{
        margin-bottom: 18px;
        margin-top: 0;
    }
    .description-text{
        margin-bottom: 12px;
        font-weight: 400;
    }
    .upload-field-customized{
        position: relative;
        display: inline-block;
        margin-right: 62px;
        vertical-align: top;
        margin-bottom: 30px;
        @media(max-width:767px){
           margin-right: 0;
           display: block;
        }
        @media(max-width:567px){
          text-align: center;
         }
        input[type="file"]{
          position: absolute;
          width: 78px;
          height: 78px;
          opacity: 0;
          cursor: pointer;
          left: 0px;
          top: 0px;
          z-index: 10;
          @media(max-width:767px){
           width: 50px;
           height: 50px;
         }
         @media(max-width:567px){
            left: 50%;
            transform: translate(-50%, 0%);
          }
        }
        
        .add_file_icon{
          text-align: center;
          width: 78px;
          display: inline-block;
          vertical-align: middle;
          height: 78px;
          background:$white_color;
          cursor: pointer;
          border-radius: 50%;
          border: 1px solid #A4A4A4;
          line-height: 88px;     
          @media(max-width:767px){
            width: 50px;
            height: 50px;
            line-height: 54px;
          }
          i{
              font-size: 30px;
              font-weight: 300;
              color: #A4A4A4;
              @media(max-width:767px){
                 font-size: 20px;
              }
          }   
        }
        .description-text{
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0;
          padding-left: 15px;
          @media(max-width:567px){
           padding-left: 0;
           display: block;
           margin-top: 10px;
           }
           .file_name{
               display: block;
               font-size: 16px;
           }
        }      
      }
      .textarea-field{
        margin-bottom: 0;
        }
      .btn_section{
          .second-btn{
              margin-right: 20px;
              max-width: 130px;
              vertical-align: top;
              width: 100%;
              @media(max-width:567px){
                max-width: 100%;
                margin-right: 0;
                margin-bottom: 25px;
              }
          }
      }
      .primary-btn{
          max-width: 130px;
          vertical-align: top;
          width: 100%;
          @media(max-width:567px){
            max-width: 100%;
          }
      }
}


// Signup process 2 page code ends here


// Signup process 3 page code starts here

.signup_step3_section{
    background-image: url('../../../public/public/signup3.jpg');
    background-size: cover;
    min-height:92vh;
    display: flex;
    align-items: center;
    @media(max-width:1199px){
        background-position: right;
    }
    .content_inner_col{
        max-width: 638px;
        width: 100%;
        margin-left: auto;
    }
    .main-heading-lg{
        margin-bottom:25px;
    }
    .description-text{
        font-weight: 400;
    }
    .review_block{
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .primary-btn,.second-btn{
      max-width: 220px;
      width: 100%;
      text-align: center;
    }
}


// Signup process 3 page code ends here