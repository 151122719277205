.dashboard_title.convo_title{
   margin-top: 40px;
}
 .dashboard_loader {
   position: relative;
   #spinner{
        height: 100%;
        width: 100%;
        background-color: #fbfbfb;
        position: absolute;
        z-index: 9;
        display: block;
        top: 0;
        left: 0;
        .lds-spinner{ 
            div{
                &::after{
                background: #00cc83;
            }
        }
    }
    }
}

.leads_section_listing{
    ul{
        padding-left: 0;
        margin-bottom: 0;
        li{
           list-style: none;
           border: 1px solid #a4a4a4;
           border-radius: 5px;
           padding: 20px;
           margin-bottom: 20px;
            background: #004dc00f;
            a{
                display: inline-block;
                width: 100%;
                &:hover{
                    .user_name{
                        color: $green_color;
                    }
                }
            }

           .left_col_wrapper{
               width: 50%;
               display: inline-block;
               vertical-align: top;
               @media(max-width:767px){
                width: 100%;
                }
               .user_name{
                   color: $grey_color;
                   font-size: 20px;
                   font-weight: 500;
                   margin-bottom: 10px;
                   @media(max-width:1366px){
                       font-size: 18px;
                   }
               }   
               .description-text-sm{
                   font-size: 13px;
                   line-height: initial;
               }
               .lead_status{
                    // padding: 6px 10px;
                    color: $blue_color;
                    // background: $blue_color;
                    display: block;
                    font-size: 14px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    width: 100%;
                    font-style: italic;
                    font-weight: 700;
                    border-radius: 5px;
                    @media(max-width:1366px){
                        font-size: 12px;
                    }
                    .status{
                        font-size: 14px;
                        margin-bottom: 0;
                        text-align: center;
                        color: $grey_color;
                        font-weight: 400;
                        font-weight: 700;
                        border-radius: 5px;
                        vertical-align: top;
                        display: inline-block;
                        @media(max-width:1366px){
                            font-size: 12px;
                        }
                       } 
               }
               .INTERESTED{
                color: #ECBE7A;
                // background: #ECBE7A;
               }  
               .INTERESTED.NOT{
                color: red;
                // background: red;
               }      
               .Waiting{
                   color: $black_color;
                   background: #ECBE7A;
                   max-width: max-content;
               }   
           }
           .right_col_wrapper{
            width: 50%;
            display: inline-block;
            text-align: right;
            vertical-align: top;
            @media(max-width:767px){
                width: 100%;
                text-align: left;
                margin-top: 20px;
            }
               .type{
                font-size: 14px;
                margin-bottom: 0;
                padding: 9px 10px;
                text-align: center;
                color: $white_color;
                vertical-align: top;
                font-weight: 400;
                border-radius: 5px;
                display: inline-block;
                background-color: $blue_color;
                margin-bottom: 10px;
                @media(max-width:1366px){
                    font-size: 12px;
                }
               }                    
           }
        //    &:nth-child(odd){
        //        .right_col_wrapper{
        //            .status{
        //             background-color: $green_color;
        //            }
        //        }
        //    }
        }
    }
}


// single lead section design code starts here

.single-lead-page{
    .lead_detail_sections{
        margin-top: 50px;
        margin-bottom: 50px;
        .row{   
        white-space: nowrap;
        overflow-x: auto;
        flex-direction: row;
        flex-wrap: nowrap;       
        @media(max-width:767px){
            white-space: initial;
            flex-wrap: wrap;
        }
        &::-webkit-scrollbar {
            width: 5px; 
            height: 5px;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 5px;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $blue_color;
            border-radius: 5px;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $blue_color;
        }
        .col-md-4{
            width: 450px;
            @media(max-width:767px){
               width: 100%;
               margin-bottom: 30px;
            }
        }
        }
        .msg_wrapper_box{
            height: 700px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
        
            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 5px;
            }
        
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $blue_color;
                border-radius: 5px;
            }
        
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: $blue_color;
            }
        }
        .map-section{
            position: relative;
            height: 300px;
        }
        .profile_section{
            margin-bottom: 30px; 
            .img_section{
                width: 100px;
                border-radius: 50%;
                display: inline-block;
                vertical-align: top;
                img{
                    border-radius: 50%; 
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border: 1px solid #ccc;
                }
            }
            .p_img_wrap{
                background: #fff;
                border-radius: 50%;
                display:inline-block;
                margin: auto;
                max-width: 100%;
                overflow: hidden;
                width: 90px;
                border: 1px solid #ccc;
                   span{
                    display: block;
                    height: 0;
                    overflow: hidden;
                    padding-bottom: 100%!important;
                    position: relative;
                    z-index: 0;
                    border-radius: 50%;
                        img{
                            bottom: 0 !important;
                            display: block !important;
                            height: auto !important;
                            left: 0 !important;
                            margin: auto !important;
                            max-width: 100% !important;
                            position: absolute !important;
                            right: 0 !important;
                            top: 0 !important;
                            width: 100% !important;
                            border-radius: 0 !important;
                            border: 0 !important;
														min-width: auto !important;
														min-height: auto !important;
														max-height: none !important;
                        }
                   }
               }
            .content_wrap{
                width: calc(100% - 100px);
                display: inline-block;
                vertical-align: top;
                padding-left: 20px;
                .name{
                    font-size: 18px;
                    margin-bottom: 6px;
                    font-weight: 500;
                }
                .lead{
                    font-size: 14px;
                    margin-bottom: 0;
                    padding: 9px 10px;
                    text-align: center;
                    color: #fff;
                    vertical-align: top;
                    font-weight: 400;
                    border-radius: 5px;
                    display: inline-block;
                    background-color: #004dc0;
                    margin-bottom: 10px;
                    margin-top: 10px;
                }
            }
        }
        .msg_section{
            margin-bottom: 30px;
            .description-text-heading{
                color: $grey_color;
                border-bottom: 1px solid #ccc;
                padding-bottom: 10px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            .ques_listing{
              height:700px;
             overflow-y: auto;
             padding-left: 0;
             margin-bottom: 0;           
             &::-webkit-scrollbar {
                 width: 5px;
                 height: 5px;
             }
         
             /* Track */
             &::-webkit-scrollbar-track {
                 box-shadow: inset 0 0 5px grey;
                 border-radius: 5px;
             }
         
             /* Handle */
             &::-webkit-scrollbar-thumb {
                 background: $blue_color;
                 border-radius: 5px;
             }
         
             /* Handle on hover */
             &::-webkit-scrollbar-thumb:hover {
                 background: $blue_color;
             }
                li{
                    list-style: none;
                    border: 1px solid #ccc;
                    margin-bottom: 20px;
                    border-radius: 5px;
                    padding: 20px;
                    // cursor: pointer;
                    .description-text{
                        font-weight:400;
                        color: $blue_color;
                        font-size: 18px;
                        margin-bottom: 0;
                        white-space: initial;
                    }
                    .description-text-sm{
                     white-space: initial;
                    }
                }
            }
        }
        .lead_status_section{
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
            margin-bottom: 20px;
            text-align: center;
            &:first-child{
                margin-top: 0;
            }
            .accept-lead{
                padding: 8px 12px;
                font-size: 12px;
                border-radius: 20px;
                background: $green_color;
                color: $white_color;
                font-weight: 500;
                display: inline-block;
                margin: 10px 0;
                i{
                    margin-right: 6px;
                    font-weight: 500;
                }
            }
            .accept-lead.decline{
               background: red;
            }
            .accept-lead.no_longer{
                background: $grey_color;
             }
             .description-text-sm{
                white-space: initial;
             }
        }
        .msg-box{
               height:435px;
               overflow-y: auto;
               padding-left: 0;
               margin-bottom: 0;           
               &::-webkit-scrollbar {
                   width: 5px; 
                   height: 5px;
               }
           
               /* Track */
               &::-webkit-scrollbar-track {
                   box-shadow: inset 0 0 5px transparent;
                   border-radius: 5px;
               }
           
               /* Handle */
               &::-webkit-scrollbar-thumb {
                   background: transparent;
                   border-radius: 5px;
               }
           
               /* Handle on hover */
               &::-webkit-scrollbar-thumb:hover {
                   background: transparent;
               }
            .left-msg{
                border: 1px solid #00000017;
                border-radius: 15px;
                padding: 10px 20px;
                background-color:$white_color;
                margin: 10px 0px 10px 20%;
                p{
                    margin-bottom: 0;
                    word-break: break-word;
                    white-space: initial;
                }
            }
            .right-msg{
                border: 1px solid #00000017;
                border-radius: 15px;
                padding: 10px 20px;
                background-color: rgba(12 ,253, 130 , 0.19);
                margin: 10px 20% 10px 0px;
                p{
                    margin-bottom: 0;
                    white-space: initial;
                    word-break: break-word;
                }
            }
        }
    }
}


// single lead section design code ends here




// Admin side leads section design code starts here


.admin-lead-cancel-wrap{
    padding: 15px 20px;
    background: red;
    margin-bottom: 20px;
    border-radius: 5px;
   p{
       font-size: 16px;
       margin-bottom: 0;
       font-weight: 500;
       color: $white_color;
       span{
        font-weight: 600;
       }
   }
}


.user_profile_view{
    padding: 30px 25px;
    border: 1px solid #ccc;
    display: flex;
    border-radius: 5px;
    margin-bottom: 50px;
    @media(min-width:992px) and (max-width:1199px){
      display: block;
    }
    @media(max-width:767px){
        display: block;
    }
.user_info_leads{
    padding: 40px 20px;
    background: #e0f2fa;
    max-width: 380px;
    border-radius: 5px;
    width: 100%;
    @media(min-width:992px) and (max-width:1199px){
       margin-bottom: 30px;
       margin-left: auto;
       margin-right: auto;
       border: 0;
    }
    @media(max-width:767px){
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        border: 0;
     }
     .profile_section{
        width: 100px;
        height: 100px;
        border: 1px solid #ccc;
        border-radius: 50%;
        margin: auto;
        img{
            width: 100%;
            border-radius: 50%;
        }
     }
     .p_img_wrap{
        background: #fff;
        border-radius: 50%;
        display:block;
        margin: auto;
        max-width: 100%;
        overflow: hidden;
        width: 100px;
        border: 1px solid #ccc;
           span{
            display: block;
            height: 0;
            overflow: hidden;
            padding-bottom: 100%!important;
            position: relative;
            z-index: 0;
            border-radius: 50%;
                img{
                    bottom: 0;
                    display: block;
                    height: auto;
                    left: 0;
                    margin: auto;
                    max-width: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    border-radius: 0;
                    border: 0;
                }
           }
       }
    .icon-wrap{
        width: 100px;
        height: 100px;
        border: 1px solid #ccc;
        border-radius: 50%;
        margin: auto;
        padding: 30px 20px;
        background: $grey_color;
        text-align: center;
        i{
            font-size: 30px;
            color: $white_color;
        }
    }
    .content-wrap{
        text-align: center;
        .name_user{
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 4px;
            color: $blue_color;
            text-transform: capitalize;
        }
        i{
            color: $green_color;
            margin-right: 6px;
        }
    }
    .status_wrap{
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
        .lead{
            padding: 6px 10px;
            font-size: 14px;
            background: $blue_color;
            color: $white_color;
            text-align: center;
            border-radius: 5px;
            display: inline-block;
            vertical-align: top;
        }
        .cat{
            padding: 6px 10px;
            font-size: 14px;
            background: #ECBE7A;
            color: $black_color;
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
            border-radius: 5px;
            display: inline-block;
            vertical-align: top;
        }
        .status{
            padding: 6px 10px;
            font-size: 14px;
            background: $green_color;
            color: $white_color;
            text-align: center;
            border-radius: 5px;
            font-weight: 600;
            display: inline-block;
            vertical-align: top;
        }
        .ACTIVE{
            background:#3392cc;
        }
        .COMPLETED{
            background: $green_color;
        }
        .CANCEL{
            background: red;
        }
    }
}
.ques_listing{
   padding-left: 0;
   margin-bottom: 0;        
   flex: auto;
   padding-left: 30px; 
   height:580px;
   overflow-y: auto;          
   @media(max-width:767px){
       padding-left: 0;
   }
   &::-webkit-scrollbar {
       width: 5px;
       height: 5px;
   }

   /* Track */
   &::-webkit-scrollbar-track {
       box-shadow: inset 0 0 5px grey;
       border-radius: 5px;
   }

   /* Handle */
   &::-webkit-scrollbar-thumb {
       background: $blue_color;
       border-radius: 5px;
   }

   /* Handle on hover */
   &::-webkit-scrollbar-thumb:hover {
       background: $blue_color;
   }  
      li{
          list-style: none;
          border: 1px solid #ccc;
          margin-bottom: 20px;
          border-radius: 5px;
          padding: 20px;
          .description-text{
              font-weight:400;
              color: $blue_color;
              font-size: 18px;
              margin-bottom: 0;
              white-space: initial;
          }
          .description-text-sm{
           white-space: initial;
          }
      }
  }
}
.admin_lead_listing{
    tbody{
        tr{
            td{
                span.status{
                    color: $white_color;
                    padding: 6px 10px;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 13px;
                    display: inline-block;
                }
                .ACTIVE,.Active{
                    background:#26bbd9;
                   
                }
                .COMPLETED{
                    background: $green_color;
                }
                .CANCELLED,.Expired{
                    background: red;
                }
                .assign-agnecy-div{
                    display: flex;
                    width: 200px;
                    .assign-agency-list{
                        margin-bottom: 0;
                        padding-left: 0;
                        text-align: left;
                        width: calc(100% - 30px);
                        display: inline-block;
                        vertical-align: middle;
                        li{
                            list-style: none;
                            font-size: 14px;
                            margin-bottom: 6px;
                            position: relative;
                            padding-left: 18px;
                            &::before{
                                content: '\f111';
                                position: absolute;
                                top: 5px;
                                left: 0;
                                color: $blue_color;
                                font-family: "Font Awesome 5 Pro";
                                display: inline-block;
                                vertical-align: middle;
                                font-weight: 900;
                                font-size: 6px;
                            }
                        }
                    }
                    a{
                        width: 20px;
												cursor: pointer;
                    }
                }
								#view__btn {
									cursor: pointer;
									color: #004dc0;
								}
            }
        }
    }
}

.assign_agency_modal{
    .modal-dialog{
        .modal-body{
            max-height: 100%;
            height: 300px;
            .multiselect-container{
                .search-wrapper{
                    .chip{
                        background: #fe5d70;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
// Admin side leads section design code ends here