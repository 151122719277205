// Request badge page design code starts here

.request-badge-wrapper{
    background: $white_color;
    box-shadow: 0 0 16px 2px rgba(0, 0, 0 , 0.20);
    // border: 1px solid #ccc;
    padding: 40px;
    max-width: 900px;
    width: 100%;
    margin: auto;
    .main-heading{
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 20px;
        &::before{
            bottom: 0;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            width: 105px;
            margin: auto;
            height: 3px;        
            background: $green_color;
        }
    }
    .description-text-sm{
        color: $black_color;
        font-weight: 400;
    }
    .checkbox__field_c{
        margin-bottom: 20px;
        @media(max-width:1366px){
            margin-bottom: 12px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
          }
          label {
            position: relative;
            cursor: pointer;
            margin-bottom: 0;
            font-size: 16px;
            color: $black_color;
            padding-left: 35px;
            font-weight: 400;
            @media(max-width:1366px){
                font-size: 18px;
              }
            &::before{
                content:'';
                -webkit-appearance: none;
                background-color: transparent;
                border:1px solid #A4A4A4;
                box-shadow: none;
                width: 22px;
                height: 22px;
                border-radius: 5px;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0px;
                @media(max-width:1366px){
                    width: 20px;
                    height: 20px;
                }
            }
          }
          input:checked + label:after  {
            content: '\f00c';
            display: block;
            position: absolute;
            top: 4px;
            font-family: "Font Awesome 5 Pro";
            font-weight: bold;
            left: 5px;
            color: $white_color;
            font-size: 12px;
          }
          input:checked + label::before {
            background-color: $blue_color;
            border-color: $blue_color;
          }
    }
    .req-service-listing{
        margin: 20px 0;
    }
    .user-form-section{
        margin-top: 16px;
        label{
            font-size: 16px;
        }
    }
}


// Request badge page design code ends here