// view request badge code starts here

.req-badge-view{
    .label-heading{
        font-size: 20px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        color: $grey_color;
    }
 .contact-detail-badge{
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 30px 20px;
    // max-width: 500px;
    width: 100%;
    margin-bottom: 30px;
    height: calc(100% - 85px);
    @media(max-width:767px){
       flex-wrap: wrap;
    }
     .icon-wrap{
        background: #fff;
    border-radius: 50%;
    display: block;
    margin: auto;
    max-width: 100%;
    overflow: hidden;
    width: 100px;
    border: 1px solid #ccc;
    margin-right: 20px;
         @media(max-width:767px){
            margin: auto;
            margin-bottom: 20px;
         }
         span{
            display: block;
            height: 0;
            overflow: hidden;
            padding-bottom: 100% !important;
            position: relative;
            text-align: center;
            z-index: 0;
            img,i{
                bottom: 0;
                display: block;
                height: auto;
                left: 0;
                margin: auto;
                max-width: 100%;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
            }
            i{
                font-size: 35px;
                top: 27px;
            }
         }
     }
     .content-wrap{
         width: calc(100% - 100px);
         @media(max-width:767px){
             width: 100%;
             text-align: center;
         }
         .name{
             font-weight: 700;
             color: $blue_color;
             margin-bottom: 10px;
         }
         .description-text-sm{
             font-weight: 400;
             margin-bottom: 10px;
             i{
                 color: $green_color;
                 margin-right: 10px;
             }
         }
     }
 }
 .service-req-block{
     margin-bottom: 30px;
     border: 1px solid #ccc;
     .inner-block-col{
         padding: 30px;
         a{
             cursor: pointer;
         }
     }
     .sub-heading{
         font-size: 26px;
         font-weight: 600;
         color: $white_color;
         padding: 20px;
         border-bottom: 1px solid #ccc;
         background: #494949;
         @media(max-width:1366px){
             font-size: 20px;
         }
     }
     .badge-for{
         display: flex;
         margin-bottom: 30px;
         span{
            background: $green_color;
            border-radius: 50%;
            padding: 5px;
            width: 50px;
            height: 50px;
            justify-content: center;
            margin-right: 15px;
            display: flex;
            align-items: center;
            i{
                color: $white_color;
                font-size: 20px;
                font-weight: 900;
            }
        }
        .inner-wrap{
            width: calc(100% - 50px);
            h6{
                font-size: 22px;
                color: $grey_color;
                font-weight: 600;
                @media(max-width:767px){
                    font-size: 18px;
                }
            }
            .description-text-sm{
                font-size: 18px;
                font-weight: 500;
                color: $blue_color;
                @media(max-width:767px){
                    font-size: 16px;
                }
            }
        }
     }
 }
}
.badge-model{
    .badge-download{
        width: 150px;
        position: relative;
         #download_icon{
            position: absolute;
            top: 0;
            right: 0;
            background: $green_color;
            padding: 6px 8px;
            margin-right: 0;
            border-bottom-left-radius: 5px;
            i{
                font-size: 14px;
                color: $white_color;
            }
         }
     }
}