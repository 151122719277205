.admin-dashboard{
    .status_chart{
        // max-width: 400px;
        // width: 100%;
        margin: auto;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 20px;
        margin-bottom: 30px;
        height: calc(100% - 30px);
    }
    .lead_chart{
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 30px;
        border-radius: 20px;
        height: calc(100% - 30px);
    }
    .lead_listing{
        border: 1px solid #ccc;
        padding: 20px;
        padding-top: 0;
        margin-bottom: 30px;
        border-radius: 5px;
        .lead_heading{
            background:#494949;
            margin: 0 -20px;
            padding: 10px 20px;
            margin-bottom: 30px;
            h2{
                color: $white-color;
                margin-bottom: 0;
                font-size: 30px;
            }
        }
    }
    .plan_chart_section{
        border: 1px solid #ccc;
        padding: 30px 10px;
        border-radius: 20px;
        @media(max-width:767px){
           canvas{
               margin-bottom: 30px;
           }
        }
        @media(max-width:480px){
           border: 0;
           padding: 0;
        }
    }
    .mb-hide_chart{
        @media(max-width:480px){
            display: none;
        }
    }
    .table-responsive{
        margin-bottom: 20px;
    }
}