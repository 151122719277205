.flash{
	color: white;
	border-radius: 5px;
	top: 50px;
	right: 75px;
	padding: 15px 20px;
    margin: 20px 0;
    padding-right: 45px;
    // position: relative;
    // max-width: 900px;
    width: auto;
    position: fixed;
    z-index: 99;
    @media(max-width:991px){
        right: 20px;
    }
	span {
		color: white;
		cursor: pointer;
        position: absolute;
        top: 15px;
        right: 20px;
		margin-left: 10px;
		display: inline-block;
        vertical-align: top;
	}
	p {
	 	margin: 0px; 
	}
}
.primary-btn{
    .spinner-border{
        margin-left: 5px;
    }
}
.flash-success {
	background: #9ee19e;
}

.flash-error {
	background: #ff9494;
}

.required_field {
	color: red;
    font-size: 25px;
    vertical-align: sub;
    line-height: 0.7;
}
a{
    text-decoration: none !important;
}
.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.description-text-sm{
    color: $grey_color;
    margin-bottom: 0;
    line-height: 30px;
    font-weight: 400;
    font-size: 16px;
}
.primary-btn{
    text-transform: capitalize;
    font-size: 16px;
    background:$green_color;
    color: $white_color;
    // width: 100%;
    border: 0;
    text-align: center;
    padding:12px 15px !important;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    &.add-btn{
        padding: 9px 15px !important;
        cursor: pointer;
    }
    @media(max-width:1366px){
        font-size: 14px;
    }
    &::before{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 0%;
        border-radius: 5px;
        background-color: $blue_color;
    }
    &::after{
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 0%;
        background-color: $blue_color;
        -webkit-transition: width .5s;
        -o-transition: width .5s;
        border-radius: 5px;
        transition: width .5s;
    }
 span,i{
     position: relative;
     z-index: 1;
     display: inline-block;
 }
    &:hover{  
        background-color: $green_color;
            color:$white-color;
        &::before{
            -webkit-transition: width .5s;
            -o-transition: width .5s;
            transition: width .5s;
            width: 100%;
        }
        &::after{
            -webkit-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
            visibility: hidden;
            width: 100%;
        }
    }
    @media (hover: none) {
        &:hover{
            background-color: $blue_color;
            color:$white-color;
            &::before,&::after{
                display: none;
            }
        }
    }
    &:focus{
        outline: 0;
        box-shadow: none !important;
        background-color: $blue_color;
        color:$white-color;
    }
    &:disabled{
      background-color:$blue_color;
    }
}
.acc_dec_wrapper{
    margin-top: 20px;
}
.text-danger{
    display: block;
}
.decline-btn{
    text-transform: uppercase;
    font-size: 16px;
    background:red;
    color: $white_color;
    // width: 100%;
    border: 0;
    text-align: center;
    padding:12px 15px;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    @media(max-width:1366px){
        font-size: 14px;
    }
    &::before{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 0%;
        border-radius: 5px;
        background-color: $blue_color;
    }
    &::after{
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 0%;
        background-color: $blue_color;
        -webkit-transition: width .5s;
        -o-transition: width .5s;
        border-radius: 5px;
        transition: width .5s;
    }
 span,i{
     position: relative;
     z-index: 1;
     display: inline-block;
 }
    &:hover{  
        background-color: red;
            color:$white-color;
        &::before{
            -webkit-transition: width .5s;
            -o-transition: width .5s;
            transition: width .5s;
            width: 100%;
        }
        &::after{
            -webkit-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
            visibility: hidden;
            width: 100%;
        }
    }
    @media (hover: none) {
        &:hover{
            background-color: $blue_color;
            color:$white-color;
            &::before,&::after{
                display: none;
            }
        }
    }
    &:focus{
        outline: 0;
        box-shadow: none !important;
        background-color: $blue_color;
        color:$white-color;
    }
    &:disabled{
      background-color:$blue_color;
    }
}
.secondary-btn{
    text-transform: uppercase;
    font-size: 16px;
    background:$blue_color;
    color: $white_color;
    // width: 100%;
    border: 0;
    padding:13px 15px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    @media(max-width:1366px){
        font-size: 14px !important;
    }
    &::before{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 0%;
        border-radius: 5px;
        background-color: $green_color;
    }
    &::after{
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 0%;
        background-color: $green_color;
        -webkit-transition: width .5s;
        -o-transition: width .5s;
        border-radius: 5px;
        transition: width .5s;
    }
 span,i{
     position: relative;
     z-index: 1;
     display: inline-block;
 }
    &:hover{  
        background-color: $blue_color;
            color:$white-color;
        &::before{
            -webkit-transition: width .5s;
            -o-transition: width .5s;
            transition: width .5s;
            width: 100%;
        }
        &::after{
            -webkit-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
            visibility: hidden;
            width: 100%;
        }
    }
    @media (hover: none) {
        &:hover{
            background-color: $green_color;
            color:$white-color;
            &::before,&::after{
                display: none;
            }
        }
    }
    &:focus{
        outline: 0;
        box-shadow: none !important;
        background-color: $green_color;
        color:$white-color;
    }
}
.border-btn{
    text-transform: uppercase;
    font-size: 16px;
    background:$white_color;
    color: $grey_color;
    // width: 100%;
    border: 1px solid $green_color;
    padding:12px 15px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    &:hover{
        @media(min-width:1100px){
        border: 1px solid transparent !important;
        background: transparent !important;
        }
        
    }
    @media(max-width:1366px){
        font-size: 14px;
    }
    &::before{
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 0%;
        border-radius: 5px;
        background-color: $green_color;
    }
    &::after{
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 0%;
        background-color: $green_color;
        -webkit-transition: width .5s;
        -o-transition: width .5s;
        // border-radius: 5px;
        transition: width .5s;
    }
 span,i{
     position: relative;
     z-index: 1;
     display: inline-block;
 }
    &:hover{  
        background-color: $blue_color;
            color:$white-color;
        &::before{
            -webkit-transition: width .5s;
            -o-transition: width .5s;
            transition: width .5s;
            width: 100%;
        }
        &::after{
            -webkit-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
            visibility: hidden;
            width: 100%;
        }
    }
    @media (hover: none) {
        &:hover{
            background-color: $green_color;
            color:$white-color;
            &::before,&::after{
                display: none;
            }
        }
    }
    &:focus{
        outline: 0;
        box-shadow: none !important;
        background-color: $green_color;
        color:$white-color;
    }
}
// header design code starts here

.top_nav{
    background: $light_wheat-color;
    padding: 22px 33px 33px 33px;
    @media(max-width:991px){
        padding: 15px 44px 15px 33px;
    }
    .mb_toggle{
        display: none;
        vertical-align: middle;
        margin-right: 20px;
        @media(max-width:991px){
            display: inline-block;
        }
        i{
            font-size: 24px;
            font-weight: 700;
            color: $blue_color;
        }
    }
  .logo_section{
      width: 200px;
      display: inline-block;
      vertical-align: middle;
      @media(max-width:767px){    
    		width: 128px;
      }
			span {
				position: static !important;
			}
      img{
					width: auto !important;
					height: auto !important;
					min-width: auto !important;
					min-height: auto !important;
					max-width: 100% !important;
					max-height: none !important;
					position: static !important;
					margin: 0 !important;
          @media(max-width:767px){
            width: 100% !important;
        }
      }
  }
  .navbar-right{
      width: calc(100% - 220px);
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
      text-align: right;
      @media(max-width:767px){
        width: calc(100% - 128px);
    }
      li.nav-item{
        display: inline-block;
        padding-left: 30px;
        @media(max-width:767px){
            padding-left:0;
            padding-right: 15px;
        }
      }
      .profile_dropdown_nav{
            padding: 0;
          .dropdown-toggle{
              background: transparent;
              color: $grey_color;
              border: 0;
              @media(max-width:767px){
               padding: 0;
              }
              &:focus{
                  outline: 0;
                  box-shadow: none;
              }
              .profile_img{
                  margin-right: 10px;
              }
              .user__name{
                  @media(max-width: 991px){
                      display: none;
                  }
              }
              .p_img_wrap{
                background: #fff;
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin: auto;
                max-width: 100%;
                overflow: hidden;
                width: 48px;
                border: 1px solid #ccc;
                   span{
                    display: block;
                    height: 0;
                    overflow: hidden;
                    padding-bottom: 100%!important;
                    position: relative;
                    z-index: 0;
                        img{
                            bottom: 0;
                            display: block;
                            height: auto;
                            left: 0;
                            margin: auto;
                            max-width: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 100%;
                            border-radius: 0;
                            border: 0;
														object-fit: contain;
                        }
                   }
               }
          }
      }
      .profile_dropdown_menu{
          .dropdown-item{
              padding: 8px 20px;
          }
      }
      .notification_section{
        .notification_btn{
            background: transparent;
            border: 0;
            padding: 0;
            font-size: 18px;
            color: $grey_color;
            &::after{
                display: none;
            }
            &:focus{
                box-shadow: none;
            }
            .count{
                display: inline-block;
                font-size: 12px;
                background: $green_color;
                color: $white_color;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                padding: 1px;
                position: absolute;
                top: -7px;
                right: -11px;
            }
        }
        .dropdown-menu {
            padding: 0;
            width: 360px;
            min-width: auto;
            @media(max-width:640px){
                width: 300px;
                transform: translate(-149px, 29px) !important;
                inset: auto !important;
            }
            .dropdown-item{
                border-bottom: 1px solid #ccc;
                text-decoration: none;
                color: $grey_color;
                padding: 15px 22px;
                white-space: inherit;
                transition: all 0.4s ease-in-out;
                &:hover{
                    background: #e6f3ff;
                }
                .icon_col_{
                    width: 30px;
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 10px;
                    i{
                        font-size: 20px;
                        font-weight: 400;
                        color: $green_color;
                    }
                }
                .detail_col_{
                    width: calc(100% - 35px);
                    display: inline-block;
                    padding-left: 10px;
                    vertical-align: middle;
                }
                p{
                    margin-bottom: 5px;
                    font-size: 14px;
                }
                .time-ago{
                    color: #ababab;
                }
            }
            .unseen_msg{
                background: #e6f3ff;
                &:hover{
                    background: #fff7eb;
                }
            }
            .view_all_notify{
                color: $white_color;
                background: $blue_color;
                transition: all 0.4s ease-in-out;
                &:hover{
                    background: $green_color;
                }
            }
        }
        .no_notify{
            color: $black_color;
            background: #f7f7f7;
            padding: 12px 15px;
        }
    }
      .profile_img{
          border-radius: 50%;
          width: 50px;
          height: 50px;
          border: 1px solid #a8a8a8;
      }
  }
}


// header design code ends here

// body content design code starts here


.body_content{
   background-image: url('../../../public/admin/main-bg.jpg');
   background-size: cover;
   padding: 0 33px 33px 33px;
   position: relative;
   display: flex;
   @media(max-width:991px){
    padding: 0;
}
//    left sidebar design code starts here
   .leftbar_section{
    width: 316px;
    display: inline-block;
    vertical-align: top;
    background: $white_color;
    margin-top: -18px;
    box-shadow: 0 8px 16px 2px rgba(0, 0 ,0 ,0.1);
    border-radius: 5px;
    height: 90vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $green_color;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $green_color;
    }
     @media(max-width:991px){
         width: auto;
         margin-top: 0;
         position: absolute;
         top: -61px;
         right: 0;
         background: transparent;
         box-shadow: none;
         height: auto;
     }
       img{
           width: 100%;
           height: auto;
       }
       .main-title{
           p{
            font-size: 14px;
            color: #3a3a3a;
            font-weight: 400;
            margin-bottom: 0;
            padding:0 20px;
            opacity: 51%;
           }
       }
       .profile_section{
           margin: 30px 0;
           text-align: center;
           padding:0 20px;
           .p_img_wrap{
            background: #fff;
            border-radius: 50%;
            display: block;
            margin: auto;
            max-width: 100%;
            overflow: hidden;
            width: 100px;
            border: 1px solid #ccc;
               span{
                display: block;
                height: 0;
                overflow: hidden;
                padding-bottom: 100%!important;
                position: relative;
                z-index: 0;
								span {
									position: static !important;
									padding-bottom: 0;
								}
                    img{
                        bottom: 0;
                        display: block;
                        height: auto;
                        left: 0;
                        margin: auto;
                        max-width: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 100%;
												object-fit: contain;
                    }
               }
           }
           h4{
            font-size: 30px;
            color: $grey_color;
            font-weight: 800;
            margin-top: 15px;
            text-transform: capitalize;
            font-family: $secondary-font;
            @media(max-width:1366px){
                font-size: 20px;
            }
           }
       }
       .sidebar_nav{
        background: $white_color;
        padding: 20px 0;
        @media(max-width:991px){
            padding: 0;
            background: transparent;
        }
        .navbar-toggler{
            border: 0;
            box-shadow: none;
        }
           .navbar-collapse{
               display: block !important;
               @media(max-width:991px){
                //    display: none !important;
                   min-height: 100%;
                   width: 300px;
                   height: 100%;
                   top: 0;
                   left: -300px;
                   position: fixed;
                   background: $white_color;
                   overflow-y: auto;
                   z-index: 999;
                   padding: 20px 0;
                   box-shadow: 0 8px 16px 2px rgba(0, 0 ,0 ,0.1);
                   transition: all .4s ease-in-out;
               }
               .navbar-toggler{
                position: absolute;
                top: 0;
                right: 0;
                background: #2471eb;
                color: #fff;
                width: 32px;
                height: 34px;
                text-align: center;
                border-radius: 0 0 0 10px;
                   .navbar-toggler-icon{
                       display: none;
                   }
                   &::before,&::after{
                    position: absolute;
                    left: 17px;
                    content: ' ';
                    height: 16px;
                    width: 2px;
                    background-color: $white_color;
                    top: 8px;
                   }
                   &::before{
                    transform: rotate(45deg);
                   }
                   &::after{
                    transform: rotate(-45deg);
                   }
               }
           }
           .navbar-collapse.show{
            display: block !important; 
            left: 0;
           }
            .navbar-nav{
                display: block;
                width: 100%;
                .nav-link{
                    font-size: 20px;
                    font-weight: 400;
                    padding: 9px 20px;
                    display: block;
                    color: $grey_color;
                    transition: all 0.3s ease-in-out;
                    margin-bottom: 20px;
                    @media(max-width:1366px){
                        font-size: 18px;
                    }
                    &:hover{
                     background: $blue_color;
                     color: $white_color; 
                     .dropdown-toggle{
                         color: $white_color;
                     }
                    }
                    i{
                        margin-right: 15px;
                        min-width: 25px;
                    }
                    .dropdown-toggle {
                        background: transparent;
                        border: 0;
                        // color: $grey_color;
                        color: inherit;
                        padding: 0;
                        font-size: 20px;
                        font-weight: 400;
                        display: block;
                        position: relative;
                        text-align: left;
                        width: 100%;
                        @media(max-width:1366px){
                            font-size: 18px;
                        }
                        &::after{
                            position: absolute;
                            right: 0;
                            top: 10px;
                        }

                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
                .dropdown{
                    margin-bottom: 0;
                    .nav-link{
                        margin-bottom: 0;
                    }
                    .dropdown-menu{
                        position: relative;
                        border: 0;
                        z-index: 1;
                        padding-left: 30px;
                        a{
                            font-size: 16px;
                            font-weight: 400;
                            padding: 9px 20px;
                            @media(max-width:1366px){
                                font-size: 14px;
                            }
                            i{
                                margin-right: 6px;
                                width: 20px;
                            }
                        }    
                        .dropdown-item.active{
                            background: $blue_color;
                            color: $white_color;
                        }  
                    }
                    .active_menu{
                        display: block;
                    }
                }
            }
           .nav-link.active{
                   background: $blue_color;
                   color: $white_color;
           }
       }
   }
.leftbar_section.active{
    left: 0;
}
   //    left sidebar design code ends here
   .right_col{
    width: calc(100% - 340px);
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    background: $white_color;
    padding: 25px 35px;
    margin-top: -18px;
    box-shadow: 0 8px 16px 2px rgba(0, 0 ,0 ,0.1);
    border-radius: 5px;
    height:90vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $green_color;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $green_color;
    }
    @media(max-width:991px){
        width: 100%;
        margin-left: 0;
        margin-top: 0;
        border-radius: 0;
        min-height:90vh;
    }
    .dashboard_title{
        margin-bottom: 30px;
        img, span {
            display: inline-block !important;
            vertical-align: middle;
        }
				.primary-btn {
					span {
						display: block !important;
						width: 100%;
					}
				}
        h6{
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            color:  #444444;
            font-weight: 400;
            margin-bottom: 0;
            width: calc(100% - 50px);
            margin-left: 15px;
            @media(max-width:580px){
                font-size: 18px;
            }
        }
        i{
            color: $green_color;
            font-size: 28px;
            vertical-align: middle;
            font-weight: 300;
        }
        .breadcrumb_text{
            font-size: 16px;
            margin-top: 20px;
            color: #3c3c3c;
            margin-bottom: 0;
            font-weight: 300;
            i{
                color:  #444444;
                margin: 0 15px;
            }
        }
    }
		.mb-3 {
			margin-bottom: 20px !important;
		}
		.password_block {
			.mb-3 {
				margin-bottom: 0 !important;
				.input_text {
					margin-bottom: 20px;
				}
			}
			.text-danger {
				display: block !important;
			}
		}
		form {
			.col-md-6:first-child {
				> div {
					z-index: 3;
				}
			}
		}
   }
}


// body content design code ends here



// tooltip design section starts here

.tooltip{
    z-index: 1;
    .arrow{
        position: absolute;
        display: block;
        width: .8rem;
        height: .4rem;
        &::before{
            position: absolute;
            content: "";
            border-color: transparent;
            border-style: solid;
            display: inline-block;
        }
    }
} 
.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
    bottom: -6px;
    &::before{
        bottom: 0;
        border-width: .4rem .4rem 0;
        border-top-color: #000;
    }
}
.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
    top: -6px;
    &::before{
        bottom: 0;
        border-width: 0 .4rem .4rem;
        border-bottom-color: #000;
    }
}
.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-bottom .arrow {
    right: -6px;
    width: .4rem;
    height: .8rem;
    &::before{
        left: 0;
        border-width: .4rem 0 .4rem .4rem;
        border-left-color: #000;
    }
}
.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-bottom .arrow {
    left: -6px;
    width: .4rem;
    height: .8rem;
    &::before{
        right: 0;
        border-width: .4rem .4rem .4rem 0;
        border-right-color: #000;
    }
}
// tooltip design section ends here


// Custom image upload design code starts here

.uploaded-img {
    margin-bottom: 20px;
    max-width: 150px;
    height: 150px;
    padding-left: 10px;
    padding-top: 10px;
    .img_wrap{
        height: 150px;
        position: relative;
        i{
            position: absolute;
            background: #00cc83;
            padding: 6px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center !important;
            font-weight: 300;
            font-size: 20px;
            left: -10px;
            top: -10px;
            cursor: pointer;
            &:hover{
                background:$blue_color;
                color: $white-color;
            }
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.word-count{
    font-size: 14px;
    color: $black_color;
}
.model_img_wrap{
    background: #fff;
    display:block;
    max-width: 100%;
    overflow: hidden;
    width: 150px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
       span{
        display: block;
        height: 0;
        overflow: hidden;
        padding-bottom: 100%!important;
        position: relative;
        z-index: 0;
            img{
                bottom: 0;
                display: block;
                height: auto;
                left: 0;
                margin: auto;
                max-width: 100%;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
                border-radius: 0;
                border: 0;
            }
       }
   }
   .award_img_wrap{
       span{
           img{
               height: 140px;
           }
       }
   }
.custom-file-input {
    max-width: 183px;
    position: relative;
    padding: 0px;
    margin-bottom: 17px;
    margin-top: 10px;
    display: inline-block;
    input {
        max-width: 183px;
        position: absolute;
        top: 0;
        opacity: 0;
        height: 40px;
        left: 0;
        width: 100%;
    }
    .add_file_icon{
         margin-left: 10px;
    }
    &::before {
        max-width: 183px;
        content: "Upload";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        background: #ffffffab;
        border: 1px dashed #dddddd;
        border-radius: 3px;
        padding: 12px 27px;
        outline: none;
        color: black;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px $white_color;
        font-size: 14px;
        text-align: center;
    }
}

// Custom image upload design code ends here




// react popup design starts here

#react-confirm-alert{
    .react-confirm-alert-overlay{
        background: rgba(0 ,0 ,0 ,0.5);
        .inner_block.custom-ui{
            max-width: 600px;
            width: 100%;        
            background: $white_color;
            border-radius: 5px;
            .header_block{
                background: $blue_color;
                color: $white_color;
                padding: 16px;
                text-align: center;
                h4{
                    margin-bottom: 0;
                    line-height: 1.5;
                }
            }
            .content_section{
                padding: 30px 20px;
                text-align: center;
            }
            .footer_block{
                padding: .75rem;
                border-top: 1px solid #dee2e6;
                display: flex;
                flex-wrap: wrap;
                flex-shrink: 0;
                align-items: center;
                justify-content: flex-end;
                button{
                    margin: .25rem;
                }
            }
        }
    }
}


// react popup design ends here



.validation_error{
    color: red;
    display: block;
    width: 100%;
}


// notification page design code starts here

.notification-page{
    ul{
        padding-left: 0;
        margin-bottom: 0;
        li{
            list-style: none;
            border: 1px solid #ccc;
            padding: 15px 40px 15px 22px;
            margin-bottom: 10px;
            transition: all 0.4s ease-in-out;
            position: relative;
            &:hover{
                background: #e6f3ff;
            }
            .icon_col{
                width: 30px;
                display: inline-block;
                vertical-align: middle;
                padding-right: 10px;
                i{
                    font-size: 20px;
                    font-weight: 400;
                    color: $green_color;
                }
            }
            .detail_col{
                width: calc(100% - 35px);
                display: inline-block;
                padding-left: 10px;
                vertical-align: middle;
            }
            p{
                margin-bottom: 5px;
            }
            .time-ago{
                color: #ababab;
            }
            .view_btn_notify{
                position: absolute;
                right: 18px;
                top: 38%;
                &:hover{
                    i{
                         color:$green_color;
                    }
                }
            }
        }
        .unseen_msg{
            background: #e6f3ff;
        }
    }
}


// notification page design code ends here



// tooltip design code starts here

.tooltip {
    .tooltip-inner{
        background-color: #606060 !important;
        color: #fff !important;
    }
    .arrow{
           &::before{
            border-top-color:#606060  !important;
           }
    }
}

// tooltip design code ends here

// .tooltip-inner{
//     background-color: #000 !important;
// }
// .arrow{
//        &::before{
//         border-top-color:#000 !important;
//        }
// }


// react datepicker design code starts here

.react-datepicker__tab-loop{
    .react-datepicker-popper{
        z-index: 2;
        .react-datepicker__triangle{
            &::before,&::after{
                border-bottom-color:$blue_color !important;
                left: -13px !important;
            }
        }
        .react-datepicker{
            border-radius: 0;
            .react-datepicker__header {
                border-radius: 0;
                background: $blue_color;
                .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
                color: $white_color;
                }
            }
            .react-datepicker__day--selected,.react-datepicker__day--keyboard-selected{
                background: $blue_color;
                &:hover{
                    background: $blue_color;
                }
            }
            .react-datepicker__navigation-icon{
                &::before{
                    border-color: $white_color;
                }
            }
        }
    }
}

// React datepicker design code ends here

// Admin Dashboard Box 
.dashboard-gird {
    padding-top: 30px;
    .bg-green {
        background: linear-gradient( 90deg,#0ac282,#0df3a3);
     }
     .bg-orange {
        background: linear-gradient(90deg,#fe9365,#feb798);
     }
     .bg-pink {
        background: linear-gradient(90deg,#fe5d70,#fe909d);
     }
     .bg-light-green {
        background: linear-gradient(90deg,#01a9ac,#01dbdf);
     }
    .widget {
        position: relative;
        width: 100%;
        border: none;
        margin-bottom: 30px;
        overflow: hidden;
        box-shadow: 0 8px 16px 2px #0000001a;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
            .widget-body {
            padding: 20px 20px;
            min-height: 117px;
            @media(min-width:1200px) and (max-width:1400px){
                padding: 15px;
                min-height: 100px;
            }
            h6{
            margin-bottom: 0;
            font-weight: 600;
            color: $white_color;
            @media(min-width:1200px) and (max-width:1400px){
                font-size: 16px;
            }
            }
            h2{
                margin-bottom: 0px;
                font-weight: 600;
                color: $white_color;
                @media(min-width:1200px) and (max-width:1400px){
                    font-size: 24px;
                }
            }
            .icon{
                font-size: 37px;
                color: $white_color;
                @media(min-width:1200px) and (max-width:1400px){
                    font-size: 26px;
                }
            }
            .text-small{
                font-size: 80%;
                color: $white_color;
                font-weight: 500;
            }
        }
        .progress-sm {
            height: 0.5rem;
            .progress-bar{
                height: 0.5rem;
        }
      }
    }
}


// Admin Dashboard Box end