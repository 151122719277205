.subscription-list{
    .plans_section-detail{
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
            .list-view {
                background: #f7f7f7;
                border-radius: 20px;
                list-style: none;
                width: 30%;
                flex: 0 0 auto;
                padding: 30px 20px;
                margin: 0 10px;
                margin-bottom: 20px;
                @media(max-width:1366px){
                    width: 45%;
                }
                @media(max-width:640px){
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
                .plans_list{
                    position: relative;
                    height: 100%;
                    padding-top: 30px;
                    .activated{
                        position: absolute;
                        top: -2px;
                        right: 0;
                        background: $green_color;
                        padding: 2px 10px;
                        color: $white_color;
                        font-weight: 600;
                        display: none;
                        border-radius: 20px;
                    }
                    .price_section{
                        .main-heading{
                            font-size: 30px;
                            margin-bottom: 20px;
                            text-transform: capitalize;
                            @media(max-width:991px){
                                font-size: 25px;
                            }
                        }
                        .description-text{
                            font-size: 18px;
                            color: $black_color;
                            margin-bottom: 20px;
                            span{
                                font-size: 30px;
                                font-weight: 700;
                                @media(max-width:991px){
                                    font-size: 25px;
                                }
                            }
                        }          
                        .row{
                            .text-right{
                                .description-text{
                                    font-size: 16px;
                                    font-weight: 600;
                                }
                                @media(max-width:1199px){
                                    text-align: left;
                                }
                            }
                        }        
                    }
                    .list_col{
                        font-size: 16px;
                        margin-bottom: 70px;
												overflow: hidden;
												-webkit-line-clamp: 6;
												-webkit-box-orient: vertical;
												display: -webkit-box;
												text-overflow: ellipsis;
												p:last-child {
													margin-bottom: 0;
												}
                    }
                    .primary-btn{
                        width: 100%;
                        position: absolute;
                        bottom: 0px;
                        left: 0;
                        box-sizing: border-box;
                    }
                    .disabled.primary-btn{
                         opacity: 0.5;
                         pointer-events: none;
                    }
                }
            }
            .active-plan-class{
                background: #eaf7ff;
                .plans_list{
                    .activated{
                        display: block;
                    }
                }           
            }
    }
    .subscribe-tabs{
        .nav.nav-pills{
            border-bottom: 1px solid #ccc;
            margin-bottom: 30px;
            .nav-item{
                .nav-link{
                    color: $grey_color;
                    font-size: 20px;
                    // border: 1px solid #ccc;
                    border-radius: 0;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    h5{
                        margin-bottom: 0;
                    }
                }
                .nav-link.active{
                    background: $green_color;
                    color: $white_color;
                }
            }
        }
    }
}