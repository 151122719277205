@import 'variables';

.contact-boxs{
    padding: 40px 0px;
    padding-bottom: 100px;
    @media(max-width:1366px){
        padding: 15px 0px;
        padding-bottom: 90px;
    }
    @media(max-width:991px){
        padding: 15px 0px;
        padding-bottom: 30px;
    }
    h2{
        color: #004DC0;
        font-family: "Nanum Myeongjo",serif;
        font-size: 40px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 80px;
        text-align: center;
        margin-bottom: 0px;
        @media(max-width:991px){
           margin-bottom: 30px;
        }
        @media(max-width:580px){
            font-size: 32px;
            line-height: 20px;
            margin-bottom: 40px;
        }
    }
    .description-text {
        margin-bottom: 50px;
    }
    .content-inner-box {
        background-color: #EAF7FF;
        box-shadow: 0 8px 16px 2px #0000001a;
        text-align: center;
        min-height: 200px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        height: 100%;
    }
    .icons-set{
        i{
            color: $green_color;
            font-size: 40px;
            letter-spacing: 0;
            line-height: 30px;
            font-weight: 300;
            @media(max-width:1050px){
                font-size: 40px;
            }
        }
    }
    .content-address{
        margin-top: 20px;
        p{
            margin-bottom: 0px;
            color: #404040;
           font-size: 20px;
            letter-spacing: 0;
            line-height: 30px;
            text-align: center;
            @media(max-width:1050px){
                font-size: 16px;
                letter-spacing: 0;
                line-height: 1.5;
            }
            span{
                display: inline-block;
                width: 100%;
            }
        }
    }
    .col-sm-6{
        @media(max-width:981px){
            margin-bottom: 20px;
        }
    }
}
.getin-touch-section{
    padding: 100px 10px;
    background-color: #FFF7EB;
    border-radius: 5px;
    overflow: hidden;
    @media(max-width:991px){
        padding: 45px 10px;   
    }
    .custom-container{
        .container{
            max-width: 1170px;
            .contact-wrap {
                display: flex;
                background-color: #fff;
                box-shadow: 0 8px 16px 2px #0000001a;
                border-radius: 5px;
                @media(max-width:767px){
                    flex-flow: column;
                }
                .contact-wrap-left {
                    width: 40%;
                    background: #eaf7ff;
                    padding: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: url('../../../public/public/contact-image.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    @media(max-width:991px){
                        padding: 25px;
                    }
                    @media(max-width:767px){
                        width: 100%;
                    }
                    .get-texts{
                        h3{
                            color: #FFFFFF;
                            font-family: "Nanum Myeongjo",serif;
                            font-size: 40px;
                            font-weight: 800;
                            letter-spacing: 0;
                            line-height: 60px;
                            @media(max-width:1050px){
                                font-size: 32px;
                                line-height: 1.5;
                            }
                            @media(max-width:991px){
                                font-size: 24px;
                            }
                        }
                        p{
                            color: #FFFFFF;
                            font-size: 20px;
                            letter-spacing: 0;
                            line-height: 30px;
                            margin-bottom: 0px;
                            @media(max-width:1050px){
                                font-size: 18px;
                                line-height: 1.5;
                            }
                        }
                    }
                }
                .contact-wrap-right {
                    width: 60%;
                    padding: 45px;
                    @media(max-width:991px){
                        padding: 30px;
                    }
                    @media(max-width:767px){
                        width: 100%;
                    }
                    @media(max-width:580px){
                        padding: 25px;
                        width: 100%;
                    }
                    .button-submit{
                        margin-bottom: 0px;
                    }
                    .primary-btn{
                        width: 100%;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                    }
                    .our-team-text{
                        color: #3C3C3C;
                        font-family: Raleway;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 25px;
                        text-align: center;
                        margin-bottom: 0px;
                        @media(max-width:580px){
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                    .contact-form-area{
												.form-group {
													margin-bottom: 20px !important;
													&:last-child {
														margin-bottom: 0 !important;
													}
												}
                        @media(max-width:580px){
                            .form-group {
                                margin-bottom: 0px !important;
                            }
                            .col-sm-6{
                                margin-bottom: 20px;
                            }
                            .col-sm-12{
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 

.main-contact-section{
    margin-bottom: 40px;
    display: none;
.input_text {
    border: 1px solid #A4A4A4;
    color: #000;
    font-size: 14px;
    padding: 14px 15px;
    background-color: #fff !important;
    width: 100%;
    border-radius: 5px;
    min-height: 51px;
}

.contact-heading{
    margin-bottom: 30px;
    .head-center{
        font-family: "Nanum Myeongjo", serif;
    font-size: 48px;
    margin-bottom: 0;
    font-weight: 800;
    display: inline-block;
    position: relative;
    margin-bottom: 30px;
    margin-top: 20px;
    &:before {
        position: absolute;
        content: "";
        bottom: 0;
        width: 100px;
        height: 2px;
        border-bottom: 3px solid $green_color;
        bottom: -30%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    }
}

.contact-detail{
    .contact-form{
        box-shadow: 0 8px 16px 2px #0000001a;
        padding: 30px;
        border-radius: 0px;
        background: #eaf7ff;
        position: relative;
        &:before {
            content: '';
            background: #004dc0;
            left: 0;
            width: 100%;
            top: 0;
            position: absolute;
            height: 5px;
            transition: all 0.4s ease-in-out;
        }
        .lbl-text{
            font-weight: 500;
        }
        .btn-secondary{
            padding: 13px 27px !important;
        }
    }
}
}

.banner_breadcrumb_section{
    @media(max-width:580px){
        min-height: 310px !important;
    }
}