// activity design code starts here
.no_activity_section{
    text-align: center;
    .icon-section{
        i{
            font-size: 80px;
            color: $grey_color;
            font-weight: 300;
        }
        p{
            font-size: 14px;
            color: #3a3a3a;
            font-weight: 400;
            margin:30px 0;
        }
    }
    .btns_section{
        .primary-btn{
            @media(max-width:767px){
                width: 100%;
                max-width: 250px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .second-btn{
            margin-left: 30px;
            @media(max-width:767px){
                margin-left: 0;
                width: 100%;
                max-width: 250px;
                margin-top: 20px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
            @media(min-width:992px)and (max-width:1199px){
                width: 100%;
                text-align: center;
                margin-bottom: 0;
                flex: auto;
            }
        }
    }
}
.activity{
    .activity_section{
        border: 1px solid #ccc;
        padding: 15px 40px 15px 22px;
        margin-bottom: 10px;
        transition: all 0.4s ease-in-out;
        position: relative;
        p{
            margin-bottom: 5px;
        }
        .time-ago{
            color: #ababab;
        }
    }
}
// activity design code ends here

// bookmark design code starts here

.right__agency_section{
   .agency-list-detail_wrapper{
       background: $white_color;
       border-radius: 5px;
       padding: 0 30px;
       border: 1px solid #ccc;
       margin-bottom: 30px;
       padding-top: 0px;
       position: relative;
       &:last-child{
           margin-bottom: 0;
       }
       .ribbon {
        width: 134px;
        height: 134px;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        &::before,&::after{
            position: absolute;
            z-index: -1;
            content: '';
            display: block;
            border: 5px solid red;
        }
        span {
            position: absolute;
            display: block;
            width: 214px;
            padding: 10px 0;
            background-color: red;
            box-shadow: 0 5px 10px rgba(0,0,0,.1);
            color: #fff;
            font-weight: 600;
            text-shadow: 0 1px 1px rgba(0,0,0,.2);
            text-transform: uppercase;
            text-align: center;
          }
      }
      /* top left*/
      .ribbon-top-left {
        top: -10px;
        left: -10px;
        &::before,&::after{
            border-top-color: transparent;
            border-left-color: transparent;
        }
        &::before{
            top: 0;
            right: 0;
        }
        &::after{
            bottom: 0;
            left: 0;
        }
        span {
            right: -25px;
            top: 30px;
            transform: rotate(-45deg);
          }
      }         
       .remove_bookmark{
           position: absolute;
           right: 0;
           top: 0;   
           background: $green_color;
           border-bottom-left-radius: 5px;
           cursor: pointer;
           &:hover{
            background: $blue_color;   
           }
           i{
            padding: 8px 10px;
               color: $white_color;
               font-weight: 500;
           }
       }
       .upper-section{
        border-bottom: 1px solid #E4E4E4;
           .img_agency{
            border-radius: 5px;
            width: 186px;
            display: inline-block;
            vertical-align: top;
            padding-top: 30px;
            padding-bottom: 30px;
            text-align: center;
            @media(max-width:1400px){
                width: 150px;
            }         
            @media(max-width:640px){
                width:100%;
               text-align: center;
               padding-bottom: 0;
             }    
            img{
                @media(max-width:640px){
                    width:100px; 
                 }   
                border-radius: 5px;
            }  
           }
           .content-wrap{
            width: calc(100% - 404px);
            display: inline-block;
            vertical-align: middle;
            padding: 0 49px;
            padding-top: 20px;
            padding-bottom: 20px;
            border-right: 1px solid #E4E4E4;
            @media(max-width:1400px){
                 width: calc(100% - 368px);
                 padding-left: 30px;
                 padding-right: 30px;
            }
            @media(min-width:992px)and (max-width:1199px){
                width: calc(100% - 150px);
                border:0;
             }
             @media(max-width:767px){
                width: calc(100% - 150px);
                border:0;
                padding-bottom: 0;
             }
             @media(max-width:640px){
                width:100%;
                padding-left: 0;
                padding-right: 0;
                text-align: center;
             }
             a{
                 &:hover{
                    .agency-name{
                        color: $green_color;
                    }
                 }
             }
               .agency-name{
                font-size: 20px;
                color: $grey_color;
                font-weight: bold;
                margin: 0;
                display: inline-block;
                @media(max-width:640px){
                 text-align: center;
                 }
               }
               .agency-category-list{
                   color: $blue_color;
                   font-weight: 500;
                   display: inline-block;
                   font-size: 16px;
                   &:hover{
                       color: $green_color;
                   }
                   @media(max-width:1366px){
                       font-size: 14px;
                   }
               }
               .rating-list{
                   padding-left: 0;
                  margin: 0px 0;
                   li{
                    font-size: 20px;
                    color: $grey_color;
                    font-weight: 600;
                    margin-right: 10px;
                    vertical-align: middle;
                    margin-bottom: 0px;
                    &:last-child{
                        margin-right: 0;
                    }
                    .star-rating{
                        padding-left: 0;
                        margin-bottom: 0;
                        li{
                            display: inline-block;
                            margin-right: 0px;
                            vertical-align: top;
                            i{
                                font-size: 15px;
                                color: #ffba00;
                            }
                        }
                    }
                    .reviews{
                        font-size: 16px;
                        font-weight: 300;
                        text-decoration: underline;
                        margin-bottom: 0;
                        color: $black_color;
                        transition: all 0.3s ease-in-out;
                        
                    }
                    a{
                        &:hover{
                            .reviews{
                            color: $green_color;
                            }
                        }
                    }   
                    img{
                        margin-right: 20px;
                        display: inline-block;
                        vertical-align: top;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                   }
               }
               .description-text-sm{
                   text-align: justify;
                a{
                    color: $green_color;
                    transition: all 0.3s ease-in-out;
                    display: inline-block;
                    &:hover{
                        color: $blue_color;
                    }
                }
                .read-more-less--more,.read-more-less--less{
                    color: $blue_color;
                    font-weight: 500;
                     &:hover{
                            color: $green_color !important;
                        }
                  }
            }
           }  
           .btn_block_right{
            width: 218px;
            display: inline-block;
            vertical-align: top;
            padding-left: 30px;
            padding-top: 30px;
            padding-bottom: 18px;
            @media(min-width:992px)and (max-width:1199px){
                width:100%;
                padding-left: 0;
                padding-top: 30px;
                display: flex;
             }
             @media (max-width:767px){
                width:100%;
                padding-left: 0;
                padding-top: 30px;
             }
               .review_block{
                   margin-bottom: 30px;
                   @media(min-width:992px)and (max-width:1199px){
                    margin-bottom: 0;
                    flex: auto;
                    margin-right: 20px;
                    width: 100%;
                   }
               }
               .primary-btn,.second-btn{
                     width: 100%;
                     text-align: center;
               }
               .primary-btn{
                padding: 14px 27px !important;
                @media(min-width:992px)and (max-width:1199px){
                    width: 100%;
                    text-align: center;
                    margin-bottom: 0;
                    flex: auto;
                }
                i{
                    display: none;
                }
                }
               .adv{
                   margin-bottom: 0;
                   margin-top: 10px;
                   i{
                       font-size: 25px;
                   }
               }
           }  
           .p_img_wrap{
            background: #fff;
            display: inline-block;
            vertical-align: middle;
            margin: auto;
            max-width: 100%;
            overflow: hidden;
            width: 146px;
            padding: 10px 0;
            @media(max-width:640px){
                display: block;
            }
               span{
                display: block;
                height: 0;
                overflow: hidden;
                padding-bottom: 100%!important;
                position: relative;
                z-index: 0;
                    img{
                        bottom: 0;
                        display: block;
                        height: auto;
                        left: 0;
                        margin: auto;
                        max-width: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 100%;
                        border-radius: 0;
                        border: 0;
                    }
               }
           }         
       }
       .bottom-section{
           .tags-wrap{
               padding: 24px 0;
               .tag-text{
                font-size: 20px;
                color: $grey_color;
                font-weight: 400;
                margin-right: 15px;
                margin-bottom: 6px;
               }
               .tag-listing{
                   display: inline-block;
                   padding-left: 0;
                   margin-bottom: 0;
                   vertical-align: top;
                   li{
                    font-size: 14px;
                    color: $grey_color;
                    margin-bottom: 6px;
                    vertical-align: top;
                    font-weight: 400;
                    border-radius: 15px;
                    background-color: #ffffff;
                    border: 1px solid #a4a4a4;
                    padding: 4px 20px;
                    margin-right: 15px;
                    transition: all 0.3s ease-in-out;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:hover{
                        border-color: $green_color;
                        color: $green_color;
                    }
                   }
                   li.active{
                       border-color: $green_color;
                       color: $green_color;
                   }
               }
           }
       }
   }
   .pagination-wrapper{
       padding-top: 30px;
   }
   .no_result{
       p{
           text-align: center;
       }
   }
}
// bookmark design code ends here



// user edit profile page design code starts here

.user_edit_profile_page{
    .profile_section{
        margin-bottom: 60px;
        img{
            width: 88px;
            height: 88px;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;
            border: 1px solid #ccc;
            @media(max-width:767px){
                margin-bottom: 20px;
            }
        }
        .upload-btn{
            display: inline-block;
            vertical-align: middle;
        }
    }
    .user_detail_form_wrap{
        .radio-btn {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            &:checked + .c_label {
                &::after{
                    background: $green_color;
                }
                &::before{
                    border: 2px solid $green_color;
                }
            }
        }
        
        .c_label{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: $grey_color;
            font-size: 16px;
            margin-bottom: 20px;
            cursor: pointer;
            position: relative;
            &::before {
                display: inline-block;
                content: "";
                height: 20px;
                width: 20px;
                margin-right: 10px;
                border: 2px solid $blue_color;
                border-radius: 50%;
                transition: all 0.25s linear;
            }
            &::after{
                height: 10px;
                width: 10px;
                display: inline-block;
                content: "";
                background: $white_color;
                position: absolute;
                top: 7px;
                left: 5px;
                border-radius: 50%;
            }
        }
        .label-text{
            margin-bottom: 10px;
            font-weight: 500;
        }
    }
    .password_block{
        margin-top: 40px;
        .description-text{
            margin-bottom:30px;
            padding-bottom: 20px;
            font-weight: 600;
            border-bottom: 1px solid #ccc;
        }
    }
    .primary-btn{
        text-transform: capitalize;
    }
    .flex-profile {
        display: flex;
        align-items: center;
        @media(max-width:580px){
            display: block;
            margin-bottom: 30px;
        }
        .profile_show_image {
            position: relative;
            margin-right: 15px;
            border-radius: 50%;
            width: 100px;
            @media(max-width:580px){
                display: block;
                margin-bottom: 15px;
                margin-right: auto;
                margin-left: auto;
            }
            .cross-icons{
                display: flex;
                width: 30px;
                height: 30px;
                background: #004dc0;
                position: absolute;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                z-index: 1;
                &:hover{
                    background: $green_color;
                }
               i{
                    cursor: pointer;
                    font-size: 20px; 
                    color: #fff;  
                    font-weight: 300;
                }
            }
            &:hover{
                .cross-icons{
                    display: flex;
                }
            }
            .p_img_wrap{
                background: #fff;
                border-radius: 50%;
                display: block;
                margin: auto;
                max-width: 100%;
                position: relative;
                overflow: hidden;
                width: 100px;
                border: 1px solid #ccc;
                   span{
                    display: block;
                    height: 0;
                    overflow: hidden;
                    padding-bottom: 100%!important;
                    position: relative;
                    z-index: 0;
                        img{
                            bottom: 0;
                            display: block;
                            height: auto;
                            left: 0;
                            margin: auto;
                            max-width: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 100%;
                            border-radius: 0;
                            border: 0;
														object-fit: contain;
                        }
                   }
               }
            img{
                @media(max-width:767px){
                    margin-bottom: 0px;
                }
            }
        }
    }
}

// user edit profile page design code ends here