@import 'variables';

// search review agency code starts here

.review-search_section{
    padding-top: 60px;
    .main-heading{
        font-size: 30px;
        color: $grey_color;
        font-weight: 800;
        margin-bottom: 30px;
        @media(max-width:1366px){
           font-size: 28px;
        }
    }
    .review_agency_wrapper{
        max-width: 500px;
        width: 100%;
        margin: auto;
        position: relative;
        .primary-btn{
            position: absolute;
            right: 0;
            top: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding: 13px 27px !important;
            &::before{
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
            @media(max-width:1366px){
                font-size: 14px;
             }
        }
        .input__control{
            @media(max-width:1366px){
                font-size: 14px;
             }
        }
    }
}

// search review agency code ends here



// review page code starts here

.review-page_section{
    padding-top: 60px;
    .form-section-review{
        max-width: 702px;
        width: 100%;
        margin: auto;
        margin-top: 60px;
        @media(max-width:640px){
            margin-top: 40px;
        }
        .rating_wrapper{
            margin-bottom: 20px;
            @media(max-width:640px){
                display: flex;
                flex-wrap: wrap;
            }
            .left_star-block{
                width: 175px;
                display: inline-block;
                vertical-align: middle;
                @media(max-width:640px){
                    width: 100%;
                    margin-bottom: 20px;
                    order: 1;
                }
                .rating_count_text{
                    font-size: 14px;
                    color: #3c3c3c;
                    font-weight: 400;
                    text-align: center;
                    margin-top: 15px;
                    margin-bottom: 0;
                }
            }
            .star_col{      
                width: 175px;
                position: relative;
                padding: 44px 20px;
                text-align: center;
                margin: auto;
                &::before{
                    content: "\f005";
                    font-size: 155px;
                    font-weight: bold;
                    color: #ffc107;
                    position: absolute;
                    top: -10px;
                    left: 0;
                    font-family: "Font Awesome 5 Pro";
                    line-height: initial;
                }
                .count{
                    font-weight: 700;
                    color:$grey_color;
                    position: relative;
                    z-index: 1;             
                    font-size: 50px;
                    font-family:$secondary-font;
                }
            }
            .right_rating_wrapper{
                width: calc(100% - 175px);
                display: inline-block;
                vertical-align: middle;
                padding-right: 50px;
                @media(max-width:640px){
                    width: 100%;
                    padding-left: 0;
                    text-align: center;
                    order: 2;
                    padding-right: 0;
                }     
            }
        }
        .description-text-sm{
            font-size: 14px;
            color: #3c3c3c;
            font-weight: 400;
        }
        .custom-file-input {
            max-width:100%;
            position: relative;
            padding: 0px;
            margin-bottom: 6px;
            display: block;
            input {
                max-width: 100%;
                position: absolute;
                top: 0;
                opacity: 0;
                height: 40px;
                left: 0;
                width: 100%;
            }
        
            &::before {
                max-width: 100%;
                content: "Upload photo";
                display: block;
                font: normal normal normal 14px/1 FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                background: #ffffffab;
                border: 1px solid #A4A4A4;
                border-radius: 3px;
                padding: 17px 27px;
                outline: none;
                color: black;
                white-space: nowrap;
                -webkit-user-select: none;
                cursor: pointer;
                text-shadow: 1px 1px $white_color;
                font-size: 16px;
                text-align: center;
            }
        }
        .primary-btn{
            width: 100%;
            text-align: center;
        }
        .form-group{
            margin-bottom: 20px;
        }
        label{
            font-size: 20px;
            color: #3c3c3c;
            font-weight: 400;
            margin-bottom: 10px;
        }
        .input_text{
            border: 1px solid #A4A4A4;
            color: $black_color;
            font-size: 16px;
            padding: 12px 15px;
            background-color: $white_color !important;
            width: 100%;
            border-radius: 5px;
            &:focus,&:hover,&:focus-visible{
                box-shadow: none !important;
                background-color: $white_color !important;
                border-color: $green_color !important;
                color: $black_color !important;
                outline: 0 !important;
            }
            &::placeholder{
                color: #969696;
            }
        }
        .dropdown_field{
            .input_text{
                text-align: left;
                position: relative;
                &:after{
                    right: 10px;
                    position: absolute;
                }
            }
            .dropdown-menu{
                width: 100%;
            }
        }
        .textarea-field{
            min-height: 250px;
            border: 1px solid #A4A4A4;
            color: $black_color;
            font-size: 16px;
            margin-bottom: 20px;
            padding: 12px 15px;
            background-color: $white_color !important;
            width: 100%;
            border-radius: 5px;
            &:focus,&:hover,&:focus-visible{
                box-shadow: none !important;
                background-color: $white_color !important;
                border-color: $green_color !important;
                color: $black_color !important;
                outline: 0 !important;
            }
            &::placeholder{
                color: #969696;
            }
        }
        .checkbox__field{
            input {
                padding: 0;
                height: initial;
                width: initial;
                margin-bottom: 0;
                display: none;
                cursor: pointer;
              }
              label {
                position: relative;
                cursor: pointer;
                margin-bottom: 0;
                &::before{
                    content:'';
                    -webkit-appearance: none;
                    background-color: transparent;
                    border:1px solid #A4A4A4;
                    box-shadow: none;
                    padding:6px;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 5px;
                }
              }
              input:checked + label:after {
                content: '';
                display: block;
                position: absolute;
                top: 10px;
                left: 5px;
                width: 5px;
                height: 10px;
                border: solid $green_color;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
        }
        .star-list{
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            max-width: 425px;
            width: 100%;
            @media(max-width:640px){
                margin: auto;
            }
            li{
                list-style: none;
                flex: 1;
                // margin-bottom: 20px;
                input{
                    padding: 0;
                    height: initial;
                    width: initial;
                    margin-bottom: 0;
                    display: none;
                    cursor: pointer;
                }
                .filled + label{
                    i{
                        font-weight: 600;
                        &::before{
                            color: #ffc107;
                        }
                    }
                }
                label{
                    position: relative;
                    cursor: pointer;
                }
                i{
                    position: relative;
                    padding: 8px 13px;
                    font-size: 14px;
                    text-align: center;
                    &::before{
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-size: 30px;
                        color: #a4a4a4;
                        transition: all 0.4s ease-in-out;
                    }
                    &:hover{
                        &::before{
                            font-weight: bold;
                            color: #ffc107;
                        }
                    }
                }
            }
        } 
        .uploaded-img{
            height: auto;
            .img_wrap{
                background: #fff;
                display: block;
                margin: auto;
                max-width: 100%;
                // overflow: hidden;
                width: 150px;
                border: 1px solid #ccc;
                i{
                    z-index: 1;
                }
                span{
                    display: block;
                    height: 0;
                    overflow: hidden;
                    padding-bottom: 110% !important;
                    position: relative;
                    z-index: 0;
                    img{
                        bottom: 0;
                        display: block;
                        height: auto;
                        left: 0;
                        margin: auto;
                        max-width: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: auto;
                    }
                }
            }
        }
    }
}
.review-page_section.thanks-section{
    .description-text{
        margin: 20px 0;
    }
}

// review page code ends here



// socail login modal design code starts here


.social-login-modal{
   .modal-dialog{
       max-width: 1000px;
       position: relative;
       .close{
        position: absolute;
        right: 0;
        background: $blue_color;
        padding: 5px 10px;
        color: $white_color;
        z-index: 1;
        cursor: pointer;
        border-bottom-left-radius: 5px;
       }
       .modal-body{
            padding: 0 15px;
           max-height: inherit;
           .social-btn-wrap{
               text-align: center;
            //    display: flex;
            //    align-items: center;
            //    flex-wrap: wrap;
            .inner-btn{
                max-width: 200px;
                width: 100%;
                margin-left: auto !important;
                margin-right: auto !important;
            }
               .google-btn{
                   iframe{
                       margin: auto !important;
                       #container{
                           padding: 0 !important;
                       }
                   }
               }
               .fb-btn{
                   margin: 20px 0;
                   .btn{
                    background: #3b5999;
                    color: $white_color;
                    font-weight: 600;
                    padding: 7px 10px;
                    font-size: 16px;
                    &:focus{
                        box-shadow: none;
                        outline: 0;
                    }
                   }
               }
               .linkedin-btn{
                   background: #007ab9;
                   color: $white_color;
                   font-weight: 600;
                   padding: 8px 16px;
                   font-size: 16px;
                   border: 0;
                   &:focus{
                       box-shadow: none;
                       outline: 0;
                   }
               }
            //    .inner-btn{
            //        width: 50%;
            //    }
           }
           .left-wrap{
               padding-left: 0;
               @media(max-width:576px){
                   display: none;
               }
               img{
                   height: 100%;
                   object-fit: cover;
               }
           }
           .right-col{
               padding:50px 15px;
               .main-heading{
                   margin-bottom: 30px;
                   border-bottom: 1px solid #e7e7e7;
                   padding-bottom: 20px;
               }
               .alternative-separator{
                    align-items: center;
                    text-align: center;
                    color: #4b4b4b;
                    font-size: 17px;
                    font-weight: 300;
                    display: flex;
                    margin: 30px 0;
                    &:after,&::before{
                        content: "";
                        -webkit-box-flex: 1;
                        -webkit-flex: 1;
                        -moz-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        border-bottom: 1px solid #eaeaea;
                    }
                    &::before{
                        margin-right: 0.5em;
                    }
                    &::after{
                        margin-left: 0.5em;
                    }
               }
           }
       }
   }
}



// socail login modal design code ends here