.req_review_header_section{
    h4{
        font-size: 30px;
        color: $grey_color;
        font-weight: 900;
        font-family:$secondary-font ;
        margin-bottom: 20px;
    }
    .primary-btn{
        margin: 30px 0;
        @media(max-width:640px){
            display: block;
            width: 100%;
        }
    }
}
.review_form{
    margin-bottom: 30px;
    .review-btn{
        margin-top: 30px;
        button{
            padding-left: 30px;
            padding-right: 30px;
            @media(max-width:640px){
                display: block;
                width: 100%;
            }
        }
        .primary-btn{
           margin-right: 20px;
           @media(max-width:640px){
            margin-bottom: 20px;
            }
        }
    }
}