@import "variables";

.faq-sections {
  max-width: 1220px;
  margin: auto;
  .main-heading{
    margin-bottom: 50px;
    @media(max-width:600px){
      margin-bottom: 30px;
  }
  }
  .accordion-button:not(.collapsed) {
    background-color: $blue_color;
    color: $white_color;
    font-size: 18px;
    font-weight: 600;
    &:hover{
      background:  $blue_color;
   }
  }
  .accordion-button {
    font-size: 18px;
    font-weight: 600;
    padding-right: 50px;
    color: $grey_color;
    &:focus{
        border: 0px !important;
        border-color: transparent !important;
        outline: none !important;
        box-shadow: none !important;
    }
    &:hover{
       background: #EAF7FF;
    }
    &::after{
      background-image: none;
      content: "\F067";
      font-size: 18px;
      color:  $grey_color;
      position: absolute;
      right: 20px;
      z-index: 9999;
      top: 18px;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      transform: rotate(0deg);
    }
  }
	.accordion-item:first-of-type .accordion-button,
  .accordion-item:last-of-type {
    border-radius: 0px !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed,
  .accordion-item:first-of-type {
    border-radius: 0px !important;
  }
 .accordion-item {
    margin-bottom: 15px;
  }
 .accordion-item:not(:first-of-type) {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
	.accordion-button:not(.collapsed)::after {
    background-image: none;
    content: "\F068";
    font-size: 18px;
    color: $white_color;
    position: absolute;
    right: 20px;
    z-index: 9999;
    top: 18px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    transform: rotate(0deg);
  }
  .accordion-body {
    padding: 1rem 1.25rem;
    color: #404040;
    margin-bottom: 0;
    line-height: 30px;
    font-weight: 400;
    font-size: 16px;
}

.faq-bottom-links {
	@media (max-width: 575px) {
		.text-left, .text-right {
			text-align: center;
		}
	}
	.primary-btn {
		@media (max-width: 575px) {
			max-width: 236px;
			width: 100%;
			margin: 10px 0;
		}
	}

}


	&.faq_sitemap {
		.accordion-body {
			padding: 0;
			.sitemap_listing {
				margin-bottom: 0;
				list-style-type: none;
				padding: 0px;
				.accordian_li {
					border: none;
					padding: 0;
					font-weight: 500;
					a {
						padding: 13px 30px;
						display: block;
						background-color: #fff;
						border-bottom: 1px solid #ddd;
						transition: all 0.25s 0s ease-in-out;
						&:hover {
							background-color: #EAF7FF;
						}
					}
					&:last-child {
						a {
							border-bottom: none;
						}
					}
				}
			}
		}
		.accordion-button:not(.collapsed) {
			a {
				color: #fff !important;
			}
		}
	}

}
.padding-set{
  padding:40px 10px 80px 10px;
}
.sitemap_loader {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	position: fixed;
}